import axios from 'axios';

import { getToken } from './auth';
import errorHandler from './errorHandler';
import qaErrorHandler from './qaErrorHandler';

const stages = {
  prod: 'prod',
  dev: 'dev',
  qa: 'qa',
};

const stage = stages[process.env.REACT_APP_STAGE] || 'dev';

export const shopsApi = {
  shopify: 'urgot-shopify-api',
  cartx: 'ornn-cartx-api',
};

const fakeShopApi = axios.create({
  baseURL: `https://leblanc-fake-api.profitfy.me/${stage}`,
  withCredentials: true,
});

const userApi = axios.create({
  baseURL: `https://sion-apis.profitfy.me/${stage}`,
  withCredentials: true,
});

const shopifyApi = axios.create({
  baseURL: `https://urgot-shopify-api.profitfy.me/${stage}`,
  withCredentials: true,
});

const ornnApi = axios.create({
  baseURL: `https://ornn-cartx-api.profitfy.me/${stage}`,
  withCredentials: true,
});

const authApi = axios.create({
  baseURL: `https://caitlyn-auth.profitfy.me/${stage}`,
  withCredentials: true,
});

userApi.defaults.headers.common.Authorization = getToken();
shopifyApi.defaults.headers.common.Authorization = getToken();
authApi.defaults.headers.common.Authorization = getToken();
ornnApi.defaults.headers.common.Authorization = getToken();
fakeShopApi.defaults.headers.common.Authorization = getToken();

userApi.interceptors.response.use(undefined, err => errorHandler(err));
shopifyApi.interceptors.response.use(undefined, err => errorHandler(err));
ornnApi.interceptors.response.use(undefined, err => errorHandler(err));
fakeShopApi.interceptors.response.use(undefined, () => qaErrorHandler());

export const api = name => {
  if (stage === 'qa') {
    return (
      {
        'auth-api': authApi,
      }[name] || fakeShopApi
    );
  }

  return {
    'urgot-shopify-api': shopifyApi,
    'ornn-cartx-api': ornnApi,
    'auth-api': authApi,
    'user-api': userApi,
  }[name];
};

const makeRequestCreator = ({ api }) => {
  if (stage === 'qa') api = 'leblanc-fake-api';

  let cancel;

  return async query => {
    if (cancel) {
      cancel.cancel();
    }

    cancel = axios.CancelToken.source();
    try {
      const res = await axios(query, {
        cancelToken: cancel.token,
        baseURL: `https://${api}.profitfy.me/${stage}`,
        withCredentials: true,
      });

      const result = res.data;

      return result;
    } catch (error) {
      return null;
    }
  };
};

export const makeSearchRequest = ({ api }) => makeRequestCreator({ api });
