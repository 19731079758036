import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardHeader,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';
import { SyncLoader } from 'react-spinners';
import { api, shopsApi } from '../../../../services/api';
import AliexpressCheckbox from './AliexpressCheckbox/AliexpressCheckbox';
import BulkEdit from './BulkEdit/BulkEdit';

const ProductCostTableHeader = ({ onSearch, loadProductsData }) => {
  const [loading, setLoading] = useState(false);
  const [storeConfiguration, setStoreConfiguration] = useState({
    addCustomSpendInProfit: true,
    updateAliexpressCostToVariant: false,
  });

  const { store, platform } = useParams();

  const loadStoreSettings = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/store-configurations`,
      );

      if (data.storeConfiguration) {
        setStoreConfiguration(data.storeConfiguration);
      }
    } finally {
      setLoading(false);
    }
  }, [platform, store]);

  useEffect(() => {
    loadStoreSettings();
  }, [loadStoreSettings]);

  const handleCheckbox = useCallback(async () => {
    try {
      setLoading(true);
      const requestType = storeConfiguration.id ? 'put' : 'post';

      const { data } = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id ||
          ''}`,
        {
          ...storeConfiguration,
          updateAliexpressCostToVariant: !storeConfiguration.updateAliexpressCostToVariant,
        },
      );

      setStoreConfiguration(data.storeConfiguration);
    } finally {
      setLoading(false);
    }
  }, [platform, store, storeConfiguration]);

  return (
    <CardHeader className="border-0">
      <Row>
        <Col>
          <div>
            <h3 className="mb-0">Lista de Produtos</h3>
            <p className="text-muted text-sm mb-0">
              Aqui você pode colocar quanto paga em cada produto.
            </p>
          </div>
        </Col>
        <Col sm="12" md="6" className="mt-2 mt-md-0">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Nome do Produto" type="text" name="Search" onChange={onSearch} />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-between align-items-center">
        <Col>
          {loading ? (
            <div className="d-flex align-items-center">
              <span className="text-gray">Carregando</span>
              <SyncLoader loading={loading} size={4} color="#8898aa" />
            </div>
          ) : (
            <AliexpressCheckbox
              checked={storeConfiguration.updateAliexpressCostToVariant}
              onChange={handleCheckbox}
              disabled={loading}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-end">
          <BulkEdit loadProductsData={loadProductsData} />
          <Button className="btn-light" id="filter">
            Editar todos produtos <i className="fa fa-tasks ml-2" />
          </Button>
        </Col>
      </Row>
    </CardHeader>
  );
};

export default ProductCostTableHeader;
