import React from 'react';
import { Container, Row, CardHeader, Col, Card, CardBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import CardIntegration from './Card/Card';
import mercadoPagoIMG from '../../../assets/img/theme/mercadopago.png';
import facebookIMG from '../../../assets/img/theme/facebook.jpeg';
import AliExpressLogo from '../../../assets/img/theme/aliexpress.png';
import appmaxLogo from '../../../assets/img/theme/appmax.png';
import cieloLogo from '../../../assets/img/theme/cielo.png';
import cloudfoxLogo from '../../../assets/img/theme/cloudfox.png';
import pagseguroLogo from '../../../assets/img/theme/pagseguro.png';

const Integrations = props => {
  const { store, platform } = useParams();

  const makeURL = link => {
    return `/${platform}/${store}/dashboard/integrations/${link}`;
  };

  return (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row className="mb-5">
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">Gateways de Pagamentos</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="d-flex">
              <CardIntegration
                {...props}
                xl="2"
                lg="2"
                img={mercadoPagoIMG}
                alt="Mercado Pago"
                link={makeURL('mercado-pago')}
              />
              <CardIntegration
                {...props}
                xl="2"
                lg="2"
                img={cieloLogo}
                alt="Cielo"
                link={makeURL('cielo')}
              />
              <CardIntegration
                {...props}
                xl="2"
                lg="2"
                img={appmaxLogo}
                alt="Appmax"
                link={makeURL('appmax')}
              />
              <CardIntegration
                {...props}
                xl="2"
                lg="2"
                img={cloudfoxLogo}
                alt="Cloudfox"
                link={makeURL('cloudfox')}
              />
              <CardIntegration
                {...props}
                xl="2"
                lg="2"
                img={pagseguroLogo}
                alt="Pagseguro"
                link={makeURL('pagseguro')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">Marketing</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="d-flex">
              <CardIntegration
                xl="2"
                lg="2"
                img={facebookIMG}
                alt="Facebook"
                {...props}
                link={makeURL('facebook')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">Outros</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="d-flex">
              <CardIntegration
                xl="2"
                lg="2"
                img={AliExpressLogo}
                alt="AliExpress"
                {...props}
                link={makeURL('aliexpress')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Integrations;
