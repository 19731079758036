import React, { useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { newUsersEmails, oldUsersEmails } from './labEcom';
import { features, features2 } from '../features';

const LabEcomPlan = ({ onSubmitPlan, plans, user }) => {
  const onSubmitPlanCallback = useCallback(
    ({ planId, price, title }) => {
      return onSubmitPlan({ planId, price, title });
    },
    [onSubmitPlan],
  );

  return (
    <>
      <h2 className="text-blue-marine">Aluno do LabEcom?</h2>
      <h3 className="text-blue-marine text-center">
        Deu sorte em! Você tem um periodo de uso <span className="text-green">gratuito</span> com a
        gente!
      </h3>

      <hr className="mt-1" />
      <h2>Benefícios do Plano</h2>
      <Row className=" align-content-center mt-2">
        <Col className="text-left">
          {features.map(el => (
            <div key={el} className="d-flex align-items-center mt-2">
              <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
            </div>
          ))}
        </Col>
        <Col className="text-left">
          {features2.map(el => (
            <div key={el} className="d-flex align-items-center  mt-2">
              <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
            </div>
          ))}
        </Col>
      </Row>
      <hr className="mt-3 mb-4" />
      <Button
        color="primary"
        className="mb-4 shadow"
        block
        onClick={() => {
          let labEcomPlan;

          if (oldUsersEmails.includes(user.email)) {
            labEcomPlan = plans.find(plan => plan.name === 'LabEcom 1 Month');
          }

          if (newUsersEmails.includes(user.email)) {
            labEcomPlan = plans.find(plan => plan.name === 'LabEcom 2 Month');
          }

          return onSubmitPlanCallback({
            planId: labEcomPlan.id,
            price: 0.0,
            title: 'LabEcom',
            description: 'Todos recursos liberados!',
          });
        }}
      >
        Próximo passo
      </Button>
    </>
  );
};

export default LabEcomPlan;
