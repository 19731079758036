import React, { useContext, memo } from 'react';
import { CardFooter } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import FacebookFormContext from '../../../../providers/FacebookFormContext';

const AdAccountTableFooter = () => {
  const { pagesCountAdAccount, handleChangePageAdAccount } = useContext(FacebookFormContext);

  return (
    <CardFooter className="py-4">
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        pageCount={pagesCountAdAccount}
        onPageChange={handleChangePageAdAccount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName="pagination justify-content-end mb-0"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        disabledClassName="disabled"
        activeClassName="active"
        nextClassName="page-item"
        nextLinkClassName="page-link fas fa-angle-right"
        previousClassName="page-item"
        previousLinkClassName="page-link fas fa-angle-left"
      />
    </CardFooter>
  );
};

export default memo(AdAccountTableFooter);
