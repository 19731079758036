import React, { useMemo } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useParams, useHistory } from 'react-router-dom';
import SidebarMobile from './SidebarMobile/SidebarMobile';
import icon from '../../assets/img/theme/icon.svg';
import logoWhite from '../../assets/img/brand/logo-white.svg';

import '../../assets/scss/sidebar-override.scss';

const Sidebar2 = ({ routes, location, stores, logo }) => {
  const { store, platform } = useParams();
  const history = useHistory();

  const secondNavItems = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard/config') {
        return (
          <NavItem key={prop.path} eventKey={`${platform}/${store}${prop.layout}${prop.path}`}>
            <NavIcon>
              <i className={`ni-nw ${prop.icon}`} style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText> {prop.name}</NavText>
          </NavItem>
        );
      }
      return [];
    });
  }, [platform, routes, store]);

  const firstNavItems = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard') {
        return (
          <NavItem key={prop.path} eventKey={`${platform}/${store}${prop.layout}${prop.path}`}>
            <NavIcon>
              <i className={`ni-nw ${prop.icon}`} style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText> {prop.name}</NavText>
          </NavItem>
        );
      }
      return [];
    });
  }, [platform, routes, store]);

  return (
    <>
      <SideNav
        className="d-none d-md-block"
        onSelect={selected => {
          const to = `/${selected}`;
          if (location.pathname !== to) {
            history.push(to);
          }
        }}
      >
        <SideNav.Toggle />
        <div className="logo">
          <img src={icon} alt="profitfy" />
          <img src={logoWhite} alt="profitfy" />
        </div>
        <SideNav.Nav defaultSelected={`${platform}/${store}/dashboard/shop-analytics`}>
          {firstNavItems}
          {secondNavItems}
        </SideNav.Nav>
      </SideNav>
      <SidebarMobile routes={routes} stores={stores} logo={logo} />
    </>
  );
};

export default Sidebar2;
