import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { useToast } from '../../providers/ToastContext';
import { api, shopsApi } from '../../services/api';

const AppmaxForm = ({ onSave = () => {}, className }) => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ accessToken: '', id: null });
  const { store, platform } = useParams();
  const { showToast } = useToast();

  const handleAccessToken = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ accessToken: value } });
  };

  const onSubmit = async event => {
    event.preventDefault();
    const requestType = credentials.id ? 'put' : 'post';

    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform])[requestType](
        `api/v1/users/${platform}/stores/${store}/appmax-credentials/${credentials?.id || ''}`,
        credentials,
      );

      setCredentials({
        accessToken: data.appMaxCredential.token,
        id: data.appMaxCredential.id,
      });
      showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
      onSave('appmax');
    } finally {
      setLoading(false);
    }
  };

  const loadCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `api/v1/users/${platform}/stores/${store}/appmax-credentials`,
      );

      if (data.appMaxCredential !== null) {
        setCredentials({
          accessToken: data.appMaxCredential.token,
          id: data.appMaxCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [platform, store]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Configurações</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="pt-5 pb-3">
                  <Col md={6}>
                    <h2>Instruções integração com AppMax</h2>
                    <p className="pr-4">
                      Para integrar com a Appmax é bem simples, entre em seu dashboard, copie sua
                      AccessKey, e cole neste campo.
                    </p>
                  </Col>
                  <Col>
                    <Form>
                      <div className="pl-lg-4">
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="input-access-token">
                                Access Token
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-access-token"
                                type="text"
                                onChange={handleAccessToken}
                                placeholder={credentials.accessToken}
                              />
                            </FormGroup>
                            <hr className="my-3" />
                            <div className="d-flex justify-content-center">
                              <Button
                                size="lg"
                                color="primary"
                                type="submit"
                                className="mt-4 px-4"
                                onClick={onSubmit}
                              >
                                <span className="text-uppercase">Salvar</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AppmaxForm;
