import React, { useState, memo } from 'react';
import moment from 'moment-timezone';
import { CardHeader, Button, Row } from 'reactstrap';
import CustomSpendsModal from './Modal/Modal';

const CustomSpendsTableHeader = ({ customSpends, setCustomSpends }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CustomSpendsModal
        toggle={toggle}
        isOpen={isOpen}
        customSpends={customSpends}
        setCustomSpends={setCustomSpends}
        customSpend={{
          id: null,
          description: null,
          amount: null,
          startDate: moment(),
          active: true,
          period: null,
        }}
        title="Insira um novo custo adicional"
      />

      <CardHeader className="border-0">
        <Row className="d-flex justify-content-between p-2">
          <div className="">
            <h3 className="mb-0">Valores Adicionais</h3>
            <p className="text-muted text-sm mb-0">
              Aqui você pode colocar valores adicionais (softwares/app, influencers, plano do
              shopify, taxas, salários, etc.).
            </p>
          </div>
          <div className="mt-4 mt-xl-0 ">
            <Button color="primary" className="btn-icon btn-2" onClick={toggle}>
              <span className="btn-inner--text">Adicionar</span>
              <span className="btn-inner--icon">
                <i className="ni ni-archive-2" />
              </span>
            </Button>
          </div>
        </Row>
      </CardHeader>
    </>
  );
};

export default memo(CustomSpendsTableHeader);
