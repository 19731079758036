import React from 'react';
import { Row } from 'reactstrap';
import CardWidget from '../../../../components/CardWidget/CardWidget';
import CustomCardWidget from '../../../../components/CustomCardWidget/CustomCardWidget';
import { numberBrFormatter } from '../../../../utils/utils';

const MainCardArea = ({ data }) => {
  const {
    profitMargin,
    convertedBillet,
    billetConversion,
    totalOrdersRefunded,
    waitOrders,
    totalOrders,
    totalCardsApproved,
    totalCardsRejected,
    totalOrdersInProcessing,
    gatewayFee,
    checkoutFee,
    totalBillets,
    customSpend,
    totalOrdersUnknown,
    convertedBilletUpdated,
    cpa,
    tax,
    convertedBilletAmount,
    refundedAmount,
    totalOrdersChargedBack,
  } = data.profit;

  const infos = {
    profitMargin: {
      id: 1,
      title: '% Margem de Lucro',
      details: 'comparado com ontem',
      iconColor: 'success',
      icon: 'fas fa-rocket',
      ...profitMargin,
      value: profitMargin,
      formatter: n => `${n.toFixed(0)}%`,
      animated: true,
      description: 'Faturamento dividido pelo Lucro Líquido',
    },
    convertedBilletUpdated: {
      id: 123,
      title: 'Boletos Compensados',
      ...convertedBilletUpdated,
      value: convertedBilletUpdated,
      iconColor: 'blue',
      icon: 'fas fa-money-bill-alt',
      description: 'Boletos compensados hoje',
    },
    billetConversion: {
      id: 3,
      title: '% Conversão de Boletos',
      details: 'comparado com ontem',
      iconColor: 'pink',
      icon: 'fa fa-percent',
      ...billetConversion,
      value: billetConversion,
      formatter: n => `${n.toFixed(0)}%`,
      animated: true,
      description: 'Taxa de Boletos Pagos dividido pelo Total de Boletos.',
    },
    refunds: [
      {
        id: 1,
        ...totalOrdersRefunded,
        value: totalOrdersRefunded,
        prepend: 'Devolvidos',
      },
      {
        id: 2,
        ...totalOrdersChargedBack,
        value: totalOrdersChargedBack,
        prepend: 'Estornados',
        animated: true,
      },
      {
        id: 3,
        ...refundedAmount,
        value: refundedAmount,
        append: 'R$ ',
        animated: true,
      },
    ],
    waitOrders: {
      id: 5,
      title: 'Pedidos Pendentes',
      details: 'comparado com ontem',
      iconColor: 'yellow',
      icon: 'fa fa-shopping-basket',
      ...waitOrders,
      value: waitOrders,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Valor de pedidos pendentes',
    },
    totalOrders: {
      id: 6,
      title: 'Total de Pedidos',
      details: 'comparado com ontem',
      iconColor: 'orange',
      icon: 'fas fa-chart-bar',
      ...totalOrders,
      value: totalOrders,
      description: 'Total de pedidos aprovados e pendentes',
    },
    transactionFee: [
      {
        id: 1,
        ...gatewayFee,
        value: gatewayFee,
        formatter: numberBrFormatter,
        animated: true,
        append: 'Gateway ',
      },
      {
        id: 2,
        ...checkoutFee,
        value: checkoutFee,
        formatter: numberBrFormatter,
        animated: true,
        append: 'Checkout ',
      },
      {
        id: 3,
        ...tax,
        value: tax,
        formatter: numberBrFormatter,
        animated: true,
        append: 'Impostos ',
      },
    ],
    totalCards: [
      {
        id: 1,
        iconColor: 'green',
        ...totalCardsApproved,
        value: totalCardsApproved,
        prepend: 'Aprovados',
      },
      {
        id: 2,
        iconColor: 'red',
        prepend: 'Rejeitados',
        ...totalCardsRejected,
        value: totalCardsRejected,
      },
      {
        id: 3,
        iconColor: 'blue',
        prepend: 'Em análise',
        ...totalOrdersInProcessing,
        value: totalOrdersInProcessing,
      },
    ],
    totalBillets: [
      {
        id: 1,

        ...totalBillets,
        value: totalBillets,
        prepend: 'Gerados',
      },
      {
        id: 2,

        ...convertedBillet,
        value: convertedBillet,
        prepend: 'Pagos',
      },
      {
        id: 3,
        ...convertedBillet,
        value: convertedBilletAmount,
        append: 'R$ ',
        animated: true,
        formatter: numberBrFormatter,
      },
    ],
    adSpendPerOrder: {
      id: 10,
      title: 'Custo Por Aquisição (CPA)',
      details: 'comparado com ontem',
      iconColor: 'indigo',
      icon: 'fa fa-chart-pie',
      status: 'up',
      value: cpa,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Gastos de ads dividido pelo total de pedidos pagos',
    },
    customSpend: {
      id: 11,
      title: 'Valores Adicionais',
      details: 'comparado com ontem',
      iconColor: 'lush',
      icon: 'fa fa-plus-square',
      status: 'up',
      value: customSpend,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Valores adicionais',
    },
    unknownOrders: {
      id: 20,
      title: 'Pedidos sem informações',
      details: 'comparado com ontem',
      iconColor: 'dark',
      icon: 'fa fa-question',
      status: 'up',
      value: totalOrdersUnknown,
      description:
        'Pedidos que não possuem informações necessárias para geração de dados. Provavelmente o seu checkout ou gateway de pagamento não está integrado corretamente ou possui integração.',
    },
  };

  return (
    <>
      <Row className="mt-2">
        <CardWidget data={infos.totalOrders} xl="6" lg="6" />
        <CardWidget data={infos.waitOrders} xl="6" lg="6" prepend="R$" />
        <CustomCardWidget
          id="8"
          data={infos.totalCards}
          xl="6"
          lg="6"
          title="Total de Cartões"
          iconColor="purple"
          icon="fa fa-credit-card"
          description="Total de cartões aprovados e rejeitados"
        />
        <CustomCardWidget
          id="9"
          xl="6"
          lg="6"
          title="Total de Boletos"
          iconColor="teal"
          icon="fa fa-barcode"
          data={infos.totalBillets}
          description="Quantidade total de boletos"
        />
        <CardWidget data={infos.adSpendPerOrder} xl="6" lg="6" prepend="R$" />
        <CardWidget data={infos.convertedBilletUpdated} xl="6" lg="6" />
        <CardWidget data={infos.profitMargin} xl="6" lg="6" />
        <CardWidget data={infos.billetConversion} xl="6" lg="6" />
        <CustomCardWidget
          id="9"
          xl="6"
          lg="6"
          title="Taxas de Transações"
          iconColor="gray"
          icon="fa fa-cart-arrow-down"
          data={infos.transactionFee}
          description="Taxas de parcelamento, porcentagem do gateway e do checkout (se houver)"
        />
        <CustomCardWidget
          id="9"
          xl="6"
          lg="6"
          title="Pedidos Devolvidos e Estornados"
          iconColor="red"
          icon="fa fa-reply"
          data={infos.refunds}
          description="Total de estornos e pagamentos devolvidos"
        />
        <CardWidget data={infos.customSpend} xl="6" lg="6" prepend="R$" />
        <CardWidget data={infos.unknownOrders} xl="6" lg="6" />
      </Row>
    </>
  );
};

export default MainCardArea;
