/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  Row,
  Col,
} from 'reactstrap';
import cepPromise from 'cep-promise';
import PhoneInput from 'react-phone-input-2';
import UserContext from '../../../providers/UserContext';
import { api } from '../../../services/api';
import { useToast } from '../../../providers/ToastContext';
import { masks } from '../../../utils/utils';

const ProfileUser = () => {
  const { value: user } = useContext(UserContext);
  const { showToast } = useToast();
  const [userState, setUser] = useState({
    companyName: user?.companyName,
    name: `${user?.firstName} ${user?.lastName}` || '',
    email: user?.email,
  });
  const [phone, setPhone] = useState(user?.phone);
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState(masks.cep(user?.address?.zipcode || ''));
  const [cpf, setCpf] = useState(masks.cpf(user?.cpf || ''));
  const [cnpj, setCnpj] = useState(masks.cnpj(user?.cnpj || ''));
  const [userType, setUserType] = useState(user?.cnpj ? 'business' : 'physical');
  const [address, setAddress] = useState({
    uf: user?.address?.uf,
    city: user?.address?.city,
    street: user?.address?.street,
    neighborhood: user?.address?.neighborhood,
    complementary: user?.address?.complementary,
    number: user?.address?.number,
  });

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const [firstName, lastName] = userState.name.split(' ');
      await api('user-api').put('/api/v1/users/me', {
        ...userState,
        firstName,
        lastName,
        phone,
      });

      const requestType = user?.address?.id ? 'put' : 'post';
      await api('user-api')[requestType](`/api/v1/users/addresses/${user?.address.id}`, {
        ...address,
        complementary: address.complementary || null,
        zipcode: cep,
      });
    } finally {
      showToast({ message: 'Dados atualizados com sucesso!', type: 'success' });
      setLoading(false);
    }
  };

  const handleGetAddressByCEP = useCallback(async () => {
    const cepFormatted = cep.replace('-', '');
    const result = await cepPromise(cepFormatted);
    setAddress({ ...address, ...result });
  }, [cep, address]);

  const handleAddress = e => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleUser = e => {
    const { name, value } = e.target;

    setUser({ ...userState, [name]: value });
  };

  const handleUserType = useCallback(value => {
    setUserType(value);
  }, []);

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">Meus Dados</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit} role="form">
          <div className="ml-lg-4">
            <div className="mb-4">
              <Button
                type="button"
                color={`${userType === 'business' ? 'primary' : ''}`}
                onClick={() => handleUserType('business')}
              >
                Pessoa Jurídica
              </Button>
              <Button
                type="button"
                color={`${userType === 'physical' ? 'primary' : ''}`}
                onClick={() => handleUserType('physical')}
              >
                Pessoa Física
              </Button>
            </div>
            {userType === 'business' ? (
              <Row>
                <Col sm="12" md="5">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="cnpj">
                      CNPJ
                    </label>
                    <StrapInput
                      value={cnpj}
                      type="text"
                      id="cnpj"
                      name="cnpj"
                      onChange={e => setCnpj(masks.cnpj(e.target.value))}
                      className="input-group-alternative"
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="7">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="companyName">
                      Razão Social
                    </label>
                    <StrapInput
                      value={userState.companyName}
                      type="text"
                      id="companyName"
                      name="companyName"
                      className="input-group-alternative"
                      onChange={e => handleUser(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sm="12" md="5">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="cpf">
                      CPF
                    </label>
                    <StrapInput
                      value={cpf}
                      type="text"
                      id="cpf"
                      name="cpf"
                      placeholder="000.000.000-00"
                      onChange={e => setCpf(masks.cpf(e.target.value))}
                      className="input-group-alternative"
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="7">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Nome
                    </label>
                    <StrapInput
                      value={userState.name}
                      type="text"
                      id="name"
                      name="name"
                      onChange={e => handleUser(e)}
                      className="input-group-alternative"
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-cellphone">
                    Celular (Whatsapp)
                  </label>
                  <PhoneInput
                    country="br"
                    inputClass="form-control-alternative"
                    onChange={phone => setPhone(phone)}
                    value={phone}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    E-mail
                  </label>
                  <StrapInput
                    className="form-control-alternative"
                    id="input-email"
                    value={userState?.email}
                    onChange={e => handleUser(e)}
                    type="email"
                    name="email"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="cep">
                  <label className="form-control-label " htmlFor="cep">
                    CEP
                  </label>
                  <StrapInput
                    value={cep}
                    type="text"
                    id="cep"
                    name="cep"
                    onChange={e => setCep(masks.cep(e.target.value))}
                    onBlur={handleGetAddressByCEP}
                    className="input-group-alternative"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="city">
                    Cidade
                  </label>
                  <StrapInput
                    value={address.city}
                    type="text"
                    id="city"
                    name="city"
                    onChange={e => handleAddress(e)}
                    className="input-group-alternative"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="uf">
                    Estado
                  </label>
                  <StrapInput
                    className="input-group-alternative"
                    value={address.uf}
                    onChange={e => handleAddress(e)}
                    type="text"
                    id="uf"
                    name="uf"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="8">
                <FormGroup className="flex-1">
                  <label className="form-control-label" htmlFor="address">
                    Endereço
                  </label>
                  <StrapInput
                    id="address"
                    type="text"
                    name="uf"
                    value={address.street}
                    onChange={e => handleAddress(e)}
                    className="input-group-alternative"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="address-number">
                    Número
                  </label>
                  <StrapInput
                    id="address-number"
                    className="address-number input-group-alternative"
                    type="text"
                    value={address.number}
                    name="number"
                    onChange={e => handleAddress(e)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="neighborhood">
                    Bairro
                  </label>
                  <StrapInput
                    type="text"
                    id="neighborhood"
                    className="input-group-alternative"
                    value={address.neighborhood}
                    name="neighborhood"
                    onChange={e => handleAddress(e)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="complement">
                    Complemento
                  </label>
                  <StrapInput
                    type="text"
                    id="complement"
                    placeholder="Ex: casa"
                    className="input-group-alternative"
                    name="complementary"
                    value={address?.complementary || ''}
                    onChange={e => handleAddress(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Col className="text-right pt-4">
            <Button color="primary" type="submit" size="md" disabled={loading}>
              {loading ? 'Carregando...' : 'Atualizar'}
            </Button>
          </Col>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ProfileUser;
