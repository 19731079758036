exports.features = [
  'Lojas ilimitadas',
  'Calculo do Lucro Líquido',
  'CPA Verdadeiro',
  'Sincronização com Gateways de pagamento e ferramentas de Marketing',
  'Análise top produtos mais rentaveis',
  'Integração com AliExpress',
];
exports.features2 = [
  'Histórico de 1 Mês',
  'Calculo do Custo de Marketing',
  'Graficos de performance e análise',
  'Controle automático de conversão e compensação de boletos',
  'Aplicativo Profitfy Mobile',
  'Entradas e saídas personalizadas, como custos fixos e recorrentes',
];

// - Controle automático de recusa, conversão e compensação de boletos em tempo real
// - Tabela de custos de seus produtos individuais, para saber quais estão dando mais lucro ou prejuizo
// - Tabela de status de pedidos
// - Tabela de top produtos  mais lucrátivos
// - Entradas e saídas personalizadas, como custos fixos e recorrentes
// - Suporte prioritário indepentemente do seu plano
// - Aplicativo Profitfy para acompanhar o financeiro de seu negócio de qualquer lugar
// - Sincronização com Gateways de pagamento e ferramentas de Marketing
