import ReactDOM from 'react-dom';
import React from 'react';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-extraneous-dependencies
import localization from 'moment/locale/pt-br';
import App from './App';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/styles-profitfy.scss';
import './assets/css/react_dates_overrides.css';
import './assets/scss/multi-step-overrides.scss';
import './assets/scss/global-styles.scss';
import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';
import 'chartjs-plugin-labels';

moment.locale('pt-br', localization);

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <App />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
