import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { api, shopsApi } from '../../../../services/api';
import { parseLineChartData } from '../../../../utils/utils';

const chartConfig = {
  fill: true,
  pointHitRadius: 0,
  lineTension: 0.3,
  pointBorderWidth: 0,
  pointHoverBorderWidth: 0,
  borderWidth: 2,
  spanGaps: true,
};

const ProductAnalyticsLineChart = () => {
  const [chartData, setChartData] = useState([]);
  const { platform, store, productSlug } = useParams();

  const chartDataParsed = useMemo(
    () => ({
      labels: [
        chartData.revenue && chartData.revenue[0],
        chartData.revenue && chartData.revenue[chartData.revenue?.length - 1]?.t,
      ],
      datasets: [
        {
          ...chartConfig,
          backgroundColor: 'rgba(225,214,0,0.4)',
          borderColor: '#ffd600',
          label: 'Faturamento',
          data: chartData.revenue,
        },
        {
          ...chartConfig,
          label: 'Custo dos Produtos',
          backgroundColor: 'rgba(251,99,64,0.4)',
          borderColor: '#fb6340',
          data: chartData.cogs,
        },
        // {
        //   ...chartConfig,
        //   label: 'Marketing',
        //   backgroundColor: 'rgba(137,101,224,0.4)',
        //   borderColor: '#8965e0',
        //   data: chartData.marketing,
        // },
        {
          ...chartConfig,
          label: 'Lucro Líquido',
          backgroundColor: 'rgba(40,207,135,0.4)',
          borderColor: '#28cf87',
          data: chartData.profit,
        },
      ],
    }),
    [chartData.cogs, chartData.profit, chartData.revenue],
  );

  const options = useMemo(
    () => ({
      legend: {
        display: true,
      },
      tooltips: {
        enabled: true,
      },
      elements: {
        point: {
          radius: 5,
        },
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            type: 'time',
            time: {
              unit: 'day',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }),
    [],
  );

  const loadChart = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/profit/chart/main/?period=6`,
    );

    const parsedData = parseLineChartData(data.chart);
    setChartData(parsedData);
  }, [platform, store, productSlug]);

  useEffect(() => {
    loadChart();
  }, [loadChart]);

  return (
    <Row className="mt-5">
      <Col>
        <Card className="bg-gradient-secundary shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Gráfico de performance</h3>
                <p className="text-muted text-sm mb-0">
                  Analise o desempenho de seu produto na última semana.
                </p>
              </div>
            </Row>
          </CardHeader>
          <CardBody className="p-0 p-md-4">
            <div className="wrapper-chart">
              <Line data={chartDataParsed} options={options} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(ProductAnalyticsLineChart);
