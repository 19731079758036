import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProfileSubscribe from './Subscribe/Subscribe';
import ProfileNotifications from './Notifications/Notifications';
import ProfileUser from './User/User';
import ProfileTransactions from './Transactions/Transactions';

const Profile = ({ fluid = true }) => {
  return (
    <Container fluid={fluid} className="pb-8 pt-3 pt-md-8">
      <Row>
        <Col md="12" lg="6" xl="5">
          <ProfileSubscribe />
          <ProfileTransactions />
        </Col>
        <Col className="order-xl-1 pt-md-0 pt-3">
          <ProfileUser />
          <ProfileNotifications />
        </Col>
      </Row>
    </Container>
  );
};
export default Profile;
