import React, { useEffect, useState, useCallback, memo, useMemo } from 'react';
import { Switch, Redirect, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar2 from '../../components/Sidebar/Sidebar2';
import menuRoutes from '../../routes/menuRoutes';
import FirstTimeContext from '../../providers/FirstTimeContext';
import ProductDetails from './ProductDetails/ProductDetails';
import Profile from '../../components/Profile/Profile';
import logo from '../../assets/img/brand/logo-white.svg';
import { api, shopsApi } from '../../services/api';
import usePersistedState from '../../utils/usePersistedState';

const Dashboard = props => {
  const { location } = props;
  const [isNewUser, setIsNewUser] = useState(false);
  const [storeCreatedAt, setStoreCreatedAt] = useState(null);
  const [stores, setStores] = useState([]);
  const { store, platform } = useParams();
  usePersistedState('storeURL', { store, platform });

  const value = useMemo(() => ({ storeCreatedAt, isNewUser }), [storeCreatedAt, isNewUser]);

  const brandText = useMemo(() => {
    const result = menuRoutes.find(route => {
      const locationPath = location.pathname.split('/').pop();
      const routePath = route.path.split('/').join('');

      return locationPath.includes(routePath);
    });

    return result?.name;
  }, [location.pathname]);

  const renderDashboardRoutes = useMemo(() => {
    return (
      <>
        <PrivateRoute
          path="/:platform/:store/dashboard/product-details/:productSlug/product-details"
          component={props => <ProductDetails {...props} />}
        />
        <PrivateRoute
          path={`/${platform}/${store}/dashboard/user-profile`}
          component={props => <Profile {...props} />}
        />
      </>
    );
  }, [platform, store]);

  const renderMenuRoutes = useMemo(() => {
    return menuRoutes.map(prop => {
      if (prop.layout.includes('/dashboard')) {
        return (
          <PrivateRoute
            {...props}
            path={`/:platform/:store${prop.layout}${prop.path}`}
            component={prop.component}
            key={prop.name}
          />
        );
      }
      return null;
    });
  }, [props]);

  const loadStoreData = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/first-time`,
    );

    setIsNewUser(data.isFirstTime);
    setStoreCreatedAt(data.storeCreatedAt);
    moment.tz.setDefault(data.timezone);
  }, [platform, store]);

  const loadStores = useCallback(async () => {
    const response = await api('user-api').get(`/api/v1/users/stores`);
    setStores(response.data.stores);
  }, []);

  useEffect(() => {
    loadStores();
  }, [loadStores]);

  useEffect(() => {
    loadStoreData();
  }, [loadStoreData]);

  return (
    <>
      {isNewUser ? (
        <Redirect to={`/${platform}/${store}/new-user`} />
      ) : (
        <FirstTimeContext.Provider value={value}>
          <Sidebar2
            routes={menuRoutes}
            {...props}
            stores={stores}
            logo={{
              innerLink: '/admin/index',
              imgSrc: logo,
              imgAlt: 'logo-profitfy',
            }}
          />
          {/* <Sidebar
            {...props}
            routes={menuRoutes}
            logo={{
              innerLink: '/admin/index',
              imgSrc: logo,
              imgAlt: 'logo-profitfy',
            }}
            stores={stores}
          /> */}
          <div className="main-content">
            <Navbar {...props} brandText={brandText} stores={stores} />
            <div className="vh-90">
              <Switch>
                {renderMenuRoutes}
                {renderDashboardRoutes}
              </Switch>
            </div>
            <Footer />
          </div>
        </FirstTimeContext.Provider>
      )}
    </>
  );
};

export default memo(Dashboard);
