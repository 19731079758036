import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { SyncLoader } from 'react-spinners';
import Spinner from '../../../../../../../components/Spinner/Spinner';
import CustomSwitch from '../../../../../../../components/CustomSwitch/CustomSwitch';

const FacebookInsghtsTableBody = ({ campaigns, handleCheckBox, loading, loadingStatus }) => {
  return (
    <>
      <div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <Table className="table-flush" responsive>
            <>
              <thead className="thead-light">
                <tr>
                  <th scope="col">
                    <span>Nome </span>
                  </th>
                  <th scope="col">
                    <span>Conta de Anúncio </span>
                  </th>
                  <th scope="col" className="text-right">
                    <span> Ações </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map(campaign => (
                  <tr key={campaign.id}>
                    <td className="text-truncate" style={{ maxWidth: '300px' }}>
                      <span>{campaign.name}</span>
                    </td>
                    <td>
                      <span>{campaign.facebook_ad_account?.name}</span>
                    </td>
                    <td className="text-right">
                      {loadingStatus?.status && loadingStatus?.id === String(campaign.id) ? (
                        <div className="py-4">
                          <SyncLoader color="#BDB5B5" loading={loadingStatus?.status} />
                        </div>
                      ) : (
                        <CustomSwitch
                          id={campaign.id}
                          onClick={handleCheckBox}
                          status={campaign.active}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          </Table>
        )}
      </div>
    </>
  );
};

export default memo(FacebookInsghtsTableBody);
