import React, { memo } from 'react';
import { Table, Button, Media, Badge } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { numberBrFormatter } from '../../../../../utils/utils';

const TopProductsTableBody = ({ products }) => {
  const { platform, store } = useParams();

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Imagem</th>
          <th scope="col">Nome</th>
          <th scope="col" className="text-center">
            Faturamento
          </th>
          <th scope="col" className="text-center">
            Qtd. Pedidos
          </th>
          <th scope="col" className="text-center">
            ações
          </th>
        </tr>
      </thead>
      <tbody>
        {products.map(product => (
          <tr key={product.id}>
            <td width="8%">
              <Media className="align-items-center">
                <span className="avatar avatar-lg rounded-circle">
                  <img alt="product" src={product.image} />
                </span>
              </Media>
            </td>
            <td className="text-truncate" style={{ maxWidth: '300px' }}>
              <span className="mb-0">{product.title}</span>
            </td>
            <td width="15%" className="text-center">
              <p className="mb-0 h2">
                <Badge color="green">R$ {numberBrFormatter(product.total_revenue)} </Badge>
              </p>
            </td>
            <td width="15%" className="text-center">
              <span>{product.total_orders} </span>
            </td>
            <td className="text-center" width="2%">
              <Link
                to={`/${platform}/${store}/dashboard/product-details/${product.slug}/product-details`}
              >
                <Button className="btn">
                  <i className="fas fa-eye text-gray" />
                </Button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(TopProductsTableBody);
