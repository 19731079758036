import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const AppmaxWarn = () => (
  <Alert color="info" className="d-flex align-items-center">
    <span>
      <i className="fa fa-exclamation-triangle" />
      Suas credenciais da <strong>Appmax </strong>
      estão incorretas. Para arrumar{' '}
      <Link className="alert-link" to="integrations/appmax">
        Clique aqui
      </Link>
    </span>
  </Alert>
);

export default AppmaxWarn;
