import React from 'react';
import { Button, Col, Card } from 'reactstrap';
import './LineItem.css';

const NewUserFluxLineItem = ({ logo, handleConnect, id, isConnected }) => {
  return (
    <Col md={3}>
      <Card className="d-flex flex-column justify-content-center align-items-center shadow shadow-lg--hover pb-3">
        <img alt="logo" className="logo-line-item" src={logo} />
        <div>
          <Button
            color={isConnected ? 'primary' : 'gray'}
            className="text-center"
            id={id}
            onClick={e => handleConnect(e)}
          >
            {isConnected ? 'Conectado' : 'Conectar'}
          </Button>
        </div>
      </Card>
    </Col>
  );
};

export default NewUserFluxLineItem;
