import React, { useEffect, useState, useCallback, memo } from 'react';
import { Card, CardBody, CardHeader, Progress, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import './NextFeatures.scss';
import axios from 'axios';

const NextFeatures = () => {
  const [features, setFeatures] = useState([]);

  const loadNextFeatures = useCallback(async () => {
    try {
      const { data } = await axios.get(
        'https://profitfy-media.s3-us-west-2.amazonaws.com/next-features',
      );

      setFeatures(data);
    } catch (error) {
      Swal.fire({
        title: 'Oops... Houve um Erro!',
        text: error,
        icon: 'error',
        showConfirmButton: true,
      });
    }
  }, []);

  useEffect(() => {
    loadNextFeatures();
  }, [loadNextFeatures]);

  return (
    <Card className="shadow next-features">
      <CardHeader>
        <Row className="d-flex justify-content-between p-2">
          <h3 className="mb-0">Próximas Atualizações</h3>
          <p className="text-muted text-sm mb-0">
            Fique de olho no que está por vir em nossa plataforma.
          </p>
        </Row>
      </CardHeader>
      <CardBody className="scrollable">
        {features.map(el => (
          <div key={el.title} className="mb-5">
            <p className="text-muted mb-2">{el.title}</p>
            <Progress
              color="primary"
              value={el.value}
              max="100"
              animated
              barClassName="bg-primary progress-height"
              className="progress-height"
            >
              <span
                className={`${
                  el.value > 0 ? 'text-white' : 'text-dark text-center ml-2'
                } font-weight-bold`}
              >
                {el.value}%
              </span>
            </Progress>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default memo(NextFeatures);
