import React, { memo } from 'react';

const SubHeaders = ({ setOrderSort, orderSort, columns }) => {
  return columns.map(column => {
    const sortProps = {
      key: column.name,
      className: `px-3 ${column.sort && 'has-sort'}`,
      scope: 'col',
      onClick: () =>
        setOrderSort({
          [column.name]: orderSort[column.name] === 'asc' ? 'desc' : 'asc',
        }),
    };

    return (
      <th {...sortProps}>
        {column.description}{' '}
        {column.sort && (
          <i
            className={
              orderSort[column.name] ? `fa fa-sort-${orderSort[column.name]}` : `fa fa-sort`
            }
          />
        )}
      </th>
    );
  });
};

export default memo(SubHeaders);
