import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import AdminRoute from '../components/AdminRoute/AdminRoute';
import Auth from '../pages/Auth/Auth';
import Stores from '../pages/Stores/Stores';
import Dashboard from '../pages/Dashboard/Dashboard';
import NewUserFlux from '../pages/NewUserFlux/NewUserFlux';
import Subscribe from '../pages/Subscribe/Subscribe';
import Prices from '../pages/Prices/Prices';
import Admin from '../pages/Admin/Admin';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/stores" />} />
      <Route path="/auth" render={props => <Auth {...props} />} />
      <Route path="/prices" render={props => <Prices {...props} />} />
      <AdminRoute path="/admin" component={props => <Admin {...props} />} />
      <PrivateRoute path="/subscribe" component={props => <Subscribe {...props} />} />
      <PrivateRoute path="/stores" component={props => <Stores {...props} />} />
      <PrivateRoute
        path="/:platform/:store/dashboard"
        component={props => <Dashboard {...props} />}
      />
      <PrivateRoute
        path="/:platform/:store/new-user"
        component={props => <NewUserFlux {...props} />}
      />
    </Switch>
  );
};
