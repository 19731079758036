import React from 'react';
import congratzSVG from '../../../assets/img/theme/congratz.svg';

const Finish = () => (
  <div className="d-flex flex-column align-items-center">
    <img src={congratzSVG} className="img-fluid" alt="thank-you" width="600px" />
    <h1 className="title font-weight-normal text-center px-md-9 my-4">Bom Trabalho!</h1>
    <div className="px-md-9 my-4">
      <h2 className="font-weight-600 text-center">Começaremos a calcular seus dados.</h2>
      <h2 className="mt-2">
        Você receberá um <span className="text-red">e-mail </span>quando terminarmos.
      </h2>
    </div>
  </div>
);

export default Finish;
