import React from 'react';
import { Container } from 'reactstrap';
import TopProducts from './TopProducts/TopProducts';
import './styles.scss';

const ProductAnalytics = () => {
  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <TopProducts />
    </Container>
  );
};

export default ProductAnalytics;
