import React, { useState, useCallback, memo, useMemo } from 'react';
import { Table, Badge, Tooltip } from 'reactstrap';
import moment from 'moment-timezone';
import OrdersTableBodyModal from './Modal/Modal';
import RowActions from './RowActions/RowActions';
import SubHeaders from './SubHeaders/SubHeaders';
import { numberBrFormatter } from '../../../../utils/utils';
import { orderUtils } from '../../../../utils/order';
import './styles.scss';

const columns = [
  { name: 'name', description: 'N. Pedido', sort: true },
  { name: 'createdAt', description: 'Data', sort: true },
  { name: 'products', description: 'Produtos', sort: false },
  { name: 'status', description: 'Status', sort: false },
  { name: 'revenue', description: 'Faturamento', sort: true },
  { name: 'cogs', description: 'Custo Produtos', sort: true },
  { name: 'tax', description: 'Taxas Transação', sort: false },
  { name: 'profit', description: 'Lucro', sort: true },
];

const OrdersTableBody = ({ orders, platform, domain, loadOrdersData, setOrderSort, orderSort }) => {
  const [tooltip, setTooltip] = useState(false);
  const [order, setOrder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [rowHovered, setRowHovered] = useState();

  const toggle = useCallback(
    id => {
      setTooltip({ open: !tooltip.open, id });
    },
    [tooltip.open],
  );

  const handleEditOrder = useCallback(
    orderValue => {
      setIsOpen(!isOpen);
      setOrder(orderValue);
    },
    [isOpen],
  );

  const handleHover = useCallback(id => setRowHovered(id), []);

  const parsedStoreLink = useMemo(
    () =>
      ({
        shopify: `https://${domain}/admin/orders/`,
        cartx: `https://accounts.cartx.io/orders/details/`,
      }[platform]),
    [domain, platform],
  );

  const renderedRows = useMemo(() => {
    return orders.map(order => {
      if (!order) return <></>;
      const type = orderUtils.getTypeData(order.type);

      return (
        <tr
          key={order.id}
          className="table-row"
          onMouseEnter={() => handleHover(order.id)}
          onMouseLeave={() => handleHover(null)}
        >
          <td>
            <p className="mb-0">
              <a
                href={`${parsedStoreLink}${order.id}`}
                target="blank"
                className="text-blue text-sm"
              >
                <span className="fas fa-external-link-alt" /> {order.name}
              </a>
            </p>
          </td>
          <td>
            <p className="mb-0">{moment(order.createdAt).format('DD-MM-YYYY hh:mm')}</p>
          </td>
          <td style={{ maxWidth: '200px' }}>
            <ul className="pl-0">
              {order.products.map(product => (
                <li key={product} className="text-truncate">
                  <span>&#9679; {product}</span>
                </li>
              ))}
            </ul>
          </td>
          <td>
            <p className="mb-0">
              <Badge className={`badge-${orderUtils.getStatusColor(order.status)}`} color="none">
                {orderUtils.parseStatusName(order.status)}
              </Badge>
            </p>
            <div className="d-flex align-items-center mt-2">
              <i className={`${type.icon} text-gray`} />
              <span className="ml-2 text-capitalize font-weight-bold">{type.name}</span>
            </div>
          </td>
          <td>
            <p className="mb-0">R$ {numberBrFormatter(order.revenue)}</p>
          </td>
          <td>
            <p
              className={`${order.costUpdatedBySupplier && 'text-green font-weight-bold'} mb-0`}
              id={`Tooltip-${order.id}`}
            >
              R$ {numberBrFormatter(order.cogs)}{' '}
            </p>
            {order.costUpdatedBySupplier && (
              <Tooltip
                placement="top"
                isOpen={tooltip.open && tooltip.id === order.id}
                target={`Tooltip-${order.id}`}
                toggle={() => toggle(order.id)}
                color="secondary"
              >
                <span className="text-gray-dark">Este valor foi sincronizado com o AliExpress</span>
              </Tooltip>
            )}
          </td>
          <td>
            <div>
              <span className="font-weight-bold">Gateway</span>
              <span className="ml-3"> R$ {numberBrFormatter(order.gatewayFee)} </span>
            </div>
            <div className="mt-1">
              <span className="font-weight-bold">Checkout</span>
              <span className="ml-3">R$ {numberBrFormatter(order.checkoutFee)} </span>
            </div>
            <div className="mt-1">
              <span className="font-weight-bold">Impostos</span>
              <span className="ml-3">R$ {numberBrFormatter(order.tax)} </span>
            </div>
          </td>
          <td>
            <p className="mb-0">
              <Badge color={orderUtils.getProfitColor(order.profit)}>
                {' '}
                R$ {numberBrFormatter(order.profit)}
              </Badge>
            </p>
          </td>
          <RowActions rowHovered={rowHovered} handleEditOrder={handleEditOrder} order={order} />
        </tr>
      );
    });
  }, [orders, handleHover, parsedStoreLink, toggle, tooltip, handleEditOrder, rowHovered]);

  return (
    <>
      {order && (
        <OrdersTableBodyModal
          handleEditOrder={handleEditOrder}
          isOpen={isOpen}
          loadOrdersData={loadOrdersData}
          order={order}
          title="Edite o as informações do produto"
        />
      )}

      <Table className="align-items-center table-flush table-orders" responsive>
        <thead className="thead-light">
          <tr>
            <SubHeaders columns={columns} setOrderSort={setOrderSort} orderSort={orderSort} />

            <th className="px-3" scope="col" />
          </tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </Table>
    </>
  );
};

export default memo(OrdersTableBody);
