import React, { useState, useCallback } from 'react';
import useForm from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Spinner from '../../../components/Spinner/Spinner';
import { useToast } from '../../../providers/ToastContext';
import { api } from '../../../services/api';

const ResetPassword = ({ history }) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { resetToken } = useParams();

  const patternPassword = /^[a-zA-Z0-9!@#$%&*]{3,25}$/;

  const validationSchema = yup.object().shape({
    password: yup.string().matches(patternPassword, 'A senha deve no mínimo 6 caracteres'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Está diferente da senha.'),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        await api('auth-api').post(`/auth/reset-password?resetToken=${resetToken}`, {
          password: data.password,
        });
        await Swal.fire({
          icon: 'success',
          title: 'Senha alterada com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        history.push('/auth/login');
      } catch (error) {
        const message = error?.response?.data?.message || 'Entre em contato com o suporte';
        showToast({ message, type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [history, resetToken, showToast],
  );

  return (
    <>
      <Col lg="6" md="8" className="mt-sm--8 mt-md--2 mt-lg--6 mt-xl--6 pb-5 container">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5 card-body">
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <>
                <div className="text-left text-muted mb-4">
                  <h2>Cadastre uma nova senha!</h2>
                </div>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className={errors.password && 'has-danger'}>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <StrapInput
                        placeholder="Nova Senha"
                        type="password"
                        innerRef={register}
                        name="password"
                      />
                    </InputGroup>
                    {errors.password && (
                      <small className="text-danger">É necessário uma senha válida</small>
                    )}
                  </FormGroup>
                  <FormGroup className={errors.confirmPassword && 'has-danger'}>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <StrapInput
                        placeholder="Confirmar Senha"
                        type="password"
                        innerRef={register}
                        name="confirmPassword"
                      />
                    </InputGroup>
                    {errors.confirmPassword && <small>{errors.confirmPassword.message}</small>}
                  </FormGroup>

                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                  </div>
                  <div className="text-center">
                    <Button className="my-2 btn-icon btn-2" color="primary" type="submit">
                      <span className="btn-inner--icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn-inner--text">Enviar</span>
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-white" to="/auth/register">
              <span>Criar nova conta</span>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link className="text-white" to="/auth/login">
              <span>Entrar</span>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ResetPassword;
