/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';

function AliexpressCheckbox({ checked, onChange, disabled }) {
  return (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        id="include-aliexpress"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor="include-aliexpress">
        <span className="text-muted">Atualizar custo do produto ao sincronizar com Aliexpress</span>
      </label>
    </div>
  );
}

export default AliexpressCheckbox;
