const newUsersEmails = [
  'enielevidalf@gmail.com',
  'rosaalvares2005@hotmail.com',
  'emmanomitidiero@gmail.com',
  'vicentemeninoa@gmail.com',
  'veronica_sansone_02@hotmail.com',
  'newtons.borges@gmail.com',
  'robertomega20@gmail.com',
  'alexpedrosomayer@gmail.com',
  'juliana.origuela@hotmail.com',
  'karincgharpis@gmail.com',
  'almeida.souza1986@bol.com.br',
  'romulor010101@gmail.com',
  'taina.hilleshein@outlook.com',
  'gabrielpsimoes@gmail.com',
  'contactrickmaia@gmail.com',
  'thais.negociosonline@yahoo.com',
  'de_morita@hotmail.com',
  'cecieli.rojas@gmail.com',
  'fefeornellas@gmail.com',
  'sadalva31@gmail.com',
  'agnesfb@gmail.com',
  'colaneri.leo@gmail.com',
  'suelem.oliveiras@hotmail.com',
  'elisafrigozago@hotmail.com',
  'elaine.michel@hotmail.com',
  'alyssonhcc@gmail.com',
  'cynaratravassos1980@gmail.com',
  'murillo.19gobr@gmail.com',
  'luizbatemarque284@gmail.com',
  'marialucas2506200@gmail.com',
  'Pauloguedes31@gmail.com',
  'rogerioaclima@gmail.com',
  'mfatima_gomes@hotmail.com',
  'arnaldosamudio72@gmail.com',
  'marisa.sarmento@gmail.com',
  'luizabritoguedes@hotmail.com',
  'tamara.arnaut22@gmail.com',
  'paulapeniel@gmail.com',
  'mx.marta@gmail.com',
  'simonembehr@yahoo.com.br',
];

const oldUsersEmails = [
  'abbaia@superig.com.br',
  'adrianasiqsilva@gmail.com',
  'adri_ruggero@hotmail.com',
  'alanjhones131@gmail.com',
  'alexandremajela@uol.com.br',
  'alexmoura78@gmail.com',
  'alexsandraborges@gmail.com',
  'andersonfera-2007@hotmail.com',
  'anderson_pinheiro_silva@hotmail.com',
  'andreduarte.moller@yahoo.com',
  'andrieli@dermociencia.com.br',
  'andrigof@hotmail.com',
  'anjos_elis@hotmail.com',
  'antoniomachado__@hotmail.com',
  'arianejaeger4@gmail.com',
  'armandomsjunior@gmail.com',
  'arpacanha@gmail.com',
  'atendimento.vidadepraia@gmail.com',
  'atendimento@emporio60.com',
  'auriledatorres2015@gmail.com',
  'azimute.lem@gmail.com',
  'b.miranda996@gmail.com',
  'bianet_lopes@hotmail.com',
  'brenno.moura1@gmail.com',
  'bronzehenrique@gmail.com',
  'brunaabit.bb@gmail.com',
  'caioblz@gmail.com',
  'caiovieira19@hotmail.com',
  'camilatprocha@gmail.com',
  'carola_magalhaes@hotmail.com',
  'carolinarbassi@gmail.com',
  'caroline.chinelatto@gmail.com',
  'cesarsantosmarketing@gmail.com',
  'cherin.omari69@gmail.com',
  'cicero.carneiro72@gmail.com',
  'cirilodasilvajunior@gmail.com',
  'claudemir.import@hotmail.com',
  'comodidadeseguranca@outlook.com',
  'contato@empreender.com.br',
  'contatotrabalhopaula@gmail.com',
  'crcs.gomes@gmail.com',
  'cristian@spreadbyte.com',
  'cristiano.souza@vitrinishop.com.br',
  'cristianovilches@gmail.com',
  'dalienwilkerson14@gmail.com',
  'Daniel.lima83@gmail.com',
  'danielpennarj@gmail.com',
  'dawifelicori@hotmail.com',
  'dccassiodocarmo@gmail.com',
  'dcrribeiro@yahoo.com.br',
  'delimaneto@hotmail.com',
  'denisespfotografia@gmail.com',
  'desconstruindocomnati@gmail.com',
  'diegokaiqueangeli@gmail.com',
  'diego_nvk@hotmail.com',
  'dinizlg2@hotmail.com',
  'diogobran10@gmail.com',
  'donadijunior@yahoo.co.uk',
  'drivizerra@icloud.com',
  'dschoenardie@hotmail.com',
  'dudu_ct@icloud.com',
  'd_clt@hotmail.com',
  'ederio.almada@gmail.com',
  'elienesantos.coach@gmail.com',
  'elitebemestar@gmail.com',
  'ellaevangelica@gmail.com',
  'elmoneto@gmail.com',
  'emanuelleb58@gmail.com',
  'fabiomonteiro.ufrj@gmail.com',
  'fabricio.jcm@gmail.com',
  'fefape.bio@gmail.com',
  'Fisio_andressa@yahoo.com.br',
  'gabrielcdmendes@gmail.com',
  'gabrielzaneloti@gmail.com',
  'giadami60@gmail.com',
  'glauberbz46@gmail.com',
  'groupfernandes@hotmail.com',
  'gui.augusto.28@hotmail.com',
  'hele_unitec@hotmail.com',
  'hjlshop10@gmail.com',
  'hsiao.trade@gmail.com',
  'hugohcleao@yahoo.com.br',
  'integracaoeduzz@gmail.com',
  'ionyalves@hotmail.com',
  'irramlc@gmail.com',
  'iurypeixotto@gmail.com',
  'jackson.gouvea@yahoo.com.br',
  'jacksongomeshd@gmail.com',
  'jefferson@iscooleventos.com.br',
  'jonata.santosbr@gmail.com',
  'jonathangyn@live.com',
  'jpkb.jose@gmail.com',
  'junepricca@hotmail.com',
  'junior.fonseca.corretor@gmail.com',
  'junior.shimanouti@gmail.com',
  'juperdao@gmail.com',
  'jvictorslg@gmail.com',
  'kelleklug@gmail.com',
  'kenia.miranda@gmail.com',
  'klquintas@yahoo.com.br',
  'le.prado.pereira@gmail.com',
  'leandroeel@gmail.com',
  'leonardo.prestes10@hotmail.com',
  'leticiayamane@icloud.com',
  'line_jvm@hotmail.com',
  'luanarengel@yahoo.com.br',
  'lucas.lmgcompany@gmail.com',
  'lucas1795@gmail.com',
  'lucasdossantosgja@gmail.com',
  'lucianoribero61@gmail.com',
  'luciomartinsjunior@gmail.com',
  'lumlqsmaia@gmail.com',
  'lv.santarosa@gmail.com',
  'maranjae@yahoo.com.br',
  'marcelo@carlotas.net',
  'marcelodieckmann@icloud.com',
  'marcelogomess.adv@gmail.com',
  'marcia_akimoto@yahoo.com.br',
  'marineidedutra1@gmail.com',
  'matheus@ipense.com.br',
  'maxtu08@gmail.com',
  'meusnegocios.passos@outlook.com',
  'michellerachell@hotmail.com',
  'mike.polettiarte@gmail.com',
  'monteirosstore01@gmail.com',
  'mr.chueke@hotmail.com',
  'murielalves@gmail.com',
  'm_cantalice@yahoo.com.br',
  'nadia.moraes2014@gmail.com',
  'natushopp1@gmail.com',
  'nayara.s.moraes@gmail.com',
  'nelsonjrmkt@gmail.com',
  'nilzalara7@gmail.com',
  'novembro64@hotmail.com',
  'oficialoutletbabykids@gmail.com',
  'pedroalves1310@gmail.com',
  'prais.vania@gmail.com',
  'rafannb@gmail.com',
  'raninha10@hotmail.com',
  'raphael0091@outlook.com',
  'raul_albers@hotmail.com',
  'renansilqueira@gmail.com',
  'renatoportobr@hotmail.com',
  'renatosaude30@gmail.com',
  'renato_engenheiro@outlook.com',
  'roberta_ugeda@hotmail.com',
  'robsonfdomingos@gmail.com',
  'rodrigo.souza981@hotmail.com',
  'rruas60@gmail.com',
  'rubenscavalcantii@hotmail.com',
  'rubensmanzo@gmail.com',
  'samuel.tbn@hotmail.com',
  'segundinho.silvinha@gmail.com',
  'storm65storm@gmail.com',
  'talesvsc@hotmail.com',
  'tamilaabbraz@gmail.com',
  'tatiestevanato@hotmail.com',
  'tayna96@gmail.com',
  'teleradioweb@hotmail.com',
  'thiago@projectvidros.com.br',
  'thiagobueno.me@gmail.com',
  'tiagoamota@gmail.com',
  'ttiagoflu@hotmail.com',
  'valdenirapsousa@gmail.com',
  'vaz.danielle@gmail.com',
  'victorsollaoficial@gmail.com',
  'villasvitor12@gmail.com',
  'vilmarcunha67@gmail.com',
  'yallediniz@hotmail.com',
  'aclaudiascs@hotmail.com',
  'acristinamartins7@gmail.com',
  'adrianavizerra@me.com',
  'adriano.rosa@globo.com',
  'adrielaviz@hotmail.com',
  'ailtonalves2607@gmail.com',
  'alessandra.calderazzo@gmail.com',
  'alessandra.silva@sapo.pt',
  'alexpedrosomayer@gmail.com',
  'alielbecas@gmail.com',
  'alinenascimento1903@gmail.com',
  'almeida.souza1986@bol.com.br',
  'alquati.dora@gmail.com',
  'alvarodiboa@gmail.com',
  'alyssonhcc@gmail.com',
  'amandaferreira1942@gmail.com',
  'amauryg3@gmail.com',
  'ana-flavia-prado@hotmail.com',
  'anacarolg@gmail.com',
  'analuoliveira32@gmail.com',
  'anamarianaribeiro@gmail.com',
  'anderson.batiston@hotmail.com',
  'anderson@ocarrinhodecompras.com.br',
  'Andrade.mr2@gmail.com',
  'andreluisbz23@gmail.com',
  'annep4r@gmail.com',
  'assiscabofrio@gmail.com',
  'austrorocha@hotmail.com',
  'ayokoda@gmail.com',
  'baguii@hotmail.com',
  'battle.net.rds@gmail.com',
  'beatrizfloriano@yahoo.com.br',
  'beneditops13@gmail.com',
  'bianet_lopes@hotmail.com',
  'brasilvariedadespalmas@gmail.com',
  'bruna050@hotmail.com',
  'bruninsp013@hotmail.com',
  'bruno0207@gmail.com',
  'carlos.santos1955@uol.com.br',
  'carmenfontella@bol.com.br',
  'cbskungfu@gmail.com',
  'cecieli.rojas@gmail.com',
  'chicencanto@gmail.com',
  'ciellyrodrigues2@hotmail.com',
  'cissamendes@hotmail.com',
  'cleiafernandes.soares@gmail.com',
  'cleisonoliveira390@gmail.com',
  'comercial@updateinformatica.com',
  'contactrickmaia@gmail.com',
  'contato@heraldomoraes.com.br',
  'contato@meupneu.com',
  'dabliuribeiro@gmail.com',
  'danielamendescurcio@gmail.com',
  'danielle05pereira@gmail.com',
  'david.19.eng@gmail.com',
  'david.dvdgt1@hotmail.com',
  'daviddonizetti22@gmail.com',
  'daviddonizetti@gmail.com',
  'dcrribeiro@yahoo.com.br',
  'denilson@accontadores.com.br',
  'de_morita@hotmail.com',
  'dhsassaki@gmail.com',
  'donadijunior@yahoo.co.uk',
  'dr.heliojp@gmail.com',
  'drivizerra@icloud.com',
  'ederio.almada@gmail.com',
  'edisonaguerra@gmail.com',
  'edney.mirandas@gmail.com',
  'edsoncosta943@gmail.com',
  'eduardo.globall@gmail.com',
  'eduardohansen_@hotmail.com',
  'eldabraz2012@gmail.com',
  'eliane.oliveira@hotmail.com',
  'eliane.oliveira@hotmail.com.br',
  'elisafrigozago@hotmail.com',
  'elisangelafelix@me.com',
  'email.julianaozany@gmail.com',
  'emmanomitidiero@gmail.com',
  'emporioeconomico@hotmail.com',
  'enielevidalf@gmail.com',
  'eosonline@gmail.com',
  'erica.c.oliver@hotmail.com',
  'erickgcomercial@gmail.com',
  'erikassol@gmail.com',
  'fabianoziem@gmail.com',
  'fabiomdm1@gmail.com',
  'fabricio.james26@gmail.com',
  'facere@facerelogistics.com.br',
  'familiacairuga@gmail.com',
  'fefeornellas@gmail.com',
  'feitosa.a.p@gmail.com',
  'felipegon01@gmail.com',
  'filipe2013@gmail.com',
  'filipemachado2013@gmail.com',
  'flaviomarao@gmail.com',
  'fna.nandanobre@gmail.com',
  'franciscotoca27@hotmail.com',
  'franpianowski@gmail.com',
  'gabidiasmello@gmail.com',
  'gabrielpsimoes@gmail.com',
  'gipascoalpatriani@gmail.com',
  'gisele.slopes66@gmail.com',
  'gracabeleza@hotmail.com',
  'gui.sk8ls@hotmail.com',
  'guigapfeijo@yahoo.com.br',
  'gustavooliveira160316@gmail.com',
  'gv.fabio.roger@gmail.com',
  'handriba@gmail.com',
  'helairrocha61@gmail.com',
  'heliometzker@hotmail.com',
  'heloisa914@hotmail.com',
  'igorrodrigues477@gmail.com',
  'igorsal07@gmail.com',
  'jacyolyver@hotmail.com',
  'jefersonarmiliato7@gmail.com',
  'jeisoncarvalhofarmaponte@gmail.com',
  'jerryhcj2@gmail.com',
  'jnicolauproducoes@gmail.com',
  'joaochaple@gmail.com',
  'jonathanwilliam1389@Gmail.com',
  'jordanabettu@yahoo.com.br',
  'jscarvalhobr@gmail.com',
  'ju.mcarneiro@hotmail.com',
  'juciaraam@gmail.com',
  'juliana.origuela@hotmail.com',
  'julio.n@hotmail.com',
  'juniorlimapessoaa.23@gmail.com',
  'karincgharpis@gmail.com',
  'karlapazosvendas@gmail.com',
  'keiser1652@gmail.com',
  'kelleklug@gmail.com',
  'ketely001@outlook.com',
  'klessyomerlin@gmail.com',
  'l.k14@hotmail.com',
  'lateofilo@gmail.com',
  'leao.sams@gmail.com',
  'leiakilt@gmail.com',
  'Leilianecunha_2007@hotmail.com',
  'leonardofq@uol.com.br',
  'leovieiraliima@gmail.com',
  'letrevisani38@gmail.com',
  'ligianeborges@hotmail.com',
  'line_jvm@hotmail.com',
  'lotusembu@gmail.com',
  'lucas1795@gmail.com',
  'Lucianamo18@yahoo.com.br',
  'lucioasf@gmail.com',
  'luferracioli@yahoo.com.br',
  'luizzmiltonn@gmail.com',
  'marcelle.mms@hotmail.com',
  'marcelosilvaaguai@hotmail.com',
  'marcosvianademoraes@hotmail.com',
  'margarethsaleslima@hotmail.com',
  'marigripa@gmail.com',
  'mariliacarolinefc@gmail.com',
  'marineidedutra1@gmail.com',
  'marisapetropolis123@gmail.com',
  'marlonarce2458@gmail.com',
  'marquimpersonal@gmail.com',
  'mdepgoncalves@bol.com.br',
  'meiremoura53@hotmail.com',
  'meperondi@yahoo.com.br',
  'merieleh@yahoo.com.br',
  'mfsantosandrade@hotmail.com',
  'mica538@hotmail.com',
  'moraescristian354@gmail.com',
  'ms.mario1705@gmail.com',
  'myrianfernandes@hotmail.com',
  'm_cantalice@yahoo.com.br',
  'm_carvalho01@hotmail.com',
  'nadiahari@gmail.com',
  'naidilaleao@gmail.com',
  'neporepresentacoes@gmail.com',
  'nessamendes14@gmail.com',
  'ozeas.cardoso.932@gmail.com',
  'patriciacostanegociosonline@gmail.com',
  'priscilaolinto23@gmail.com',
  'pugode11@gmail.com',
  'rafael.leitemperger@gmail.com',
  'ramyla.rsmartins@gmail.com',
  'raonitsinoar@gmail.com',
  'raphaeleusoualenda@gmail.com',
  'rcaita@gmail.com',
  're.teixeirasilva@gmail.com',
  'regcomercio@gmx.com',
  'renatacristhine@gmail.com',
  'renato_engenheiro@outlook.com',
  'ritadecassiasousa@terra.com.br',
  'rlscorp@yahoo.com',
  'rmourarp@gmail.com',
  'robertomega20@gmail.com',
  'robsonzanelatto@gmail.com',
  'RODRIGO.RXA.AFILIADO@GMAIL.COM',
  'romulor010101@gmail.com',
  'rosaalvares2005@hotmail.com',
  'rosecri7@gmail.com',
  'sallesandre2@gmail.com',
  'sauloprueza_32@yahoo.com.br',
  'sergiopietro@gmail.com',
  'shiu.stylle@gmail.com',
  'silavamachado@gmail.com',
  'silviainaba2017@gmail.com',
  'simoneassumpcao1@hotmail.com',
  'sousacristovao57@yahoo.com.br',
  'sperandio.empreendedor@gmail.com',
  'sramos.rose@gmail.com',
  'susanapdl@hotmail.com',
  'taina.hilleshein@outlook.com',
  'taniaregina171@gmail.com',
  'tatiana.evangelista@hotmail.com',
  'tayna96@gmail.com',
  'th.oliveira996@gmail.com',
  'thais.negociosonline@yahoo.com',
  'thiagoribeiro.e@gmail.com',
  'tw.adm79@gmail.com',
  'valeria.galeazzo@gmail.com',
  'valeriawglobal@gmail.com',
  'valeriguerra73@gmail.com',
  'VAREJOMIL@GMAIL.COM',
  'vbregadas@gmail.com',
  'veraponte@yahoo.com.br',
  'veronica_sansone_02@hotmail.com',
  'vicentemeninoa@gmail.com',
  'victorsollaoficial@gmail.com',
  'vilmamlmendonca@gmail.com',
  'viniwms@gmail.com',
  'vivian_mouralima@yahoo.com.br',
  'vi_fagnani@hotmail.com',
  'vm-vaidello@bol.com.br',
  'wagner_mouralavras@hotmail.com',
  'wellingtongomes@hotmail.com.br',
  'werley_planejamento@outlook.com',
  'wfernandobezerra@gmail.com',
  'wili.farias@outlook.com',
  'williancamatag@gmail.com',
  'wilmer.wfc@gmail.com',
  'zainne.rodrigues@gmail.com',
  'denisebanzasp@hotmail.com',
  'jackson.gouvea@yahoo.com.br',
  'marcelogomess.adv@gmail.com',
  'marcia_akimoto@yahoo.com.br',
  'mathe_h@hotmail.com',
  'renansilqueira@gmail.com',
  'segundinho.silvinha@gmail.com',
  'acsdjr@hotmail.com',
  'armandomsjunior@gmail.com',
  'carolinarbassi@gmail.com',
  'desconstruindocomnati@gmail.com',
  'elienesantos.coach@gmail.com',
  'junior.fonseca.corretor@gmail.com',
  'junior.shimanouti@gmail.com',
  'thiagoalves.silva@yahoo.com.br',
];

exports.newUsersEmails = newUsersEmails;
exports.oldUsersEmails = oldUsersEmails;
