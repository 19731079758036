import React from 'react';

const VideoTutorial = ({ src }) => (
  <>
    <hr />
    <div className="d-flex justify-content-center mt-5">
      <video src={src} controls playsInline>
        <track default kind="captions" srcLang="en" />
      </video>
    </div>
  </>
);

export default VideoTutorial;
