import React, { useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import InputRange from 'react-input-range';
import { features, features2 } from '../features';

const getPlanInfo = plan =>
  ({
    0: { name: 'Lite', description: 'R$ até 15mil / mês', value: '47,90' },
    1: { name: 'Starter', description: 'R$ até 50mil / mês', value: '97,90' },
    2: { name: 'Essentials', description: 'R$ até 100mil / mês', value: '147,90' },
    3: { name: 'Premium', description: 'R$ até 300mil / mês', value: '297,90' },
    4: { name: 'Pro', description: 'R$ até 500mil / mês', value: '497,90' },
    5: { name: 'Custom', description: 'R$ + de 500mil / mês', value: '?' },
  }[plan]);

const DefaultPlans = ({ onSubmitPlan, plans, subTitle, loading }) => {
  const [selectedPlan, setSelectedPlan] = useState(0);

  const onSubmitPlanCallback = useCallback(
    ({ planId, price, title, description }) => {
      return onSubmitPlan({ planId, price, title, description });
    },
    [onSubmitPlan],
  );

  return (
    <>
      <h2>Qual é a média do seu faturamento de pedidos pagos mensal?</h2>

      <InputRange
        maxValue={5}
        allowSameValues
        minValue={0}
        value={selectedPlan}
        onChange={setSelectedPlan}
        formatLabel={() => getPlanInfo(selectedPlan).description}
      />
      {selectedPlan === 5 && (
        <div>
          <h2>
            Entre em contato com suporte pelo email: contato@profitfy.me ou
            <br /> pelo Whatsapp: (11) 93423-5716
          </h2>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <p className="desc mb-0">Preço baseado no seu faturamento</p>
        <div className="price">
          <span>R$</span>
          <span className="value text-primary">{getPlanInfo(selectedPlan).value}</span>
          <span>/mês</span>
        </div>
      </div>
      <hr className="mt-1" />
      <h2>Benefícios do Plano</h2>
      <Row className=" align-content-center mt-2">
        <Col className="text-left">
          {features.map(el => (
            <div key={el} className="d-flex align-items-center mt-2">
              <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
            </div>
          ))}
        </Col>
        <Col className="text-left">
          {features2.map(el => {
            return (
              <div key={el} className="d-flex align-items-center mt-2">
                <i className="text-green ni ni-check-bold mr-2" /> <p className="mb-0"> {el}</p>
              </div>
            );
          })}
        </Col>
      </Row>
      <hr className="mt-3 mb-4" />
      <p className="mb-4 text-lead font-weight-bold">{subTitle}</p>
      <Button
        color="primary"
        className="mb-4 shadow"
        block
        disabled={selectedPlan === 5 || loading}
        onClick={() => {
          const parsedPlans = plans.find(plan => plan.name === getPlanInfo(selectedPlan).name);

          return onSubmitPlanCallback({
            planId: parsedPlans.id,
            price: parsedPlans.amount,
            title: parsedPlans.name,
            description: getPlanInfo(selectedPlan).description,
          });
        }}
      >
        <p className="text-uppercase mb-0 font-weight-bold text-lg">Próximo passo</p>
      </Button>
    </>
  );
};

export default DefaultPlans;
