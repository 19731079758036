import React, { useState, useCallback, memo } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { logout } from '../../services/auth';

const Sidebar = props => {
  const { routes, logo } = props;
  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleCollapse = useCallback(() => {
    setCollapseOpen(!collapseOpen);
  }, [collapseOpen]);

  const closeCollapse = useCallback(() => {
    setCollapseOpen(false);
  }, []);

  const createLinks = routes => {
    const adminRoutes = routes.filter(({ layout }) => layout.includes('/admin'));

    return adminRoutes.map(prop => (
      <NavItem key={prop.name}>
        <NavLink
          to={`${prop.layout}${prop.path}`}
          tag={NavLinkRRD}
          onClick={closeCollapse}
          activeClassName="active"
          className="text-light"
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    ));
  };

  return (
    <>
      {/* <div className="px-3 pl-md-9 bg-gradient-orange py-2">
        <p className="mb-0 pl-md-9 text-center text-white font-weight-bold">
          Caso seu marketing esteja zerado, basta deslogar e logar novamente no facebook em nossa
          plataforma. Caso tenha dúvidas entre em contato no whatstapp: (11) 93423-5716
        </p>
      </div> */}
      <Navbar
        className="navbar-vertical fixed-left navbar-dark bg-gradient-gray-dark"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

          <div className="img-fluid text-center d-none d-md-inline mt-3">
            <img alt={logo?.imgAlt} className="img-fluid" width="100%" src={logo?.imgSrc} />
          </div>

          <Nav className="align-items-center justify-content-end flex-grow-1 d-md-none">
            {/* { Select Store } */}

            {/* { Gear DropDown } */}
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <i className="ni ni-settings-gear-65 text-secondary" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem-Vindo!</h6>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem onClick={() => logout()}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  {logo?.innerLink ? (
                    <Link to={logo?.innerLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </Link>
                  ) : (
                    <Link href={logo?.outterLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </Link>
                  )}
                </Col>

                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}

            <Nav navbar>{createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3 bg-primary" />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default memo(Sidebar);
