import React, { useState, useCallback, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import { Card, CardHeader, Badge, Table } from 'reactstrap';
import Spinner from '../../Spinner/Spinner';
import { api } from '../../../services/api';
import UserContext from '../../../providers/UserContext';
import { numberBrFormatter } from '../../../utils/utils';
import { parseStatusName, getStatusColor } from '../../../utils/transactions';

const ProfileTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { value: user } = useContext(UserContext);

  const loadTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api('user-api').get(
        `api/v1/users/subscriptions/${user?.subscription?.id}`,
      );
      const { transactions } = data.subscription;
      setTransactions(transactions);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      loadTransactions();
    }
  }, [loadTransactions, user]);

  return (
    <Card className="shadow mt-4">
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <>
          <CardHeader className="d-flex justify-content-between">
            <h3 className="mb-1">Minhas Transações</h3>
          </CardHeader>

          <Table className="table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Valor</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(transaction => (
                <tr key={transaction.created}>
                  <td>
                    <p className="mb-0 font-weight-bold">
                      {moment(transaction.created).format('DD/MM/YYYY')}
                    </p>
                  </td>
                  <td>
                    <p className=" mb-0 font-weight-bold">
                      R$ {numberBrFormatter(transaction.paidAmount / 100, 2)}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0 h2">
                      <Badge color={getStatusColor(transaction.status)}>
                        {parseStatusName(transaction.status)}
                      </Badge>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Card>
  );
};

export default ProfileTransactions;
