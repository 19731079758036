import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { api, shopsApi } from '../../../../../services/api';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import { useToast } from '../../../../../providers/ToastContext';

const ProductCostModalDatePicker = ({ open, toggle, loadProductsData, productSlug }) => {
  const [period, setPeriod] = useState({ startDate: moment(), endDate: moment() });
  const { store, platform } = useParams();
  const { showToast } = useToast();

  const onDate = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPeriod({ startDate, endDate });
  };

  const handleSavePeriod = async () => {
    toggle();
    await api(shopsApi[platform]).put(
      `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/orders/recalculate-cogs?startDate=${period.startDate.format(
        'YYYY-MM-DD',
      )}&endDate=${period.endDate.format('YYYY-MM-DD')}`,
    );
    showToast({ message: 'Valores alterados com sucesso!', type: 'success' });
    loadProductsData();
  };

  return (
    <Modal centered fade isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Qual periodo deseja aplicar essas alterações?</ModalHeader>
      <ModalBody className="mx-3 my-3 bg-1">
        <Row>
          <Col>
            <DatePicker startDate={period.startDate} endDate={period.endDate} onDate={onDate} />
          </Col>
          <Button
            className="btn-icon btn-2"
            color="primary"
            type="button"
            onClick={handleSavePeriod}
          >
            <span className="btn-inner--text">Concluir</span>
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ProductCostModalDatePicker;
