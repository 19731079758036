import Swal from 'sweetalert2';
import { logout } from './auth';

const errorHandler = err => {
  const error = err?.response?.data;

  if (error?.plan || error?.subscription || error?.upgradePlan) {
    if (error?.plan) {
      Swal.fire({
        icon: 'info',
        title: 'Você precisa escolher seu plano.',
        text: error.message,
        showConfirmButton: true,
      }).then(() => {
        window.location.replace('/subscribe');
      });

      return Promise.reject(err.response);
    }

    if (error?.upgradePlan) {
      Swal.fire({
        icon: 'info',
        title: error.message,
        showConfirmButton: true,
      }).then(() => {
        window.location.replace(
          `/${error.store.platform}/${error.store.slug}/dashboard/user-profile`,
        );
      });

      return Promise.reject(err.response);
    }

    if (error?.subscription) {
      Swal.fire({
        icon: 'error',
        title: 'Oops... Houve um Erro!',
        text: error?.message,
        showConfirmButton: true,
      });
      return Promise.reject(err.response);
    }
  }

  if (error?.message === 'Network Error') {
    logout();
  }

  Swal.fire({
    icon: 'error',
    title: 'Oops... Houve um Erro!',
    text: error?.message,
    showConfirmButton: true,
  });

  return Promise.reject();
};

export default errorHandler;
