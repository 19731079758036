import React, { useCallback, useMemo, useState } from 'react';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useParams, useHistory, NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { logout } from '../../../services/auth';

const SidebarMobile = ({ routes, stores, logo }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const { store: slug, platform } = useParams();
  const history = useHistory();

  const toggleCollapse = useCallback(() => {
    setCollapseOpen(!collapseOpen);
  }, [collapseOpen]);

  const closeCollapse = useCallback(() => {
    setCollapseOpen(false);
  }, []);

  const selectedStore = useMemo(() => stores.find(store => store.slug === slug), [stores, slug]);

  const onChangeStore = useCallback(
    (slug, platform) => {
      history.push(`/${platform}/${slug}/dashboard/shop-analytics`);
    },
    [history],
  );

  const renderDropdownStores = useMemo(() => {
    return stores.map(store => (
      <div key={store.slug}>
        <DropdownItem tag="a" onClick={() => onChangeStore(store.slug, store.platform)}>
          <span className="text-md text-gray-dark font-weight-bold">
            {store.name}{' '}
            {selectedStore?.slug === store.slug && (
              <i className="ni ni-check-bold text-primary ml-2" />
            )}
          </span>
        </DropdownItem>
        <DropdownItem divider className="mx-3" />
      </div>
    ));
  }, [onChangeStore, stores, selectedStore]);

  const dashboardMenuRoutes = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard') {
        return (
          <NavItem key={prop.name}>
            <NavLink
              to={`/${platform}/${slug}${prop.layout}${prop.path}`}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="text-light"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      return [];
    });
  }, [closeCollapse, platform, routes, slug]);

  const dropdownRoutes = useMemo(() => {
    return routes.map(prop => {
      if (prop.layout === '/dashboard/config') {
        return (
          <NavItem key={prop.name}>
            <NavLink
              to={`/${platform}/${slug}${prop.layout}${prop.path}`}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="text-light "
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      return [];
    });
  }, [closeCollapse, platform, routes, slug]);

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark bg-gradient-gray-dark d-md-none"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}

        <div className="img-fluid text-center d-none d-md-inline mt-3">
          <img alt={logo?.imgAlt} className="img-fluid" width="100%" src={logo?.imgSrc} />
        </div>

        <Nav className="align-items-center justify-content-end flex-grow-1 d-md-none">
          <UncontrolledDropdown className="gray-dark flex-grow-1" nav>
            <DropdownToggle className="text-center" nav>
              <span className="font-weight-bold text-md text-secondary">{selectedStore?.name}</span>
              <i className="ni ni-bold-down text-secondary mr-3 pl-3" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              {renderDropdownStores}
              <DropdownItem href="/stores">
                <span className="text-blue">Registar nova loja</span>
                <i className="ni ni-shop text-blue ml-2" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {/* { Gear DropDown } */}
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <i className="ni ni-settings-gear-65 text-secondary" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bem-Vindo!</h6>
              </DropdownItem>
              <DropdownItem to={`/${platform}/${slug}/dashboard/user-profile`} tag={Link}>
                <i className="ni ni-single-02" />
                <span>Meu Perfil</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => logout()}>
                <i className="ni ni-user-run" />
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                {logo?.innerLink ? (
                  <Link to={logo?.innerLink}>
                    <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                  </Link>
                ) : (
                  <Link href={logo?.outterLink}>
                    <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                  </Link>
                )}
              </Col>

              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}

          <Nav navbar>{dashboardMenuRoutes}</Nav>
          {/* Divider */}
          <hr className="my-3 bg-primary" />
          <h2 className="navbar-heading text-secondary">Configurações</h2>
          <Nav navbar>{dropdownRoutes}</Nav>
          <hr className="my-3 bg-primary" />
          {/* Heading */}
          <h6 className="navbar-heading text-secondary">Central de Ajuda</h6>
          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink
                href="https://docs.profitfy.me/tutoriais/integracao-com-mercado-pago"
                className="text-light"
              >
                <i className="ni ni-support-16 text-green" />
                Tutoriais
              </NavLink>

              <NavLink href="https://docs.profitfy.me/outros/fale-conosco" className="text-light">
                <i className="ni ni-mobile-button text-light" />
                Contate-nos
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default SidebarMobile;
