import React, { useState, useEffect, useCallback, memo } from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import FacebookFormProfiles from './Profiles/Profiles';
import FacebookAdAccounts from './AdAccounts/AdAccounts';
import FacebookFormContext from '../../providers/FacebookFormContext';
import { api, shopsApi, makeSearchRequest } from '../../services/api';
import './FacebookForm.css';

const apisName = {
  cartx: 'ornn-cartx-api',
  shopify: 'urgot-shopify-api',
};

const FacebookForm = ({ fluid = true, onSave = false, className }) => {
  const [loading, setLoading] = useState(false);
  const [adAccounts, setAdAccounts] = useState([]);
  const [pagesCountAdAccount, setPagesCountAdAccount] = useState(1);
  const [pagesCountProfiles, setPagesCountProfiles] = useState(1);
  const [pageAdAccount, setPageAdAccount] = useState(1);
  const [pageProfiles, setPageProfiles] = useState(1);
  const [loadingAdAccountStatus, setLoadingAdAccountStatus] = useState({ status: false, id: null });
  const [profiles, setProfiles] = useState([]);
  const [adAccountName, setAdAccountName] = useState('');
  const { store, platform } = useParams();
  const limitAdAccounts = 10;
  const limitProfiles = 5;

  const value = { loading, adAccounts, loadingAdAccountStatus };

  const loadAdAccounts = useCallback(
    async (spinner = true) => {
      setLoading(spinner);

      const search = makeSearchRequest({ api: apisName[platform] });
      const response = await search(
        `api/v1/users/${platform}/stores/${store}/facebook/ad-accounts?page=${pageAdAccount}&name=${adAccountName}&limit=${limitAdAccounts}`,
      );

      if (response) {
        setAdAccounts(response.facebookAdAccounts);
        setPagesCountAdAccount(response.numberOfPages);
        setLoading(false);
      }
    },
    [platform, store, pageAdAccount, adAccountName],
  );

  const loadProfiles = useCallback(async () => {
    const { data } = await api('user-api').get(
      `api/v1/users/facebook/credential?page=${pageProfiles}&limit=${limitProfiles}`,
    );

    setProfiles(data.credentials);
    setPagesCountProfiles(data.numberOfPages);
  }, [pageProfiles]);

  const handleFacebookLogout = async profileId => {
    await api('user-api').delete(`api/v1/users/facebook/credential/${profileId}`);
    loadProfiles();
    loadAdAccounts();
  };

  const handleFacebookLogin = async responseFacebook => {
    const parsedData = {
      name: responseFacebook.name || 'Sem nome',
      ...responseFacebook,
    };

    try {
      setLoading(true);
      const { data } = await api('user-api').post(`api/v1/users/facebook/credential`, parsedData);

      await api('user-api').post(
        `api/v1/users/facebook/credential/${data.credential.id}/ad-accounts`,
      );

      await loadProfiles();
      await loadAdAccounts(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePageAdAccount = page => {
    setPageAdAccount(page.selected + 1);
  };

  const handleChangePageProfiles = page => {
    setPageProfiles(page.selected + 1);
  };

  const handleCheckBox = async event => {
    const { checked, id } = event.target;

    try {
      setLoadingAdAccountStatus({ status: true, id });
      api(shopsApi[platform]).patch(
        `api/v1/users/${platform}/stores/${store}/facebook/ad-accounts/${id}`,
        {
          active: checked,
        },
      );
    } finally {
      setLoadingAdAccountStatus({ status: false, id });
    }
  };

  useEffect(() => {
    loadProfiles();
  }, [loadProfiles]);

  useEffect(() => {
    loadAdAccounts(true);
  }, [loadAdAccounts]);

  return (
    <FacebookFormContext.Provider
      value={{
        handleCheckBox,
        handleFacebookLogin,
        handleFacebookLogout,
        handleChangePageAdAccount,
        handleChangePageProfiles,
        setLoading,
        setAdAccountName,
        pagesCountAdAccount,
        pagesCountProfiles,
        value,
        platform,
        store,
        profiles,
        onSave,
      }}
    >
      <Container fluid={fluid} className={className || 'pb-8 pt-5 pt-md-8'}>
        <FacebookFormProfiles />
        <FacebookAdAccounts />
      </Container>
    </FacebookFormContext.Provider>
  );
};

export default memo(FacebookForm);
