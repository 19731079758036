import React from 'react';
import { Table, Badge } from 'reactstrap';
import moment from 'moment-timezone';
import { numberBrFormatter } from '../../../../utils/utils';

const parseStatusName = status =>
  ({
    PAID: 'Sem Informações',
    REFUNDED: 'Sem Informações',
    PENDING: 'Pendente',
    in_mediation: 'Com Disputa',
    in_process: 'Em análise',
    cancelled: 'Cancelado',
    rejected: 'Rejeitado',
    approved: 'Aprovado',
    refunded: 'Devolvido',
    charged_back: 'Estornado',
    pending: 'Pendente',
  }[status] || 'Sem Status');

const getStatusColor = status =>
  ({
    PAID: 'dark',
    REFUNDED: 'dark',
    PENDING: 'orange',
    in_mediation: 'purple',
    in_process: 'blue',
    cancelled: 'danger',
    rejected: 'danger',
    approved: 'dark-green',
    refunded: 'yellow',
    charged_back: 'danger',
    pending: 'orange',
  }[status] || 'gray');

const getProfitColor = profit => {
  if (profit > 0) return 'success';
  if (profit < 0) return 'none';

  return 'gray';
};

const BilletTableBody = ({ billets, platform, domain }) => {
  const parseStoreLink = () =>
    ({
      shopify: `https://${domain}/admin/orders/`,
      cartx: `https://${domain}/orders/details/`,
    }[platform]);

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">N. Pedido</th>
            <th scope="col">Data do Pedido</th>
            <th scope="col">Compensado em</th>
            <th scope="col">Produto(s)</th>
            <th scope="col">Status</th>
            <th scope="col">Faturamento</th>
            <th scope="col">Cogs</th>
            <th scope="col">Tax. Gateway</th>
            <th scope="col">Tax. Checkout</th>
            <th scope="col">Imposto</th>
            <th scope="col">Lucro</th>
          </tr>
        </thead>
        <tbody>
          {billets.map(el => (
            <tr key={el.id}>
              <td>
                <p className="mb-0">
                  <a href={`${parseStoreLink()}${el.id}`} target="blank" className="text-blue">
                    <span className="fas fa-external-link-alt" /> {el.name}
                  </a>
                </p>
              </td>
              <td>
                <p className="mb-0">{moment(el.createdAt).format('DD/MM/YYYY')}</p>
              </td>
              <td>
                <p className="mb-0">{moment(el.approvedAt).format('DD/MM/YYYY')}</p>
              </td>
              <td style={{ maxWidth: '200px' }}>
                <ul className="pl-0">
                  {el.products.map(product => (
                    <li key={product} className="text-truncate">
                      <span>&#9679; {product}</span>
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <p className="mb-0">
                  <Badge className={`badge-${getStatusColor(el.status)}`} color="none">
                    {parseStatusName(el.status)}
                  </Badge>
                </p>
              </td>
              <td>
                <p className="mb-0">R$ {numberBrFormatter(el.revenue)}</p>
              </td>
              <td>
                <p className="mb-0">R$ {numberBrFormatter(el.cogs)} </p>
              </td>
              <td>
                <p className="mb-0">R$ {numberBrFormatter(el.gatewayFee)} </p>
              </td>
              <td>
                <p className="mb-0">R$ {numberBrFormatter(el.checkoutFee)} </p>
              </td>
              <td>
                <p className="mb-0">R$ {numberBrFormatter(el.tax)} </p>
              </td>
              <td>
                <p className="mb-0">
                  <Badge color={getProfitColor(el.profit)}>
                    {' '}
                    R$ {numberBrFormatter(el.profit)}
                  </Badge>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BilletTableBody;
