import React from 'react';
import { PacmanLoader } from 'react-spinners';
import './styles.scss';

const Spinner = ({ loading, absoluteCenter, color = '#7dd56f' }) => (
  <div className={absoluteCenter ? `spinner-absolute-center` : `spinner-center`}>
    <PacmanLoader sizeUnit="px" size={30} color={color} loading={loading} />
  </div>
);

export default Spinner;
