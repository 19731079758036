import React, { useState } from 'react';
import NewUserFluxLineItem from '../LineItem/LineItem';
import FacebookForm from '../../../components/FacebookForm/FacebookForm';
import facebookLogo from '../../../assets/img/theme/facebook-icon.png';

const NewUserFluxMarketing = () => {
  const [component, setComponent] = useState(() => {});

  const handleConnect = event => {
    const { id } = event.target;

    const component = {
      facebook: <FacebookForm fluid={false} className="mt-0" />,
    }[id];

    setComponent(component);
  };

  return (
    <>
      {component || (
        <div className="p-4">
          <h3 className="mb-0">Conecte-se a uma plataforma de Marketing</h3>
          <p className="text-muted text-sm mb-0">
            Isso é importante para que você possa vincular seus gastos com anúncios às suas análises
            de lucro.
          </p>
          <hr />
          <NewUserFluxLineItem
            title="Facebook"
            logo={facebookLogo}
            handleConnect={handleConnect}
            id="facebook"
          />
        </div>
      )}
    </>
  );
};

export default NewUserFluxMarketing;
