import React, { useState } from 'react';
import { Container, Card, Row, Col, Button, CardBody } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import InputRange from 'react-input-range';
import { features, features2 } from '../../components/Plans/features';
import logo from '../../assets/img/brand/logo-white.svg';
import BubblesBackground from '../../components/BubblesBackground/BubblesBackground';

const getPlanInfo = plan =>
  ({
    0: { name: 'Lite', description: 'R$ até 15mil / mês', value: '47,90' },
    1: { name: 'Starter', description: 'R$ até 50mil / mês', value: '97,90' },
    2: { name: 'Essentials', description: 'R$ até 100mil / mês', value: '147,90' },
    3: { name: 'Premium', description: 'R$ até 300mil / mês', value: '297,90' },
    4: { name: 'Pro', description: 'R$ até 500mil / mês', value: '497,90' },
    5: { name: 'Custom', description: 'R$ + de 500mil / mês', value: '?' },
  }[plan]);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Prices = () => {
  const query = useQuery();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const ref = query.get('ref');

  return (
    <BubblesBackground>
      <div className="main-content subscribe">
        <div className="header py-5 py-lg-4 vh-90">
          <Container className="subscribe-width">
            <Row className="justify-content-center">
              <Col>
                <div className="img-fluid text-center">
                  <img alt="profitfy" className="img-fluid" width="350px" src={logo} />
                </div>
              </Col>
            </Row>
            <Row className="mt-6">
              <Col>
                <Card className="card-shadow">
                  <CardBody className="pb-5">
                    <Container className="plans px-md-6">
                      <Row className="justify-content-center align-items-center">
                        <h1 className="title font-weight-normal text-center pt-2 px-md-3">
                          Os planos baseiam-se em seu faturamento! <br />
                          Assim seu bolso fica feliz da vida, né?
                        </h1>
                        <h3 className="text-primary mt-3 mb-3 display-4 text-center">
                          Assine hoje e tenha 7 dias grátis!
                        </h3>
                        <Card className="shadow px-md-5 px-3 pt-4">
                          <h2>Qual é a média do seu faturamento de pedidos pagos mensal?</h2>
                          <InputRange
                            maxValue={5}
                            allowSameValues
                            minValue={0}
                            value={selectedPlan}
                            onChange={setSelectedPlan}
                            formatLabel={() => getPlanInfo(selectedPlan).description}
                          />
                          {selectedPlan === 5 && (
                            <div>
                              <h2>
                                Entre em contato com suporte pelo email: contato@profitfy.me ou
                                <br /> pelo Whatsapp: (11) 93423-5716
                              </h2>
                            </div>
                          )}
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <p className="desc mb-0">Preço baseado no seu faturamento</p>
                            <div className="price">
                              <span>R$</span>
                              <span className="value text-primary">
                                {getPlanInfo(selectedPlan).value}
                              </span>
                              <span>/mês</span>
                            </div>
                          </div>
                          <hr className="mt-1" />
                          <h2>Benefícios do Plano</h2>
                          <Row className=" align-content-center mt-2">
                            <Col className="text-left">
                              {features.map(el => (
                                <div key={el} className="d-flex align-items-center mt-2">
                                  <i className="text-green ni ni-check-bold mr-2" />{' '}
                                  <p className="mb-0"> {el}</p>
                                </div>
                              ))}
                            </Col>
                            <Col className="text-left">
                              {features2.map(el => {
                                return (
                                  <div key={el} className="d-flex align-items-center mt-2">
                                    <i className="text-green ni ni-check-bold mr-2" />{' '}
                                    <p className="mb-0"> {el}</p>
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                          <hr className="mt-3 mb-4" />
                          <Link to={`/auth/register?ref=${ref}`}>
                            <Button color="primary" className="mb-4 shadow" block>
                              <p className="text-uppercase mb-0 font-weight-bold text-lg">
                                Testar por 7 dias Grátis
                              </p>
                            </Button>
                          </Link>
                        </Card>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </BubblesBackground>
  );
};

export default Prices;
