/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { api, shopsApi } from '../../../../../services/api';

const BulkEdit = ({ loadProductsData }) => {
  const { platform, store } = useParams();

  const handleCurrency = useCallback(
    async currency => {
      await api(shopsApi[platform]).patch(
        `/api/v1/users/${platform}/stores/${store}/products/currency`,
        {
          currency,
        },
      );

      await loadProductsData(true);
    },
    [platform, store, loadProductsData],
  );

  const handleIOF = useCallback(
    async buyMethod => {
      await api(shopsApi[platform]).patch(
        `/api/v1/users/${platform}/stores/${store}/products/buy-method`,
        {
          buyMethod,
        },
      );

      await loadProductsData(true);
    },
    [platform, store, loadProductsData],
  );

  return (
    <UncontrolledPopover placement="left" className="w-100" flip target="filter">
      <PopoverBody>
        <Row>
          <Col>
            <div className="d-flex mr-2 text-center">
              <div>
                <span className="mr-3 font-weight-bold">Moeda</span>
                <Input
                  className="input-group-alternative"
                  type="select"
                  name="currency"
                  id="currency"
                  defaultValue="NONE"
                  onChange={event => handleCurrency(event.target.value)}
                >
                  <option disabled value="NONE">
                    Selecione...{' '}
                  </option>
                  <option value="BRL">Real</option>
                  <option value="USD">Dólar</option>
                </Input>
              </div>
              <div className="ml-3 text-center">
                <span className="mr-3 font-weight-bold">IOF</span>
                <Input
                  className="input-group-alternative"
                  type="select"
                  name="select"
                  id="selectIOF"
                  data-show-icon="true"
                  onChange={event => handleIOF(event.target.value)}
                  defaultValue="NONE"
                >
                  <option disabled value="NONE">
                    Selecione...
                  </option>
                  <option value="CARD">Cartão de Crédito 6,38%</option>
                  <option value="TICKET">Boleto Bancário 0,38%</option>
                </Input>
              </div>
            </div>
          </Col>
        </Row>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default BulkEdit;
