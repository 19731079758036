import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import Login from './Login/Login';
import Register from './Register/Register';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Footer from '../../components/Footer/Footer';
import logo from '../../assets/img/brand/logo-white.svg';
import BubblesBackground from '../../components/BubblesBackground/BubblesBackground';

const Auth = props => {
  return (
    <>
      <BubblesBackground>
        <div className="main-content">
          {/* <AuthNavbar /> */}
          <div className="header py-5 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="8">
                    <div className="img-fluid text-center">
                      <img alt="logo white" className="img-fluid" width="80%" src={logo} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}

          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                <Route path="/auth/login" component={() => <Login {...props} />} />
                <Route path="/auth/register" component={() => <Register {...props} />} />
                <Route
                  path="/auth/forgot-password"
                  component={() => <ForgotPassword {...props} />}
                />
                <Route
                  path="/auth/reset-password/:resetToken"
                  component={() => <ResetPassword {...props} />}
                />
              </Switch>
            </Row>
          </Container>
        </div>
        <Footer transparent />
      </BubblesBackground>
    </>
  );
};

export default Auth;
