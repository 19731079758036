/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col, Button, Input, Form } from 'reactstrap';
import useForm from 'react-hook-form';

const Filter = ({ handleFilter }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = useCallback(
    orderFilters => {
      if (orderFilters.status === 'null') orderFilters.status = null;
      if (orderFilters.type === 'null') orderFilters.type = null;
      if (orderFilters.hasTrackingNumber === 'null') orderFilters.hasTrackingNumber = null;
      if (orderFilters.hasTrackingNumber === 'true') orderFilters.hasTrackingNumber = true;

      handleFilter(orderFilters);
    },
    [handleFilter],
  );

  return (
    <div className="mr-3">
      <Button className="text-blue-marine" id="filter" type="button">
        <i className="fa fa-filter" />
      </Button>
      <UncontrolledPopover placement="left" className="w-100 " flip target="filter">
        <PopoverBody className="py-4">
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <Row>
              <Col sm="12" md="4" className="mt-3 mt-md-0">
                <div className="text-center">
                  <span className="font-weight-bold mt-sm-3 mt-md-0">Status</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="status"
                    id="status"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Nenhum</option>
                    <option value="approved">Aprovado</option>
                    <option value="pending">Pendente</option>
                    <option value="rejected">Rejeitado</option>
                    <option value="no_info">Sem informações</option>
                    <option value="charged_back">Estornado</option>
                    <option value="cancelled">Cancelado</option>
                    <option value="refunded">Devolvido</option>
                    <option value="in_mediation">Com Disputa</option>
                    <option value="in_process">Em Análise</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" md="4" className="mt-3 mt-md-0">
                <div className=" text-center">
                  <span className=" font-weight-bold mt-3 mt-md-0">Tipo</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="type"
                    id="type"
                    data-show-icon="true"
                    defaultValue="null"
                    innerRef={register}
                  >
                    <option value="null">Nenhum</option>
                    <option value="credit_card">Cartão de Crédito</option>
                    <option value="ticket">Boleto Bancário</option>
                    <option value="debit_card">Cartão de Débito</option>
                    <option value="account_money">Depósito</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" md="4" className="mt-3 mt-md-0">
                <div className=" text-center">
                  <span className=" font-weight-bold mt-3 mt-md-0">Cod. Rastreio</span>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="hasTrackingNumber"
                    id="hasTrackingNumber"
                    data-show-icon="true"
                    defaultValue={false}
                    innerRef={register}
                  >
                    <option value="null">Nenhum</option>
                    <option value="true">Com Rastreio</option>
                  </Input>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="text-right">
                <Button type="submit" color="primary">
                  Buscar <i className="ml-2 fa fa-search" />
                </Button>
              </Col>
            </Row>
          </Form>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default Filter;
