import React, { useState, useEffect, useCallback, memo } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ProductCostTableHeader from './TableHeader/TableHeader';
import ProductCostTableBody from './TableBody/TableBody';
import ProductCostTableFooter from './TableFooter/TableFooter';
import Spinner from '../../../components/Spinner/Spinner';
import { shopsApi, makeSearchRequest } from '../../../services/api';
import ImportCSV from './ImportCSV/ImportCSV';

const ProductCost = ({ className, limit = 15 }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productTitle, setProductTitle] = useState('');
  const [pagesCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const { store, platform } = useParams();

  const loadProductsData = useCallback(
    async load => {
      setLoading(Boolean(load));

      const search = await makeSearchRequest({ api: shopsApi[platform] });
      const data = await search(
        `api/v1/users/${platform}/stores/${store}/products?page=${page}&title=${productTitle}&limit=${limit}`,
      );

      if (data) {
        setProducts(data.products);
        setPageCount(data.numberOfPages);
        setLoading(false);
      }
    },
    [page, platform, store, limit, productTitle],
  );

  const onChangePage = page => {
    setPage(page.selected + 1);
  };

  const onSearch = async event => {
    const { value } = event.target;
    setPage(1);
    setProductTitle(value);
  };

  useEffect(() => {
    loadProductsData(true);
  }, [loadProductsData]);

  return (
    <Container fluid className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <ImportCSV />
        </Col>
      </Row>
      <Row>
        <div className="col">
          <Card className="shadow">
            <ProductCostTableHeader onSearch={onSearch} loadProductsData={loadProductsData} />
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <ProductCostTableBody products={products} loadProductsData={loadProductsData} />
            )}
            <ProductCostTableFooter pages={pagesCount} onChangePage={onChangePage} />
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default memo(ProductCost);
