import React from 'react';
import {
  CardHeader,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';

const TopProductsTableHeader = ({ handleSearch }) => {
  return (
    <CardHeader className="border-0">
      <Row>
        <Col>
          <div>
            <h3 className="mb-0">Lista de Produtos</h3>
            <p className="text-muted text-sm mb-0">
              Aqui você pode analisar produtos individualmente.
            </p>
          </div>
        </Col>
        <Col sm="12" md="6" className="mt-2 mt-md-0">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Nome do Produto"
              type="text"
              name="Search"
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
      </Row>
    </CardHeader>
  );
};

export default TopProductsTableHeader;
