import React, { useEffect, useCallback, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from './Card/Card';
import { api } from '../../../services/api';
import { numberBrFormatter } from '../../../utils/utils';

const Dashboard = () => {
  const [metrics, setMetrics] = useState({
    trialToCancelledEvasionRate: 0,
    paidToCancelledEvasionRate: 0,
  });
  const [usersRecovered, setUsersRecovered] = useState(0);
  const [usersPlan, setUsersPlan] = useState({});

  const loadUserMetrics = useCallback(async () => {
    const [metricsData, planData] = await Promise.all([
      api('user-api').get(`/api/v1/admin/dashboard-users/metrics`),
      api('user-api').get(`/api/v1/admin/dashboard-users/plan-status-revenue`),
    ]);

    setUsersPlan(planData.data);
    setMetrics(metricsData.data.metrics);
    setUsersRecovered(metricsData.data.totalRecoveredUsers);
  }, []);

  useEffect(() => {
    loadUserMetrics();
  }, [loadUserMetrics]);

  return (
    <Container fluid className=" pt-5 pt-md-8">
      <h1>Bem-vindo, meu lindo</h1>
      <hr className="mb-4" />
      <Row className="mt-4">
        <Col>
          <Card
            value={numberBrFormatter(usersPlan.trialingRevenue)}
            title="Faturamento Usuários Trial "
            prepend="R$"
          />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={numberBrFormatter(usersPlan.unpaidRevenue)}
            title="Faturamento Pagamento Pendente"
            prepend="R$"
          />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={numberBrFormatter(usersPlan.canceledRevenue)}
            title="Faturamento Usuários Cancelados"
            prepend="R$"
          />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={numberBrFormatter(usersPlan.paidRevenue)}
            title="Faturamento Pagos"
            prepend="R$"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card value={metrics.paidUsers} title="Usuários com Plano Ativo" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card value={metrics.totalUsers} title="Usuários Registrados" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card value={metrics.trialingUsers} title="Usuários em Trial" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={metrics.unpaidUsers + metrics.waitingPaymentUsers}
            title="Usuários com pagamento recusado"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card value={metrics.canceledUsers} title="Usuários com Plano Cancelado" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card value={metrics.usersWithoutPlan} title="Usuários sem plano" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card value={usersRecovered} title="Usuários que voltaram" />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={numberBrFormatter(metrics.ticketAverage)}
            title="Ticket Médio"
            prepend="R$"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card
            value={`${metrics.trialToCancelledEvasionRate.toFixed(2) * 100}%`}
            title="Taxa de Evasão Trial"
          />
        </Col>
        <Col className="mt-md-0 mt-3">
          <Card
            value={`${metrics.paidToCancelledEvasionRate.toFixed(2) * 100}%`}
            title="Taxa de Evasão Cliente"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
