import React, { memo } from 'react';
import { Card } from 'reactstrap';

const ProductDetailsCardDetails = ({ values }) => {
  return (
    <Card className="shadow px-4 py-2">
      <div className="d-flex justify-content-between">
        <small className="text-muted text-uppercase text-xs">{values[0].title}</small>
        <small className={`text-${values[0].color}`}>{values[0].value}</small>
      </div>
      <hr className="my-1" />
      <div className="d-flex justify-content-between">
        <small className="text-muted text-uppercase text-xs">{values[1].title}</small>
        <small className={`text-${values[1].color}`}>{values[1].value}</small>
      </div>
    </Card>
  );
};

export default memo(ProductDetailsCardDetails);
