import React, { createContext, useContext, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { api, shopsApi } from '../services/api';

const ProductDetails = createContext();

export const ProductDetailsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    profit: {
      productId: 'Carregando',
      profit: 0,
      cpa: 0,
      totalOrders: 0,
      totalOrdersApproved: 0,
      revenue: 0,
      convertedBillet: 0,
      totalBillets: 0,
      waitOrders: 0,
      totalCardsApproved: 0,
      totalCardsCancelled: 0,
      refundedAmount: 0,
      totalCardsRejected: 0,
      totalOrdersInProcessing: 0,
      totalOrdersUnknown: 0,
      totalOrdersRefunded: 0,
      totalOrdersChargedBack: 0,
      billetConversion: 0,
      gatewayFee: 0,
      checkoutFee: 0,
      cogs: 0,
      tax: 0,
      marketing: 0,
      profitMargin: 0,
      createdAt: '2020-06-09T03:00:03.000Z',
    },
    facebookInsights: {
      spend: 0,
      cpc: 0,
      cpm: 0,
      ctr: 0,
      initiatedCheckout: 0,
      addToCart: 0,
      purchase: 0,
      roas: 0,
      costPerConversion: 0,
      viewContent: 0,
      addPaymentInfo: 0,
    },
    product: {
      id: 'Carregando',
      title: 'Carregando',
      image: '',
      currency: 'BRL',
      buyMethod: 'TICKET',
      slug: 'Carregando',
      shopifyStoreId: 'Carregando',
    },
  });

  const loadProductDetails = useCallback(async ({ platform, store, productSlug, period }) => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/profit?startDate=${moment(
          period.startDate,
        ).format('YYYY-MM-DD')}&endDate=${moment(period.endDate).format('YYYY-MM-DD')}`,
      );

      setProductDetails(data);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <ProductDetails.Provider value={{ loading, productDetails, loadProductDetails }}>
      {children}
    </ProductDetails.Provider>
  );
};

export const useProductDetails = () => {
  const context = useContext(ProductDetails);

  if (!context) {
    throw new Error('useProductDetails must be used within a ProductDetailsProvider');
  }

  return context;
};
