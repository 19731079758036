import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardImg } from 'reactstrap';

const CardIntegration = ({ img, alt, xl, lg, sm, xs, link }) => {
  return (
    <Col xl={xl} lg={lg} sm={sm} xs={xs} className="mt-4">
      <Link to={link}>
        <Card className="mb-4 mb-xl-0 card-shadow border bg-white p-0">
          <CardImg top className="img-fluid" src={img} alt={alt} />
        </Card>
      </Link>
    </Col>
  );
};

export default CardIntegration;
