import React, { useState, useCallback } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import filesize from 'filesize';

import csvImage from '../../../../../assets/img/theme/csv.svg';
import Upload from '../../../../../components/Upload/Upload';
import { api, shopsApi } from '../../../../../services/api';
import { useToast } from '../../../../../providers/ToastContext';

const stage = process.env.REACT_APP_STAGE || 'dev';

function ImportCSVModal({ open, toggle }) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { store, platform } = useParams();
  const { showToast } = useToast();

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    if (!uploadedFile) return;

    data.append('file', uploadedFile.file, uploadedFile.name);

    try {
      setLoading(true);
      await api(shopsApi[platform]).post(
        `api/v1/users/${platform}/stores/${store}/variants/csv/import`,
        data,
      );
      showToast({ message: 'Arquivo enviado com sucesso!', type: 'success' });
      toggle();
    } finally {
      setLoading(false);
    }
  }, [platform, store, showToast, uploadedFile, toggle]);

  const submitFile = useCallback(files => {
    const uploadFile = files.pop();

    const parsedUploadFile = {
      file: uploadFile,
      name: uploadFile.name,
      readableSize: filesize(uploadFile.size),
    };

    setUploadedFile(parsedUploadFile);
  }, []);

  return (
    <Modal centered fade isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Edite o custo do seus produtos em massa</ModalHeader>
      <ModalBody className="mx-3 my-3 bg-1">
        <Row>
          <span>Selecione o arquivo Excel (.CSV) importe para a Profitfy.me</span>
          <span>
            É necessário que{' '}
            <a
              className="text-blue px-0"
              href={`https://${shopsApi[platform]}.profitfy.me/${stage}/api/v1/users/${platform}/stores/${store}/variants/csv`}
              target="blank"
            >
              faça download da nossa planilha modelo clicando aqui
            </a>
            , edite os dados e importe-a.
          </span>
        </Row>
        <Row className="mt-6 align-align-items-start">
          <Col xs="4">
            <img src={csvImage} className="pl-3" alt="profitfy.me" />
          </Col>
          <Col>
            <Upload onUpload={submitFile} />
            {uploadedFile && (
              <div className="mt-4 ml-1 d-flex flex-column">
                <strong>{uploadedFile.name}</strong>
                <span className="text-gray">{uploadedFile.readableSize}</span>
              </div>
            )}
          </Col>
        </Row>
        <hr className="my-5" />
        <Row>
          <Col className="d-flex justify-content-end">
            <Button type="button" color="primary" onClick={handleUpload} disabled={loading}>
              {loading ? 'Enviando ...' : 'Importar'} <i className="fa fa-cloud-upload-alt ml-2" />
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default ImportCSVModal;
