import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, CardHeader, CardBody, Button, Badge, Modal, ModalHeader } from 'reactstrap';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Spinner from '../../Spinner/Spinner';
import AddCreditCard from '../../AddCreditCard/AddCreditCard';
import Plans from '../../Plans/Plans';
import { numberBrFormatter } from '../../../utils/utils';
import UserContext from '../../../providers/UserContext';
import { api } from '../../../services/api';

const getCardBrandImage = cardBrand =>
  ({
    amex: 'https://profitfy-media.s3-us-west-2.amazonaws.com/amex.svg',
    visa: 'https://profitfy-media.s3-us-west-2.amazonaws.com/visa.svg',
    mastercard: 'https://profitfy-media.s3-us-west-2.amazonaws.com/mastercard.svg',
    elo: 'https://profitfy-media.s3-us-west-2.amazonaws.com/elo.svg',
    hipercard: 'https://profitfy-media.s3-us-west-2.amazonaws.com/hiper.svg',
  }[cardBrand]);

const getStatusColor = status =>
  ({
    canceled: 'danger',
    paid: 'primary',
    pending: 'warning',
    trialing: 'info',
  }[status]);

const parseStatusText = status =>
  ({
    canceled: 'cancelado',
    paid: 'ativo',
    pending: 'pendente',
    trialing: 'em trial',
  }[status]);

const ProfileSubscribe = () => {
  const [card, setCard] = useState({});
  const [subscription, setSubscription] = useState({});
  const [plan, setPlan] = useState({});
  const [isOpenCreditCard, setIsOpenCreditCard] = useState(false);
  const [isOpenPlans, setIsOpenPlans] = useState(false);
  const [loading, setLoading] = useState(false);
  const { value: user, loadUser } = useContext(UserContext);

  const toggleCreditCard = () => {
    setIsOpenCreditCard(!isOpenCreditCard);
  };

  const togglePlans = () => {
    setIsOpenPlans(!isOpenPlans);
  };

  const loadData = useCallback(async () => {
    try {
      if (user) {
        setLoading(true);
        const [
          { data: subscriptionData },
          { data: cardData },
          { data: planData },
        ] = await Promise.all([
          api('user-api').get(`api/v1/users/subscriptions/${user.subscription.id}`),
          api('user-api').get(`api/v1/users/cards/${user.subscription.cardId}`),
          api('user-api').get(`api/v1/users/plans/${user.subscription.planId}`),
        ]);

        setSubscription(subscriptionData.subscription);
        setCard(cardData.card);
        setPlan(planData.plan);
      }
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSubmitPlan = async ({ planId }) => {
    try {
      setLoading(true);
      await api('user-api').post('api/v1/users/subscriptions', {
        planId: String(planId),
        documentNumber: user.cpf,
      });

      await Swal.fire({
        icon: 'success',
        title: 'Plano alterado com sucesso!',
        showConfirmButton: false,
        timer: 1500,
      });
      await loadUser();
    } finally {
      setLoading(false);
      togglePlans();
    }
  };

  const handleCancelSubscription = async () => {
    await api('user-api').delete(`api/v1/users/subscriptions/${user.subscription.id}`);
    await Swal.fire({
      icon: 'success',
      title: 'Plano cancelado',
      text: 'É uma pena que tenha cancelado, caso precise de ajuda entre em contato conosco!',
      showConfirmButton: false,
      timer: 5000,
    });
    await loadData();
  };

  return (
    <>
      <AddCreditCard title="Atualizar Cartão" isOpen={isOpenCreditCard} toggle={toggleCreditCard} />
      <Modal isOpen={isOpenPlans} size="lg">
        <ModalHeader toggle={togglePlans}>
          <h3>Escolha um Plano</h3>
        </ModalHeader>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="p-0 p-md-4">
            <Plans
              loading={loading}
              onSubmitPlan={onSubmitPlan}
              subTitle="Ao alterar um plano, é feito um calculo para saber a
        proporção não usufruída, e cobrar um valor justo. O dia da próxima cobrança será depois de um mês da alteração do plano."
            />
          </CardBody>
        </Card>
      </Modal>
      <Card className="shadow">
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <>
            <CardHeader className="d-flex justify-content-between">
              <h3 className="mb-0">Meu plano</h3>
              <div>
                <Badge color={getStatusColor(subscription.status)}>
                  <span className="text-lg text-capitalize">
                    {parseStatusText(subscription.status)}
                  </span>
                </Badge>
              </div>
            </CardHeader>
            <CardBody className="p-0 p-md-4">
              <div className="bg-gradient-light-dark-100 p-3 px-md-5 pb-md-5 pt-md-3">
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <span className="text-uppercase text-muted text-sm ls-1">Plano Atual</span>
                  <div>
                    <Button className="btn-link border-0 text-primary" onClick={togglePlans}>
                      <span> Mudar de Plano</span>
                      <i className="fa fa-pen" />
                    </Button>
                  </div>
                </div>
                <p className="mb-0 font-weight-bold text-uppercase text-lg">{plan.name}</p>
                <span className="mb-0 font-weight-bold text-uppercase text-lg">
                  R$ {numberBrFormatter(plan.amount / 100, 2)}
                </span>
                <span className="text-lg">/mês</span>
                {subscription.status !== 'canceled' && (
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <div>
                      <span className="text-muted text-sm">
                        Próximo pagamento será cobrado dia:{' '}
                      </span>
                      <span className="font-weight-bold text-blue-marine">
                        {moment(subscription?.nextBilling).format('DD/MM/YYYY')}
                      </span>
                    </div>
                    <Button className="btn-link text-muted" onClick={handleCancelSubscription}>
                      <span>Cancelar</span>
                    </Button>
                  </div>
                )}
              </div>
              <div className="bg-gradient-light-dark-200 px-5 py-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={getCardBrandImage(card.brand)} alt="card" width="35px" />
                    <p className="text-uppercase font-weight-bold text-blue-marine ls-1 mb-0 ml-3">
                      ***************{card.lastDigits}
                    </p>
                  </div>
                  <Button
                    className="btn-link border-0 text-blue text-right"
                    onClick={toggleCreditCard}
                  >
                    <span> Alterar</span>
                  </Button>
                </div>
              </div>
            </CardBody>
          </>
        )}
      </Card>
    </>
  );
};

export default ProfileSubscribe;
