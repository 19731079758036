import React, { useState, useEffect, useCallback, memo } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import CustomSpendsTableHeader from './TableHeader/TableHeader';
import CustomSpendsTableBody from './TableBody/TableBody';
import CustomSpendsTableFooter from './TableFooter/TableFooter';
import CheckoutFee from '../../../components/CheckoutFee/CheckoutFee';
import Spinner from '../../../components/Spinner/Spinner';
import Tax from '../../../components/Tax/Tax';
import { api, shopsApi } from '../../../services/api';
import emptyImage from '../../../assets/img/theme/empty.svg';

const CustomSpends = () => {
  const [customSpends, setCustomSpends] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const { store, platform } = useParams();

  const loadCustomSpendsData = useCallback(
    async load => {
      const limit = 15;

      try {
        setLoading(Boolean(load));
        const { data } = await api(shopsApi[platform]).get(
          `api/v1/users/${platform}/stores/${store}/custom-spends?page=${page}&limit=${limit}`,
        );

        setCustomSpends(data.customSpends);
        setPageCount(data.numberOfPages);
      } finally {
        setLoading(false);
      }
    },
    [page, platform, store],
  );

  const onChangePage = page => {
    setPage(page.selected + 1);
  };

  useEffect(() => {
    loadCustomSpendsData(true);
  }, [loadCustomSpendsData]);

  return loading ? (
    <Spinner loading={loading} />
  ) : (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row className="mb-5 align-items-start">
        <CheckoutFee />
        <Tax />
      </Row>
      <Row>
        <Col>
          <Card className="shadow">
            <CustomSpendsTableHeader
              customSpends={customSpends}
              setCustomSpends={setCustomSpends}
            />
            <CustomSpendsTableBody customSpends={customSpends} setCustomSpends={setCustomSpends} />
            <CustomSpendsTableFooter pages={pagesCount} onChangePage={onChangePage} />
          </Card>
        </Col>
      </Row>

      {customSpends.length <= 0 && !loading && (
        <Row className="align-items-center flex-column mt-6">
          <img src={emptyImage} width="300px" alt="no data" />
          <h1 className="mt-4 text-muted text-center">Você não tem nenhum valor adicional.</h1>
        </Row>
      )}
    </Container>
  );
};

export default memo(CustomSpends);
