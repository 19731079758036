import React, { memo, useState } from 'react';
import {
  Row,
  Col,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const marketingProviders = [
  // {
  //   title: 'Google',
  //   icon: 'fab fa-google',
  // },
  {
    title: 'Facebook',
    icon: 'fab fa-facebook',
  },
];

const CampainsHeader = () => {
  const [valueSelected, setValueSelected] = useState('Facebook');

  const handleChangeItem = item => {
    setValueSelected(item);
  };

  return (
    <>
      <Row className="mt-5 ">
        <Col className="d-flex justify-content-between">
          <h1 className="font-weight-light">Minhas Campanhas</h1>
          <UncontrolledDropdown className="gray-dark" nav>
            <DropdownToggle nav>
              <span className="font-weight-bold">{valueSelected}</span>
              <i className="ni ni-bold-down text-light-dark ml-2" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              {marketingProviders.map(provider => (
                <DropdownItem
                  key={provider.title}
                  onClick={() => {
                    handleChangeItem(provider.title);
                  }}
                >
                  <span className="text-md text-gray-dark font-weight-bold">
                    <i className={`${provider.title} ml-2`} />
                    {provider.title}
                    {provider.title === valueSelected ? (
                      <i className="ni ni-check-bold text-primary ml-2" />
                    ) : (
                      ''
                    )}
                  </span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <hr className="mb-4 mt-2" />
    </>
  );
};
export default memo(CampainsHeader);
