import React, { useContext } from 'react';
import { Row, Col, Card, CardHeader, Button } from 'reactstrap';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ProfilesTableBody from './TableBody/TableBody';
import ProfilesTableFooter from './TableFooter/TableFooter';
import FacebookFormContext from '../../../providers/FacebookFormContext';

const FacebookFormProfiles = () => {
  const { handleFacebookLogin } = useContext(FacebookFormContext);

  return (
    <Row>
      <Col md="5">
        <h1>Conecte-se em seus perfis do Facebook</h1>
        <p className="text-gray">
          Atráves desse tutorial te ensinaremos como adicionar mais de um perfil do facebook. Uma
          grande utilidade para quem trabalha fortemente com contigência. Importante ressaltar que
          esse processo não influência em nada que possa prejudicar sua BM.
        </p>
        <video
          src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/facebook.webm"
          controls
          playsInline
        >
          <track default kind="captions" srcLang="pt-br" />
        </video>
      </Col>
      <Col md="7">
        <Card className="shadow">
          <CardHeader>
            <Row>
              <Col>
                <h3 className="mb-0">Meus Perfis</h3>
              </Col>
              <Col className="text-right">
                <FacebookLogin
                  appId="2444384415680634"
                  scope="ads_read,public_profile,business_management,ads_management"
                  version="5.0"
                  disableMobileRedirect
                  callback={handleFacebookLogin}
                  render={props => (
                    <Button color="facebook" size="md" onClick={props.onClick}>
                      <span className="btn-inner--icon pr-2 ">
                        <i className="fab fa-facebook-f" />
                      </span>
                      <span className="btn-inner--text">Adicionar Perfil Facebook</span>
                    </Button>
                  )}
                />
              </Col>
            </Row>
          </CardHeader>
          <ProfilesTableBody />
          <ProfilesTableFooter />
        </Card>
      </Col>
    </Row>
  );
};

export default FacebookFormProfiles;
