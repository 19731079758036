import React, { useState, useContext, memo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  Table,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Kits from './Kits/Kits';
import { numberBrFormatter } from '../../../../../utils/utils';
import { api, shopsApi } from '../../../../../services/api';
import FirstTimeContext from '../../../../../providers/FirstTimeContext';
import { useToast } from '../../../../../providers/ToastContext';

const ProductCostVariants = ({
  products,
  currency,
  productSlug,
  loadProductsData,
  setModalDatePicker,
  open,
  toggle,
}) => {
  const [blurred, setBlurred] = useState(false);
  const [kitsIsOpen, setKitIsOpen] = useState(false);
  const [variant, setVariant] = useState();
  const { store, platform } = useParams();
  const firstTime = useContext(FirstTimeContext);
  const { showToast } = useToast();

  const handleOpenKitsModal = variant => {
    setVariant(variant);
    toggle();
    setKitIsOpen(!kitsIsOpen);
  };

  const onBlurValue = async (event, { variantId, productSlug, cost }) => {
    const { value } = event.target;

    const defaultCogs = Number(value.replace(/,/g, '.')) || cost;
    if (defaultCogs !== cost) {
      await api(shopsApi[platform]).patch(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/variants/${variantId}`,
        {
          cost: defaultCogs,
        },
      );
      showToast({ message: 'Valor alterado com sucesso!', type: 'success' });
      setBlurred({ [variantId]: true });
    }
  };

  const listVariantsById = slug => {
    const product = products.find(el => el.slug === slug);
    return product ? product.Variants : [];
  };

  const handleModalClose = async () => {
    if (blurred && firstTime && !firstTime?.isNewUser) {
      const result = await Swal.fire({
        icon: 'info',
        title: 'Alterar valores para pedidos do passado?',
        text: 'Isso não altera pedidos sincronizados com a AliExpress',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Alterar',
        cancelButtonText: 'Não Alterar',
      });

      if (result.value) {
        return setModalDatePicker(true);
      }
    }

    return loadProductsData();
  };

  return (
    <>
      {variant?.id && (
        <Kits
          isOpen={kitsIsOpen}
          toggle={handleOpenKitsModal}
          variant={variant}
          currency={currency[productSlug]}
          productSlug={productSlug}
        />
      )}
      <Modal isOpen={open} onClosed={handleModalClose} fade size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle} slug={productSlug}>
          Edite o valor de suas variantes
        </ModalHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Imagem</th>
              <th scope="col">Nome</th>
              <th scope="col">Preço de Venda</th>
              <th scope="col">Custo do Produto</th>

              <th scope="col">Kits</th>
            </tr>
          </thead>
          <tbody>
            {listVariantsById(productSlug).map(variant => (
              <tr key={variant.id}>
                <td width="8%" height="50px">
                  <span className="avatar avatar-lg rounded-circle">
                    <img alt="user-icon" src={variant.image} />
                  </span>
                </td>
                <td className="text-truncate" style={{ maxWidth: '320px' }}>
                  <span className="mb-0">{variant.title}</span>
                </td>
                <td width="12%">
                  <p className="mb-0">{`R$ ${numberBrFormatter(variant.sellPrice)}`}</p>
                </td>
                <td width="14%">
                  <div className="d-flex align-items-center">
                    <FormGroup className={blurred[variant.id] && 'has-success'}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className={`${blurred[variant.id] && 'text-green'}`}>
                            {currency[productSlug] === 'BRL' ? 'R$' : 'U$'}
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={variant.isModified && 'product-cost-changed'}
                          name="cogs"
                          placeholder={numberBrFormatter(variant.cost)}
                          id={variant.id}
                          onBlur={e =>
                            onBlurValue(e, {
                              variantId: variant.id,
                              productSlug,
                              cost: variant.cost,
                            })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                </td>
                <td width="8%">
                  <Button
                    className="btn btn-inner--text"
                    onClick={() => handleOpenKitsModal(variant)}
                  >
                    <i className="fas fa-pen" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>
    </>
  );
};

export default memo(ProductCostVariants);
