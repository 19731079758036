import React, { memo, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import SidebarAdmin from '../../components/SidebarAdmin/SidebarAdmin';
import menuRoutes from '../../routes/menuRoutes';
import logo from '../../assets/img/brand/logo-white.svg';

const Dashboard = props => {
  const { location } = props;

  const brandText = useMemo(() => {
    const result = menuRoutes.find(r => {
      const locationPath = location.pathname.split('/');
      const path = r.path.split('/').join('');
      return locationPath.includes(path);
    });

    return result?.name;
  }, [location.pathname]);

  const renderRoutes = useMemo(() => {
    const adminRoutes = menuRoutes.filter(({ layout }) => layout.includes('/admin'));

    const parsedRoutes = adminRoutes.map(prop => (
      <PrivateRoute
        {...props}
        path={`${prop.layout}${prop.path}`}
        component={prop.component}
        key={prop.name}
      />
    ));

    return parsedRoutes;
  }, [props]);

  return (
    <>
      <SidebarAdmin
        {...props}
        routes={menuRoutes.filter(({ layout }) => layout.includes('/admin'))}
        logo={{
          innerLink: '/admin/dashboard',
          imgSrc: logo,
          imgAlt: 'logo-profitfy',
        }}
        stores={[]}
      />
      <div className="main-content">
        <Navbar {...props} brandText={brandText} stores={[]} />
        <div className="vh-90">
          <Switch>{renderRoutes}</Switch>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default memo(Dashboard);
