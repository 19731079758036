import React, { useContext } from 'react';
import SubscribeContext from '../../../providers/SubscribeContext';
import { numberBrFormatter } from '../../../utils/utils';
import UserContext from '../../../providers/UserContext';

const PlanDetail = () => {
  const { value: planData } = useContext(SubscribeContext);
  const { value: user } = useContext(UserContext);

  return (
    <div className="pt-md-9 pt-4">
      <h2 className="text-gray font-weight-normal">Resumo do Pedido</h2>
      <hr className="mt-2" />
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-gray">Nome do Plano</p>
        <p className="text-primary font-weight-bold">{planData.title}</p>
      </div>
      {planData?.description && (
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-gray">Descrição</p>
          <p className="text-primary font-weight-bold">{planData.description}</p>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-gray">Preço</p>
        <p className="text-primary font-weight-bold">
          R${numberBrFormatter(planData.price / 100, 2)}
        </p>
      </div>
      {!user.subscription.gatewayId && (
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-gray">Bônus</p>
          <p className="text-primary font-weight-bold">7 Dias Grátis</p>
        </div>
      )}
      <hr className="mt-md-6" />
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-gray font-weight-bold">Total</p>
        <p className="total-amount text-primary font-weight-bold">
          R${numberBrFormatter(planData.price / 100, 2)}
        </p>
      </div>
    </div>
  );
};

export default PlanDetail;
