import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const CieloWarn = () => (
  <Alert color="info" className="d-flex align-items-center">
    <span>
      <i className="fa fa-exclamation-triangle" />
      Suas credenciais da <strong>Cielo </strong>
      estão incorretas. Para arrumar{' '}
      <Link className="alert-link" to="integrations/cielo">
        Clique aqui
      </Link>
    </span>
  </Alert>
);

export default CieloWarn;
