import React, { useState, useRef } from 'react';
import { Button } from 'reactstrap';
import './MobileApp.scss';
import iphoneMock from '../../assets/img/theme/iphone-mock.png';
import androidMock from '../../assets/img/theme/android-mock.png';

const MobileApp = () => {
  const [play, setPlay] = useState(false);
  const [iphone, setIphone] = useState(false);
  const videoRef = useRef();

  const onPlay = () => {
    videoRef.current.play();
    setPlay(true);
  };

  const onPause = () => {
    videoRef.current.pause();
    setPlay(false);
  };

  const switchTutorial = () => {
    setIphone(!iphone);
    videoRef.current.currentTime = 0;
    videoRef.current.load();
  };

  return (
    <div className="mobile-app mb-5">
      <h1 className="title font-weight-normal text-center px-md-9 my-4">
        Agora vamos te ensinar instalar nosso App em seu celular!
      </h1>
      <hr />
      <div className="text-center mb-4">
        <Button color="dark" onClick={switchTutorial}>
          {iphone ? (
            <>
              Uso Android <i className="fab fa-android" />
            </>
          ) : (
            <>
              Uso Iphone <i className="fab fa-apple" aria-hidden="true" />
            </>
          )}
        </Button>
      </div>
      {iphone ? (
        <div
          className="iphone d-flex flex-column align-items-center"
          role="presentation"
          onClick={play ? onPause : onPlay}
        >
          <img src={iphoneMock} alt="iphone-mock" />
          <video
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/iphone-video.webm"
            controls={false}
            ref={videoRef}
            autoPlay
          >
            <track kind="captions" />
          </video>
        </div>
      ) : (
        <div
          className="android video-mock d-flex flex-column align-items-center"
          role="presentation"
          onClick={play ? onPause : onPlay}
        >
          <img src={androidMock} alt="android-mock" />
          <video
            src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/android-video.webm"
            controls={false}
            ref={videoRef}
            autoPlay
          >
            <track kind="captions" />
          </video>
        </div>
      )}
    </div>
  );
};

export default MobileApp;
