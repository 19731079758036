import React, { useState, memo, useCallback } from 'react';
import { Card, CardText, Col, Row, Modal, ModalHeader, ModalBody, CardImg } from 'reactstrap';
import Shopify from './Shopify/Shopify';
import Cartx from './Cartx/Cartx';
import newIcon from '../../../assets/img/theme/plus-icon.svg';
import './NewCard.css';

const shopifyLogo = 'https://upload.wikimedia.org/wikipedia/commons/e/e1/Shopify_Logo.png';
const cartxLogo = 'https://cartx.io/wp-content/uploads/2019/09/new-logo-copy-2.svg';

const StoreNewCard = ({ loadStore }) => {
  const [modal, setModal] = useState(false);
  const [component, setComponent] = useState(() => {});

  const onOpenModal = () => setModal(!modal);

  const handleClick = useCallback(
    event => {
      const { id } = event.target;

      const component = {
        cartx: <Cartx setModal={setModal} loadStore={loadStore} />,
        shopify: <Shopify />,
      }[id];

      setComponent(component);
    },
    [loadStore],
  );

  return (
    <>
      <Modal isOpen={modal} onClosed={() => setComponent(null)} toggle={onOpenModal} centered>
        {component || (
          <>
            <ModalHeader toggle={onOpenModal} className="teal h1">
              Escolha a sua plataforma
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="6">
                  <Card
                    className="card-shadow bg-secondary border bg-white my-2 rounded"
                    onClick={handleClick}
                    id="shopify"
                  >
                    <CardImg
                      top
                      className="justify-content-center card-profile-image new-card-icon my-6"
                      alt="Store Image"
                      src={shopifyLogo}
                      onClick={handleClick}
                      id="shopify"
                      height="60px"
                    />
                  </Card>
                </Col>
                <Col sm="6">
                  <Card
                    className="card-shadow bg-secondary border bg-white my-2 rounded"
                    onClick={handleClick}
                    id="cartx"
                  >
                    <CardImg
                      top
                      className="justify-content-center card-profile-image new-card-icon my-6 px-3"
                      alt="Store Image"
                      src={cartxLogo}
                      onClick={handleClick}
                      id="cartx"
                      height="60px"
                    />
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </>
        )}
      </Modal>
      <Col lg="3" md="3" sm="6" className="store-card">
        <Card
          className="card-shadow bg-secondary border bg-white my-2 rounded"
          onClick={onOpenModal}
        >
          <CardImg
            top
            className="justify-content-center card-profile-image new-card-icon px-5"
            alt="Store Image"
            src={newIcon}
          />
          <div className="dropdown-divider" />
          <CardText className="my-2 text-blue-marine h2">Adicionar Loja</CardText>
        </Card>
      </Col>
    </>
  );
};

export default memo(StoreNewCard);
