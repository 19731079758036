import React, { useState, useCallback, useEffect, memo } from 'react';
import { Row, Col } from 'reactstrap';
import Header from './Header/Header';
import FunnelChart from './FunnelChart/FunnelChart';
import Tutorial from './Tutorial/Tutorial';
import CampaignTable from './CampaignTable/CampaignTable';

const CampaignsFacebookInsights = ({ facebookInsights }) => {
  const [data, setChartData] = useState({
    labels: ['View Content', 'Initiate Checkout', 'Add Payment Info', 'Purchase'],
    subLabels: ['Tráfego'],
    colors: [['#76C66B', '#7AD26E', '#97D48F']],
    values: [[]],
  });

  const loadChartData = useCallback(() => {
    setChartData({
      labels: ['View Content', 'Initiate Checkout', 'Add Payment Info', 'Purchase'],
      subLabels: ['Tráfego'],
      colors: ['#53C443', '#7AD26E', '#94FF84'],
      values: [
        facebookInsights?.viewContent || 0,
        facebookInsights?.initiatedCheckout || 0,
        facebookInsights?.addPaymentInfo || 0,
        facebookInsights?.purchase || 0,
      ],
    });
  }, [facebookInsights]);

  useEffect(() => {
    loadChartData();
  }, [loadChartData]);

  return (
    <>
      <Header facebookInsights={facebookInsights} />
      <FunnelChart data={data} />
      <Row className="mt-6">
        <Col lg={4}>
          <Tutorial />
        </Col>
        <Col lg={8} className="mt-4 mt-lg-0">
          <CampaignTable loadChartData={loadChartData} />
        </Col>
      </Row>
    </>
  );
};

export default memo(CampaignsFacebookInsights);
