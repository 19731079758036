import React, { memo } from 'react';
import Header from './Header/Header';
import FacebookInsights from './FacebookInsights/FacebookInsights';

const ProductDetailsCampaigns = ({ productDetails }) => {
  return (
    <div>
      <Header />
      <FacebookInsights facebookInsights={productDetails.facebookInsights} />
    </div>
  );
};

export default memo(ProductDetailsCampaigns);
