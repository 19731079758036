import React, { useEffect, useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import DatePicker from '../../../components/DatePicker/DatePicker';
import CardGroup from './CardGroup/CardGroup';

import LineChart from './LineChart/LineChart';
import Campaigns from './Campaigns/Campaigns';
import usePersistedState from '../../../utils/usePersistedState';
import Spinner from '../../../components/Spinner/Spinner';
import { useProductDetails } from '../../../providers/ProductDetails';

const ProductDetails = () => {
  const { platform, store, productSlug } = useParams();
  const { loadProductDetails, loading, productDetails } = useProductDetails();
  const [period, setPeriod] = usePersistedState('topProductsDatePicker', {
    startDate: moment(),
    endDate: moment(),
  });

  const handleDate = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return;
      if (!startDate.isValid() || !endDate.isValid()) return;
      setPeriod({ startDate, endDate });
    },
    [setPeriod],
  );

  useEffect(() => {
    loadProductDetails({ platform, store, productSlug, period });
  }, [loadProductDetails, platform, store, productSlug, period]);

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <>
        <Row>
          <Col className="mb-4">
            <DatePicker
              startDate={moment(period.startDate)}
              endDate={moment(period.endDate)}
              onDate={handleDate}
              showPreDefinedDates
            />
          </Col>
        </Row>
        {loading ? <Spinner loading={loading} /> : <CardGroup productDetails={productDetails} />}
        <LineChart />
        <Campaigns productDetails={productDetails} />
      </>
    </Container>
  );
};

export default memo(ProductDetails);
