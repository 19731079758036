import React, { useState, useMemo, useContext, useCallback } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody, Button } from 'reactstrap';
import Steps, { Step } from 'rc-steps';
import Plans from '../../components/Plans/Plans';
import AddCreditCard from './CreditCard/CreditCard';
import SubscribeContext from '../../providers/SubscribeContext';
import ThankYou from './ThankYou/ThankYou';
import UserContext from '../../providers/UserContext';
import BubblesBackground from '../../components/BubblesBackground/BubblesBackground';
import logo from '../../assets/img/brand/logo-white.svg';
import './Subscribe.scss';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import PersonalInfo from './PersonalInfo/PersonalInfo';

const Subscribe = () => {
  const [current, setCurrent] = useState(0);
  const [plan, setPlan] = useState(null);
  const { value: user } = useContext(UserContext);

  const value = useMemo(() => plan, [plan]);

  const onSubmitPlan = useCallback(({ planId, price, title, description }) => {
    setPlan({ planId, price, title, description });
    setCurrent(1);
  }, []);

  const getCurrentComponent = useCallback(
    current =>
      ({
        0: (
          <div>
            <h1 className="title font-weight-normal text-center pt-2 px-md-3">
              Os planos baseiam-se em seu faturamento! <br />
              Assim seu bolso fica feliz da vida, né?
            </h1>
            <h3 className="text-primary mt-3 display-4 text-center">
              {!user?.subscription?.gatewayId ? 'Assine hoje e tenha 7 dias grátis!' : ''}
            </h3>
            <hr className="mt-0 mb-6 mx-lg-5" />
            {user && <Plans onSubmitPlan={onSubmitPlan} />}
          </div>
        ),
        1: <PersonalInfo />,
        2: (
          <div>
            <h1 className="title font-weight-normal text-center px-md-8">
              Show! Última etapa, e logo cuidaremos de todos os cálculos para você.
            </h1>
            <hr className="mb-5" />
            <AddCreditCard />
          </div>
        ),
        3: <ThankYou />,
      }[current]),
    [onSubmitPlan, user],
  );

  return (
    <>
      <BubblesBackground>
        <div className="main-content subscribe">
          <div className="header py-5 py-lg-4 vh-90">
            <Container className="subscribe-width">
              <Row className="justify-content-center">
                <Col>
                  <div className="img-fluid text-center">
                    <img alt="profitfy" className="img-fluid" width="350px" src={logo} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-6">
                <Col>
                  <Card className="card-shadow">
                    <CardHeader className="px-md-9 card-step-header">
                      <Steps
                        size="lg"
                        current={current}
                        labelPlacement="vertical"
                        onChange={val => setCurrent(val)}
                      >
                        <Step
                          title="Plano"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-file-signature" />
                            </Button>
                          }
                        />
                        <Step
                          title="Dados Pessoais"
                          disabled={current === 0}
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-user" />
                            </Button>
                          }
                        />
                        <Step
                          title="Pagamento"
                          disabled={current === 0 || current === 1}
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-credit-card" />
                            </Button>
                          }
                        />
                        {current === 2 && (
                          <Step
                            title="Sucesso"
                            icon={
                              <Button type="button" className="btn btn-circle btn-xl">
                                <i className="fas fa-rocket" />
                              </Button>
                            }
                          />
                        )}
                      </Steps>
                    </CardHeader>
                    <SubscribeContext.Provider value={{ value, setCurrent }}>
                      <CardBody className="pb-5">{getCurrentComponent(current)}</CardBody>
                    </SubscribeContext.Provider>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </BubblesBackground>
    </>
  );
};
export default Subscribe;
