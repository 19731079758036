import React from 'react';
import { Card, CardHeader, Row, CardBody } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';

const PieChart = ({ data }) => {
  const parsedData = {
    datasets: [
      {
        data: [data.cardChart.toFixed(0), data.billetChart.toFixed(0)],
        backgroundColor: ['#3294d1', 'rgb(255, 127, 14)'],
        borderWidth: 1,
      },
    ],
    labels: ['Cartão', 'Boleto'],
  };

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (el, data) =>
          `${data.labels[el.index]} ${data.datasets[el.datasetIndex].data[el.index]}`,
      },
    },
    plugins: {
      labels: {
        fontColor: 'white',
        render: 'percentage',
        fontStyle: 'bold',
        fontSize: 16,
      },
    },
  };

  return (
    <Card className="shadow mt-3">
      <CardHeader className="bg-transparent">
        <Row className="d-flex justify-content-between p-2">
          <h3 className="mb-0">Boleto x Cartão</h3>
          <p className="text-muted text-sm mb-0">
            Veja como está sua % de conversão de cartões comparado com boletos.
          </p>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="wrapper-chart">
          <Doughnut data={parsedData} options={options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default PieChart;
