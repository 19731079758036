import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import UserContext from '../../../providers/UserContext';
import thankYouSVG from '../../../assets/img/theme/thank-you.svg';

const SubscribeThankYou = () => {
  const { loadUser } = useContext(UserContext);
  const history = useHistory();

  const handleOnClick = async () => {
    await loadUser();
    history.push('/stores');
  };

  return (
    <Container>
      <Row>
        <Col className="d-flex flex-column align-items-center">
          <img src={thankYouSVG} className="img-fluid" alt="thank-you" width="600px" />
          <h1 className="text-green mt-6 display-3">Muito Obrigado!</h1>
          <p className="text-muted">Yess! Agora você faz parte de nossa família. Seja bem vindo!</p>
          <Button
            className="btn-icon btn-2 btn-lg px-md-8 mt-6 shadow"
            color="primary"
            onClick={handleOnClick}
          >
            <span className="btn-inner--text text-white text-lg">Ir para Dashboard</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscribeThankYou;
