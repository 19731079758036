import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Container, Card } from 'reactstrap';
import LabEcomPlan from './LabEcomPlan/LabEcomPlan';
import { api } from '../../services/api';
import { newUsersEmails, oldUsersEmails } from './LabEcomPlan/labEcom';
import UserContext from '../../providers/UserContext';
import './Plans.scss';
import 'react-input-range/lib/css/index.css';
import DefaultPlans from './DefaultPlans/DefaultPlans';

const Plans = ({ onSubmitPlan, subTitle, loading }) => {
  const [plans, setPlans] = useState(null);
  const { value: user } = useContext(UserContext);

  const loadPlans = useCallback(async () => {
    const { data } = await api('user-api').get(
      `api/v1/users/plans?category=${user.subscription.gatewayId ? 'default' : 'initial'}`,
    );

    setPlans(data.plans);
  }, [user.subscription.gatewayId]);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return (
    plans && (
      <Container className="plans px-md-6">
        <Row className="justify-content-center align-items-center">
          <Card className="shadow px-md-5 px-3 pt-4">
            {(newUsersEmails.includes(user.email) || oldUsersEmails.includes(user.email)) &&
            !user.subscription.gatewayId ? (
              <LabEcomPlan onSubmitPlan={onSubmitPlan} plans={plans} user={user} />
            ) : (
              <DefaultPlans
                onSubmitPlan={onSubmitPlan}
                plans={plans}
                subTitle={subTitle}
                loading={loading}
              />
            )}
          </Card>
        </Row>
      </Container>
    )
  );
};

export default Plans;
