/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useState, useContext } from 'react';
import { Button, Form, FormGroup, Input as StrapInput, CardBody, Card, Col, Row } from 'reactstrap';
import useForm from 'react-hook-form';
import cepPromise from 'cep-promise';
import PlanDetail from '../PlanDetail/PlanDetail';
import './styles.scss';
import { masks } from '../../../utils/utils';
import { api } from '../../../services/api';
import UserContext from '../../../providers/UserContext';
import SubscribeContext from '../../../providers/SubscribeContext';

const PersonalInfo = () => {
  const [userType, setUserType] = useState('physical');
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState();
  const [cpf, setCpf] = useState();
  const [cnpj, setCnpj] = useState();

  const [address, setAddress] = useState({
    state: '',
    city: '',
    street: '',
    neighborhood: '',
    complementary: null,
  });
  const { register, handleSubmit } = useForm();
  const { value: user } = useContext(UserContext);
  const { setCurrent } = useContext(SubscribeContext);

  const onSubmit = useCallback(
    async data => {
      setLoading(true);

      try {
        await api('user-api').put('/api/v1/users/me', {
          ...user,
          cpf: data.cpf,
          cnpj: data.cnpj,
          companyName: data.companyName,
        });
        await api('user-api').post('/api/v1/users/addresses', {
          ...data,
          complementary: data.complementary || null,
          zipcode: data.cep,
        });

        setCurrent(2);
      } finally {
        setLoading(false);
      }
    },
    [user, setCurrent],
  );

  const handleGetAddressByCEP = useCallback(async () => {
    const cepFormatted = cep.replace('-', '');
    const result = await cepPromise(cepFormatted);
    setAddress({ ...address, ...result });
  }, [cep, address]);

  const handleUserType = useCallback(value => {
    setUserType(value);
  }, []);

  return (
    <Card className="shadow credit-card">
      <CardBody>
        <Row className="justify-content-center">
          <Col md="6" className="order-2 order-md-1">
            <Form onSubmit={handleSubmit(onSubmit)} role="form">
              <div className="d-flex justify-content-center" />
              <div className="mt-5 px-3">
                <div className="mb-4">
                  <Button
                    type="button"
                    color={`${userType === 'physical' ? 'primary' : ''}`}
                    onClick={() => handleUserType('physical')}
                  >
                    Pessoa Física
                  </Button>
                  <Button
                    type="button"
                    color={`${userType === 'business' ? 'primary' : ''}`}
                    onClick={() => handleUserType('business')}
                  >
                    Pessoa Jurídica
                  </Button>
                </div>
                {userType === 'physical' ? (
                  <FormGroup className="cpf">
                    <label className="form-control-label" htmlFor="cpf">
                      CPF
                    </label>
                    <StrapInput
                      value={cpf}
                      type="text"
                      id="cpf"
                      name="cpf"
                      placeholder="000.000.000-00"
                      onChange={e => setCpf(masks.cpf(e.target.value))}
                      innerRef={register}
                      className="input-group-alternative"
                    />
                  </FormGroup>
                ) : (
                  <Row>
                    <Col sm="12" md="5">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="cnpj">
                          CNPJ
                        </label>
                        <StrapInput
                          value={cnpj}
                          type="text"
                          id="cnpj"
                          name="cnpj"
                          placeholder="00.000.000/0001-00"
                          onChange={e => setCnpj(masks.cnpj(e.target.value))}
                          innerRef={register}
                          className="input-group-alternative"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="7">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="companyName">
                          Razão Social
                        </label>
                        <StrapInput
                          type="text"
                          id="companyName"
                          name="companyName"
                          innerRef={register}
                          className="input-group-alternative"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <FormGroup className="cep">
                      <label className="form-control-label " htmlFor="cep">
                        CEP
                      </label>
                      <StrapInput
                        value={cep}
                        type="text"
                        id="cep"
                        name="cep"
                        onChange={e => setCep(masks.cep(e.target.value))}
                        onBlur={handleGetAddressByCEP}
                        innerRef={register}
                        className="input-group-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="city">
                        Cidade
                      </label>
                      <StrapInput
                        value={address.city}
                        type="text"
                        id="city"
                        name="city"
                        innerRef={register}
                        className="input-group-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="state">
                        Estado
                      </label>
                      <StrapInput
                        className="input-group-alternative"
                        value={address.state}
                        type="text"
                        id="state"
                        name="uf"
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="8">
                    <FormGroup className="flex-1">
                      <label className="form-control-label" htmlFor="address">
                        Endereço
                      </label>
                      <StrapInput
                        id="address"
                        name="street"
                        type="text"
                        value={address.street}
                        innerRef={register}
                        className="input-group-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="address-number">
                        Número
                      </label>
                      <StrapInput
                        id="address-number"
                        className="address-number input-group-alternative"
                        name="number"
                        type="text"
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="neighborhood">
                        Bairro
                      </label>
                      <StrapInput
                        type="text"
                        name="neighborhood"
                        id="neighborhood"
                        className="input-group-alternative"
                        value={address.neighborhood}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="complement">
                        Complemento
                      </label>
                      <StrapInput
                        type="text"
                        name="complementary"
                        id="complement"
                        placeholder="Ex: casa"
                        innerRef={register}
                        className="input-group-alternative"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <hr className="mt-5" />
              <div className="text-right mr-3">
                <Button className="btn-icon btn-2" type="button">
                  <span className="btn-inner--text">Voltar</span>
                </Button>
                <Button
                  type="submit"
                  className="btn-icon btn-2 btn-lg"
                  disabled={loading}
                  color="primary"
                >
                  {loading ? 'Processando...' : 'Próximo'}
                </Button>
              </div>
            </Form>
          </Col>
          <Col
            className="bg-lighter mt--4 my-md--4 mr-md--3 px-md-6 mb-6 mb-md-0 order-1 order-md-2"
            md="6"
          >
            <PlanDetail />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PersonalInfo;
