import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomSwitch from '../../CustomSwitch/CustomSwitch';
import UserContext from '../../../providers/UserContext';
import { api } from '../../../services/api';

const ProfileNotifications = () => {
  const { value: user } = useContext(UserContext);

  const updateNotifications = data =>
    api('user-api').put(`/api/v1/users/notifications/${user.notification.id}`, data);

  const changeNotification = event => {
    const { checked, id } = event.target;
    const parsedNotification = { [id]: checked };

    updateNotifications({ ...user.notification, ...parsedNotification });
  };

  return (
    <Card className="shadow mt-4">
      <CardHeader>
        <h3>Notificações</h3>
        <p className="text-muted text-sm mb-0">Ative quais tipos de notificações quer receber</p>
      </CardHeader>
      <CardBody className="p-3 p-md-4 mt-3">
        <Row>
          <Col className=" px-md-4 d-flex align-items-center">
            <span className="mb-1 mr-2 font-weight-bold">Erros</span>
            <CustomSwitch
              id="errors"
              onClick={changeNotification}
              status={user?.notification.errors}
            />
          </Col>
          <Col className="px-md-4 d-flex align-items-center">
            <span className="mb-1 mr-2 font-weight-bold">Email</span>
            <CustomSwitch
              id="email"
              onClick={changeNotification}
              status={user?.notification.email}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="px-md-4 d-flex align-items-center">
            <span className="mb-1 mr-2 font-weight-bold">Whatsapp</span>
            <CustomSwitch
              id="whatsapp"
              onClick={changeNotification}
              status={user?.notification.whatsapp}
            />
          </Col>

          <Col className="px-md-4 d-flex align-items-center">
            <span className="mb-1 ml-2 font-weight-bold">Atualizações</span>
            <CustomSwitch
              id="updates"
              onClick={changeNotification}
              status={user?.notification.updates}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProfileNotifications;
