import React, { memo, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
} from 'reactstrap';
import { logout } from '../../../services/auth';

const StoresNavbar = () => {
  const userProfileRouteMatch = useRouteMatch({
    path: '/stores/user-profile',
    strict: true,
    sensitive: true,
    exact: true,
  });

  const storesRouteMatch = useRouteMatch({
    path: '/stores',
    strict: true,
    sensitive: true,
    exact: true,
  });

  const brandTexts = useMemo(
    () => [
      {
        match: storesRouteMatch,
        text: 'Minhas Lojas',
      },
      {
        match: userProfileRouteMatch,
        text: '< Voltar para minhas lojas',
      },
    ],
    [storesRouteMatch, userProfileRouteMatch],
  );

  const { text } = useMemo(() => brandTexts.find(brand => brand.match), [brandTexts]);

  return (
    <>
      <Navbar className="navbar-top bg-white shadow position-fixed" id="navbar-stores">
        <Container fluid>
          <Link className="text-lg mb-0 text-gray text-uppercase " to="/">
            {text}
          </Link>
          <Nav navbar>
            <UncontrolledDropdown nav className="mr-3">
              <DropdownToggle>
                <i className="ni ni-settings-gear-65" />
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu-arrow">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem-Vindo!</h6>
                </DropdownItem>
                <DropdownItem to="/stores/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => logout()}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default memo(StoresNavbar);
