import React, { useCallback, useState, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Button,
} from 'reactstrap';
import SuggestionModal from '../SuggestionModal/SuggestionModal';
import { logout } from '../../services/auth';

const DashboardNavbar = ({ brandText, stores }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { store: slug, platform } = useParams();
  const history = useHistory();

  const selectedStore = useMemo(() => stores.find(store => store.slug === slug), [stores, slug]);

  const toggleSuggestionModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleChangeStore = useCallback(
    (slug, platform) => {
      history.push(`/${platform}/${slug}/dashboard/shop-analytics`);
    },
    [history],
  );

  const renderDropdownStores = useMemo(() => {
    return stores.map(store => (
      <div key={store?.slug}>
        <DropdownItem onClick={() => handleChangeStore(store.slug, store.platform)}>
          <span className="text-md text-gray-dark font-weight-bold">
            {store?.name}{' '}
            {selectedStore?.slug === store?.slug && (
              <i className="ni ni-check-bold text-primary ml-2" />
            )}
          </span>
        </DropdownItem>
        <DropdownItem divider className="mx-3" />
      </div>
    ));
  }, [handleChangeStore, stores, selectedStore]);

  return (
    <>
      <SuggestionModal toggle={toggleSuggestionModal} open={isOpen} />

      <Navbar className="navbar-top shadow d-none d-md-inline-block" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="text-lg mb-0 text-default text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <Button
              className="btn-link text-default mr-0"
              onClick={toggleSuggestionModal}
              nav="true"
            >
              <i className="fa fa-lightbulb-o" />
            </Button>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <i className="ni ni-settings-gear-65" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem-Vindo!</h6>
                </DropdownItem>
                <DropdownItem to={`/${platform}/${slug}/dashboard/user-profile`} tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => logout()}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* Select Store */}
            <UncontrolledDropdown className="border-left gray-dark ml-3 mr--3 " nav>
              <DropdownToggle className="pr-2" nav>
                <span className="font-weight-bold">{selectedStore?.name}</span>
                <i className="ni ni-bold-down text-light-dark ml-2" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {renderDropdownStores}
                <DropdownItem href="/stores">
                  <span className="text-blue">Registar nova loja</span>
                  <i className="ni ni-shop text-blue ml-2" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default DashboardNavbar;
