import React, { useRef, memo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import FunnelGraph from 'funnel-graph-js';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { useProductDetails } from '../../../../../../providers/ProductDetails';

const makeMediaQueries = () => {
  const mediaQueries = [];
  const mobileSize = 760;

  let index = 320;

  while (index < 6000) {
    const mediaQuery = {
      active: window.matchMedia(`(max-width: ${index}px)`).matches,
      width: index <= mobileSize ? index - 50 : index - 340,
    };

    mediaQueries.push(mediaQuery);

    index += 50;
  }

  return mediaQueries;
};

const FacebookInsightsFunnelChart = ({ data }) => {
  const funnelContainer = useRef();
  const { loading } = useProductDetails();

  useEffect(() => {
    if (funnelContainer.current) {
      const funnel = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'horizontal',
        data,
        displayPercent: true,
        direction: 'horizontal',
        width: makeMediaQueries().find(media => media.active).width,
        height: 270,
        subLabelValue: 'raw',
      });

      funnel.draw();

      if (funnel.getDataSize() > 1) {
        funnel.updateData(data);
      }
    }
  }, [data]);

  return (
    <Row className="mt-4 py-5 bg-gradient-default">
      <Col>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <div key={new Date().toString()} className="funnel" ref={funnelContainer} />
        )}
      </Col>
    </Row>
  );
};

export default memo(FacebookInsightsFunnelChart);
