import React, { useState, memo, useMemo } from 'react';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { api } from '../../../../services/api';

const Shopify = () => {
  const [storeName, setStoreName] = useState('');

  const onAddNewStore = async () => {
    const response = await api('urgot-shopify-api').post(
      `/api/v1/users/shopify/stores/?shop=${storeName}`,
    );
    window.open(response.data.url, '_self');
  };

  useMemo(() => {
    Swal.fire({
      icon: 'info',
      title: 'Atenção!',
      text:
        'Se você utiliza o Checkout do Cartx em sua loja Shopify, adicione uma loja como Cartx, para que funcione corretamente.',
      showConfirmButton: true,
    });
  }, []);

  return (
    <>
      <ModalHeader className="teal h1">Digite o Nome da sua loja</ModalHeader>
      <ModalBody>
        <InputGroup className="input-group-alternative">
          <Input bsSize="lg" onChange={event => setStoreName(event.target.value)} />
          <InputGroupAddon addonType="append">
            <InputGroupText>.myshopify.com</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <Button color="primary" className="btn-icon btn-2 mt-4" onClick={onAddNewStore}>
          <span className="btn-inner--text">Cadastrar</span>
          <span className="btn-inner--icon">
            <i className="ni ni-send" />
          </span>
        </Button>
      </ModalBody>
    </>
  );
};

export default memo(Shopify);
