import React, { useContext, memo } from 'react';
import { Table } from 'reactstrap';
import { SyncLoader } from 'react-spinners';
import Spinner from '../../../Spinner/Spinner';
import FacebookFormContext from '../../../../providers/FacebookFormContext';
import CustomSwitch from '../../../CustomSwitch/CustomSwitch';

const AdAccountTableBody = () => {
  const { value, handleCheckBox } = useContext(FacebookFormContext);

  return (
    <>
      <div>
        {value.loading ? (
          <Spinner loading={value.loading} />
        ) : (
          <Table className="table-flush" responsive>
            <>
              <thead className="thead-light">
                <tr>
                  <th scope="col">
                    <span className="facebook-form-table-title">Id da Conta</span>
                  </th>
                  <th scope="col">
                    <span className="facebook-form-table-title">Nome </span>
                  </th>
                  <th scope="col">
                    <span className="facebook-form-table-title">Perfil </span>
                  </th>
                  <th scope="col" className="text-right">
                    <span className="facebook-form-table-title"> Ações </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {value.adAccounts.map(adAccount => (
                  <tr key={adAccount.id}>
                    <td>
                      <span className="facebook-form-table-title">{adAccount.id}</span>
                    </td>
                    <td>
                      <span className="facebook-form-table-title">{adAccount.name}</span>
                    </td>
                    <td>
                      <span className="facebook-form-table-title">
                        {adAccount.facebook_credential?.name}
                      </span>
                    </td>
                    <td className="text-right">
                      {value.loadingAdAccountStatus.status &&
                      value.loadingAdAccountStatus.id === adAccount.id ? (
                        <div className="py-4">
                          <SyncLoader
                            color="#BDB5B5"
                            loading={value.loadingAdAccountStatus.status}
                          />
                        </div>
                      ) : (
                        <CustomSwitch
                          id={adAccount.id}
                          onClick={handleCheckBox}
                          status={adAccount.active}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          </Table>
        )}
      </div>
    </>
  );
};

export default memo(AdAccountTableBody);
