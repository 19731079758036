import React, { useContext, memo } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
} from 'reactstrap';
import AdAccountTableBody from './TableBody/TableBody';
import AdAccountTableFooter from './TableFooter/TableFooter';
import FacebookFormContext from '../../../providers/FacebookFormContext';

const FacebookAdAccounts = () => {
  const { setAdAccountName } = useContext(FacebookFormContext);

  const onSearch = async event => {
    const { value } = event.target;

    setAdAccountName(value);
  };

  return (
    <Row className="mt-5">
      <Col className="order-xl-1" xl="12">
        <Card className="shadow">
          <>
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h2>Gerenciar contas de Anuncio</h2>
                  <div className="d-md-flex justify-content-between mb-5">
                    <p className="mb-0">
                      Selecione todas contas de ads da sua loja para que possamos fazer os calculos
                      corretos.
                    </p>
                  </div>
                </Col>
                <Col>
                  <Row>
                    {' '}
                    <Col>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nome da conta de anúncio"
                          type="text"
                          name="Search"
                          onChange={onSearch}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <AdAccountTableBody />
            <AdAccountTableFooter />
          </>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(FacebookAdAccounts);
