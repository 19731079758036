import React, { useState, memo } from 'react';
import { Card, Col, Tooltip, CardTitle } from 'reactstrap';
import AnimatedNumber from 'animated-number-react';

import './styles.scss';

const CardWidget = ({ data, xl, lg, md, prepend = '' }) => {
  const { id, title, value, iconColor, icon, formatter = n => n, animated, description } = data;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Col xl={xl} lg={lg} md={md} className="mt-2">
      <Card className="shadow">
        <div className="card-widget">
          <div className="title">
            <CardTitle tag="h5" className="text-uppercase text-muted pb-0 card-height">
              {title}
            </CardTitle>

            <div className={`icon icon-shape bg-${iconColor} text-white rounded-circle shadow`}>
              <i className={icon} />
            </div>
          </div>

          <div className="content">
            <div>
              <span className="text-gray">{prepend} </span>
              <span className="h1 font-weight-bold">
                {animated ? (
                  <AnimatedNumber value={value} formatValue={formatter} duration={1500} />
                ) : (
                  value
                )}
              </span>
            </div>

            <i className="fas fa-info-circle text-light text-right" id={`Tooltip-${id}`} />
            <Tooltip
              placement="left"
              isOpen={tooltipOpen}
              target={`Tooltip-${id}`}
              toggle={toggle}
              color="secondary"
            >
              <span className="text-gray-dark">{description}</span>
            </Tooltip>
          </div>
        </div>
        <div className={`bg-gradient-${iconColor} card-background mt--2`} />
      </Card>
    </Col>
  );
};

export default memo(CardWidget);
