/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { Modal, ModalHeader, Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap';
import PaymentCard from 'react-payment-card-component';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import pagarme from 'pagarme';
import Spinner from '../Spinner/Spinner';

import { api } from '../../services/api';

const AddCreditCard = ({ isOpen, toggle, title }) => {
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [cardData, setCardData] = useState({
    number: '4111111111111111',
    cvv: '202',
    holderName: 'Yung Buda',
    month: '12',
    year: '24',
  });

  const flipCard = () => setFlipped(!flipped);

  const onSubmit = async () => {
    const parsedCardDataToEncryption = {
      card_holder_name: cardData.holderName,
      card_expiration_date: `${cardData.month}${cardData.year}`,
      card_number: cardData.number,
      card_cvv: cardData.cvv,
    };

    const client = await pagarme.client.connect({
      encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
    });

    const cardHash = await client.security.encrypt(parsedCardDataToEncryption);

    const parsedCardData = {
      number: cardData.number,
      holderName: cardData.holderName,
      expirationDate: `${cardData.month}${cardData.year}`,
      cardHash,
    };

    try {
      setLoading(true);
      await api('user-api').post(`api/v1/users/cards`, parsedCardData);
      await Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        showConfirmButton: false,
        timer: 1500,
      });
      toggle();
    } finally {
      setLoading(false);
    }
  };

  const onChangeCard = event => {
    const { name, value } = event.target;
    const parseData = { [name]: value };

    setCardData({ ...cardData, ...parseData });
  };

  return (
    <Modal isOpen={isOpen} fade toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <p>{title}</p>
      </ModalHeader>
      <Card className="bg-secondary shadow border-0 ">
        <CardBody>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <Form role="form">
              <div className="d-flex justify-content-center">
                <PaymentCard
                  brand={cardData.brand}
                  number={cardData.number}
                  cvv={cardData.cvv}
                  holderName={cardData.holderName}
                  expiration={`${cardData.month}/${cardData.year}`}
                  flipped={flipped}
                />
              </div>
              <div className="mt-5">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-card-number">
                    Número do Cartão
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="4111 1111 1111 1111"
                    type="text"
                    id="input-card-number"
                    name="number"
                    onChange={onChangeCard}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-holder-name">
                    Nome <span className="font-weight-normal">(Impresso no Cartão)</span>
                  </label>
                  <Input
                    id="input-holder-name"
                    name="holderName"
                    className="form-control-alternative"
                    placeholder="YUNG BUDA"
                    type="text"
                    onChange={onChangeCard}
                  />
                </FormGroup>
                <div className="d-flex">
                  <FormGroup className="mr-5">
                    <label className="form-control-label" htmlFor="input-expiration">
                      Validade
                    </label>
                    <div className="d-flex">
                      <Input
                        className="form-control-alternative mr-3"
                        type="select"
                        name="month"
                        id="input-expiration"
                        onChange={onChangeCard}
                      >
                        <option value="" disabled defaultValue>
                          Mês
                        </option>
                        <option value="01">01 - Janeiro</option>
                        <option value="02">02 - Fevereiro</option>
                        <option value="03">03 - Março</option>
                        <option value="04">04 - Abril</option>
                        <option value="05">05 - Maio</option>
                        <option value="06">06 - Junho</option>
                        <option value="07">07 - Julho</option>
                        <option value="08">08 - Agosto</option>
                        <option value="09">09 - Setembro</option>
                        <option value="10">10 - Outubro</option>
                        <option value="11">11 - Novembro</option>
                        <option value="12">12 - Dezembro</option>
                      </Input>
                      <Input
                        className="form-control-alternative"
                        type="select"
                        name="year"
                        id="input-year"
                        onChange={onChangeCard}
                      >
                        <option value="" disabled defaultValue>
                          Ano
                        </option>
                        {[...Array(11).keys()].map(el => (
                          <option key={el} value={el + 20}>{`20${el + 20}`}</option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-cvv">
                      Cod. Segurança
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="123"
                      type="text"
                      name="cvv"
                      id="input-cvv"
                      onChange={onChangeCard}
                      onFocus={flipCard}
                      onBlur={flipCard}
                    />
                  </FormGroup>
                </div>
                <ReCAPTCHA sitekey="6LeFEdAUAAAAAEkRP9-0xaRIYxAAbP2GWf3-0obH" ref={recaptchaRef} />,
              </div>
              <hr className="mt-5" />
              <div className="text-right">
                <Button className="btn-icon btn-2" type="button" onClick={toggle}>
                  <span className="btn-inner--text">Cancelar</span>
                </Button>
                <Button className="btn-icon btn-2" color="primary" onClick={onSubmit}>
                  <span className="btn-inner--text">Concluir</span>
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default AddCreditCard;
