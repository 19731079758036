import ShopAnalytics from '../pages/Dashboard/ShopAnalytics/ShopAnalytics';
import ProductAnalytics from '../pages/Dashboard/ProductAnalytics/ProductAnalytics';
import Orders from '../pages/Dashboard/Orders/Orders';
import Integrations from '../pages/Dashboard/Integrations/Integrations';
import MercadoPagoForm from '../components/MercadoPagoForm/MercadoPagoForm';
import ProductCost from '../pages/Dashboard/ProductCost/ProductCost';
import Billets from '../pages/Dashboard/Billets/Billets';
import CustomSpends from '../pages/Dashboard/CustomSpends/CustomSpends';
import FacebookForm from '../components/FacebookForm/FacebookForm';
import AliexpressForm from '../components/AliexpressForm/AliexpressForm';
import CieloForm from '../components/CieloForm/CieloForm';
import Dashboard from '../pages/Admin/Dashboard/Dashboard';
import AppmaxForm from '../components/AppmaxForm/AppmaxForm';
import CloudfoxForm from '../components/CloudfoxForm/CloudfoxForm';
import PagseguroForm from '../components/PagseguroForm/PagseguroForm';

const menuRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard Admin',
    icon: 'ni ni-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/shop-analytics',
    name: 'Análise Loja',
    icon: 'ni ni-chart-pie-35',
    component: ShopAnalytics,
    layout: '/dashboard',
  },
  {
    path: '/product-analytics',
    name: 'Análise Produtos',
    icon: 'ni  ni-chart-bar-32',
    component: ProductAnalytics,
    layout: '/dashboard',
  },
  {
    path: '/orders',
    name: 'Pedidos',
    icon: 'ni ni-basket',
    component: Orders,
    layout: '/dashboard',
  },
  {
    path: '/billet',
    name: 'Boletos',
    icon: 'fa fa-barcode',
    component: Billets,
    layout: '/dashboard',
  },

  {
    path: '/products-costs',
    name: 'Custo dos Produtos',
    icon: 'ni ni-shop',
    component: ProductCost,
    layout: '/dashboard/config',
  },
  {
    path: '/integrations',
    name: 'Integrações',
    icon: 'ni ni-app',
    component: Integrations,
    layout: '/dashboard/config',
  },
  {
    path: '/custom-spends',
    name: 'Valores Adicionais',
    icon: 'ni ni-archive-2',
    component: CustomSpends,
    layout: '/dashboard/config',
  },
  {
    path: '/mercado-pago',
    name: 'Integração com Mercado Pago',
    component: MercadoPagoForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/facebook',
    name: 'Integração com Facebook',
    component: FacebookForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/aliexpress',
    name: 'Integração com AliExpress',
    component: AliexpressForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/cielo',
    name: 'Integração com Cielo',
    component: CieloForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/appmax',
    name: 'Integração com AppMax',
    component: AppmaxForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/cloudfox',
    name: 'Integração com Cloudfox',
    component: CloudfoxForm,
    layout: '/dashboard/integrations',
  },
  {
    path: '/pagseguro',
    name: 'Integração com Pagseguro',
    component: PagseguroForm,
    layout: '/dashboard/integrations',
  },
];

export default menuRoutes;
