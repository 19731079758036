import React, { memo } from 'react';
import { Card, CardText, CardImg, Col } from 'reactstrap';
import defultStoreImage from '../../../assets/img/theme/default-store.svg';
import './Card.css';

const shopifyLogo = 'https://upload.wikimedia.org/wikipedia/commons/e/e1/Shopify_Logo.png';
const cartxLogo = 'https://cartx.io/wp-content/uploads/2019/09/new-logo-copy-2.svg';

const getPlataformLogo = platform =>
  ({
    shopify: shopifyLogo,
    cartx: cartxLogo,
  }[platform]);

const StoreCard = ({ storeImage, storeName, storeSlug, storePlatform, onOpenStore }) => {
  const handleClick = () => {
    onOpenStore({ storeSlug, storePlatform });
  };

  return (
    <Col lg="3" md="4" sm="6" className="store-card">
      <Card className="card-shadow bg-secondary border bg-white my-2 rounded" onClick={handleClick}>
        <div className="text-right d-inline-block pt-3 pr-3">
          <img
            width="100px"
            className="mb-2 ml-2 "
            src={getPlataformLogo(storePlatform)}
            alt="plataform logo"
          />
        </div>
        <CardImg
          top
          height="200px"
          className="justify-content-center card-profile-image px-5 py-0 "
          src={storeImage || defultStoreImage}
          alt="Store Image"
        />
        <div className="dropdown-divider" />
        <CardText className="my-2 text-blue-marine h2">{storeName}</CardText>
      </Card>
    </Col>
  );
};

export default memo(StoreCard);
