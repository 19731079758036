import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

const AliexpressForm = () => {
  return (
    <Container fluid className="pb-8 pt-5 pt-md-8">
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Integração com Aliexpress</h3>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="mx-md-6">
              <h2 className="mt-4">Veja como integrar com a Aliexpress!</h2>
              <p className="mt-5">
                Antes de tudo instale nossa <b>extensão</b> para o google chrome no link abaixo: 👇
              </p>
              <a href="https://chrome.google.com/webstore/detail/profitfy/aidoclnkpklaaeikjeodkddifooblllp?hl=pt-BR">
                <p className="font-weight-bold"> Instalar extensão profitfy.me</p>
              </a>
              <p>
                Após ter instalado, assista <b>atentamente</b>, as instruções do Chris, para que
                funcione corretamente.
              </p>

              <video
                src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/video-aliexpress.mov"
                controls
                playsInline
              >
                <track default kind="captions" srcLang="en" />
              </video>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AliexpressForm;
