import React from 'react';

// import { Container } from './styles';

const FacebookInsightsTutorial = () => {
  return (
    <>
      <h1 className="font-weight-light">Ative campanhas deste produto.</h1>
      <p className="text-gray mt-2">
        Atráves desse tutorial te ensinaremos como adicionar mais de um perfil do facebook. Uma
        grande utilidade para quem trabalha fortemente com contigência. Importante ressaltar que
        esse processo não influência em nada que possa prejudicar sua BM.
      </p>
      <div className="mt-5">
        <video src="" controls playsInline>
          <track default kind="captions" srcLang="en" />
        </video>
      </div>
    </>
  );
};

export default FacebookInsightsTutorial;
