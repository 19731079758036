const numberBrFormatter = (number, minimumFractionDigits = 1) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits,
  });

  return `${formatter.format(number)}`;
};

const calculateProductMargin = (cogs, productSellPrice) => {
  const result = (cogs * 100) / productSellPrice;
  return Number(Math.round(Math.abs(result - 100)).toFixed(0));
};

const parseLineChartData = data => {
  const profit = data[0]?.map(el => ({ t: new Date(el.t), y: el.y }));
  // const marketing = data[1]?.map(el => ({ t: new Date(el.t), y: el.y }));
  const cogs = data[1]?.map(el => ({ t: new Date(el.t), y: el.y }));
  const revenue = data[2]?.map(el => ({ t: new Date(el.t), y: el.y }));

  return {
    profit,
    //  marketing,
    cogs,
    revenue,
  };
};

const masks = {
  cpf(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cnpj(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cep(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  },
};

export { numberBrFormatter, calculateProductMargin, parseLineChartData, masks };
