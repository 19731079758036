import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, CardFooter } from 'reactstrap';
import Steps, { Step } from 'rc-steps';
import moment from 'moment-timezone';
import NewUserPayments from './Payments/Payments';
import NewUserMarketing from './Marketing/Marketing';
import CheckoutFee from '../../components/CheckoutFee/CheckoutFee';
import ProductCost from '../Dashboard/ProductCost/ProductCost';
import Finish from './Finish/Finish';
import VideoTutorial from './VideoTutorial/VideoTutorial';
import MobileApp from '../../components/MobileApp/MobileApp';
import Tax from '../../components/Tax/Tax';
import BubblesBackground from '../../components/BubblesBackground/BubblesBackground';
import { api, shopsApi } from '../../services/api';
import { useToast } from '../../providers/ToastContext';
import logo from '../../assets/img/brand/logo-white.svg';

const NewUserFlux = () => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const { platform, store } = useParams();
  const history = useHistory();
  const { showToast } = useToast();

  const getCurrentComponent = useCallback(
    current =>
      ({
        0: <NewUserPayments />,
        1: <NewUserMarketing />,
        2: (
          <>
            <CheckoutFee />
            <div className="mt-4">
              <Tax size="12" />
            </div>
            <VideoTutorial src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/taxa.webm" />
          </>
        ),
        3: (
          <div>
            <h1 className="title font-weight-normal text-center px-md-8 my-4">
              Importante que coloque o custo dos produtos para que o calculo seja feito de maneira
              correta!
            </h1>
            <ProductCost className="mt-0" limit="5" />
            <VideoTutorial src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/cogs.webm" />
          </div>
        ),
        4: <MobileApp />,
        5: (
          <>
            <Finish />
            <VideoTutorial src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/pronto.webm" />
          </>
        ),
      }[current]),
    [],
  );

  const onFinish = useCallback(async () => {
    try {
      setLoading(true);
      await api(shopsApi[platform]).patch(`/api/v1/users/${platform}/stores/${store}/first-time`, {
        isFirstTime: false,
      });

      await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/calculate-historic?endDate=${moment().format(
          'YYYY-MM-DD',
        )}&startDate=${moment()
          .subtract(1, 'month')
          .format('YYYY-MM-DD')}`,
      );
      history.push(`/${platform}/${store}/dashboard/shop-analytics`);
    } finally {
      setLoading(false);
    }
  }, [history, platform, store]);

  const handleNext = useCallback(() => {
    let storageValue = localStorage.getItem('gateway');

    if (storageValue) {
      storageValue = JSON.parse(storageValue);
    }

    const hasGatewayConnected = Object.values(storageValue).some(gateway => gateway);

    if (!hasGatewayConnected) {
      showToast({
        message: 'É necessário integrar com um gateway o dashboard não funcionará corretamente!',
        type: 'warn',
      });
    }

    setCurrent(current => current + 1);
  }, [showToast]);

  return (
    <>
      <BubblesBackground>
        <div className="main-content subscribe">
          <div className="header py-5 py-lg-4 vh-90">
            <Container className="subscribe-width">
              <Row className="justify-content-center">
                <Col>
                  <div className="img-fluid text-center">
                    <img alt="profitfy" className="img-fluid" width="350px" src={logo} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-6">
                <Col>
                  <Card className="card-shadow">
                    <CardHeader className="card-step-header">
                      <Steps
                        size="lg"
                        labelPlacement="vertical"
                        current={current}
                        onChange={val => setCurrent(val)}
                      >
                        <Step
                          title="Gateway"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-credit-card" />
                            </Button>
                          }
                        />
                        <Step
                          title="Marketing"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-users" />
                            </Button>
                          }
                        />
                        <Step
                          title="Taxas"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-cog" />
                            </Button>
                          }
                        />
                        <Step
                          title="Cogs"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-shopping-cart" />
                            </Button>
                          }
                        />
                        <Step
                          title="App"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="ni ni-mobile-button" />
                            </Button>
                          }
                        />
                        <Step
                          title="Pronto"
                          icon={
                            <Button type="button" className="btn btn-circle btn-xl">
                              <i className="fas fa-rocket" />
                            </Button>
                          }
                        />
                      </Steps>
                    </CardHeader>
                    <CardBody className="pb-5">{getCurrentComponent(current)}</CardBody>
                    <CardFooter className={current === 5 ? 'text-center' : 'text-right'}>
                      {current === 5 ? (
                        <Button
                          color="primary"
                          className="text-lg"
                          onClick={onFinish}
                          disabled={loading}
                        >
                          {loading ? 'Carregando...' : 'Ir para Dashboard'}
                        </Button>
                      ) : (
                        <Button color="dark" onClick={handleNext}>
                          Próximo
                        </Button>
                      )}
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </BubblesBackground>
    </>
  );
};

export default NewUserFlux;
