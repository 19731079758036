import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import _ from 'lodash';
import TableBody from './TableBody/TableBody';
import { api, shopsApi, makeSearchRequest } from '../../../../../../services/api';
import TableHeader from './TableHeader/TableHeader';
import TableFooter from './TableFooter/TableFooter';
import { useProductDetails } from '../../../../../../providers/ProductDetails';
import usePersistedState from '../../../../../../utils/usePersistedState';

const FacebookInsightsCampaignTable = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [campaignName, setCampaignName] = useState('');
  const [loadingStatus, setLoadingStatus] = useState({ status: false, id: null });
  const { platform, store, productSlug } = useParams();
  const { loadProductDetails } = useProductDetails();
  const [period] = usePersistedState('topProductsDatePicker');

  const loadCampaigns = useCallback(
    async (spinner = true) => {
      const limit = 10;
      setLoading(spinner);

      const search = await makeSearchRequest({ api: shopsApi[platform] });
      const response = await search(
        `api/v1/users/${platform}/stores/${store}/products/${productSlug}/facebook/campaigns?page=${page}&name=${campaignName}&limit=${limit}`,
      );

      if (response) {
        const orderedCampaigns = _.orderBy(response.facebookCampaigns, 'active', 'desc');

        setCampaigns(orderedCampaigns);
        setPagesCount(response.numberOfPages);
        setLoading(false);
      }
    },
    [platform, store, productSlug, campaignName, page],
  );

  const handleCheckBox = useCallback(
    async event => {
      const { checked, id } = event.target;

      try {
        setLoadingStatus({ status: true, id });
        api(shopsApi[platform]).patch(
          `api/v1/users/${platform}/stores/${store}/products/${productSlug}/facebook/campaigns/${id}`,
          {
            active: checked,
          },
        );

        loadProductDetails({ platform, store, productSlug, period });
      } finally {
        setLoadingStatus({ status: false, id });
      }
    },
    [productSlug, platform, store, loadProductDetails, period],
  );

  const handleSyncCampaigns = async () => {
    try {
      setLoading(true);
      await api('user-api').post(`api/v1/users/facebook/ad-accounts/${platform}/campaigns`);

      await loadCampaigns();
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async event => {
    const { value } = event.target;

    setPage(1);
    setCampaignName(value);
  };

  const handleChangePages = page => {
    setPage(page.selected + 1);
  };

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    <Card className="shadow">
      <TableHeader handleSearch={handleSearch} handleSyncCampaigns={handleSyncCampaigns} />
      <TableBody
        campaigns={campaigns}
        loadingStatus={loadingStatus}
        handleCheckBox={handleCheckBox}
        loading={loading}
      />
      <TableFooter pages={pagesCount} handleChangePages={handleChangePages} />
    </Card>
  );
};

export default FacebookInsightsCampaignTable;
