/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Tooltip, CardTitle } from 'reactstrap';
import AnimatedNumber from 'animated-number-react';
import { SyncLoader } from 'react-spinners';
import { numberBrFormatter } from '../../../../../utils/utils';
import { api, shopsApi } from '../../../../../services/api';
import './styles.scss';

const CardProfit = ({ data, loadStore, loadChartData, lg, xl }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [storeConfiguration, setStoreConfiguration] = useState({
    addCustomSpendInProfit: true,
    updateAliexpressCostToVariant: false,
  });

  const [loading, setLoading] = useState(false);
  const { store, platform } = useParams();

  const loadStoreSettings = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/store-configurations`,
    );

    if (data.storeConfiguration) {
      setStoreConfiguration(data.storeConfiguration);
    }
  }, [platform, store]);

  useEffect(() => {
    loadStoreSettings();
  }, [loadStoreSettings]);

  const handleCheckbox = useCallback(async () => {
    const requestType = storeConfiguration.id ? 'put' : 'post';

    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/store-configurations/${storeConfiguration?.id ||
          ''}`,
        {
          ...storeConfiguration,
          addCustomSpendInProfit: !storeConfiguration.addCustomSpendInProfit,
        },
      );

      setStoreConfiguration(data.storeConfiguration);

      loadStore({ spinner: false });
      loadChartData({ spinner: false });
    } finally {
      setLoading(false);
    }
  }, [platform, store, storeConfiguration, loadStore, loadChartData]);

  return (
    <Col xl={xl} lg={lg} className="mt-2">
      <Card className="shadow">
        <div className="card-profit">
          <div className="title">
            <div>
              <CardTitle tag="h5" className="text-uppercase text-muted pb-0 card-height">
                Lucro Liquído
              </CardTitle>
              <div className="pt-2">
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input"
                    id="include-custom-spends"
                    type="checkbox"
                    defaultChecked={storeConfiguration.addCustomSpendInProfit}
                    onClick={handleCheckbox}
                  />
                  <label className="custom-control-label" htmlFor="include-custom-spends">
                    <small className="text-muted">Incluir valores adicionais</small>
                  </label>
                </div>
              </div>
            </div>

            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
              <i className="fas fa-dollar-sign" />
            </div>
          </div>

          <div className="content">
            {loading ? (
              <SyncLoader color="#BDB5B5" loading={loading} size={10} />
            ) : (
              <div>
                <span className="text-gray mr-1">R$ </span>
                <span className="h1 font-weight-bold">
                  <AnimatedNumber
                    value={data.value}
                    formatValue={numberBrFormatter}
                    duration={1500}
                  />
                </span>
              </div>
            )}

            <i className="fas fa-info-circle text-light text-right" id="Tooltip-card-profit" />
            <Tooltip
              placement="left"
              isOpen={tooltipOpen}
              target="Tooltip-card-profit"
              toggle={toggle}
              color="secondary"
            >
              <span className="text-gray-dark">
                Lucro líquido = Faturamento - Custo Dos Produtos - Marketing - Taxas do Gateway -
                Valores Adicionais
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="bg-gradient-success card-background mt--2" />
      </Card>
    </Col>
  );
};

export default memo(CardProfit);
