/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useContext } from 'react';
import { Button, Form, FormGroup, Input, CardBody, Card, Col, Row } from 'reactstrap';
import PaymentCard from 'react-payment-card-component';
import ReCAPTCHA from 'react-google-recaptcha';
import pagarme from 'pagarme';
import SubscribeContext from '../../../providers/SubscribeContext';
import PlanDetail from '../PlanDetail/PlanDetail';
import { api } from '../../../services/api';
import './CreditCard.scss';

const SubscribeCreditCard = () => {
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [cardData, setCardData] = useState({
    number: '4111111111111111',
    cvv: '202',
    holderName: 'Yung Buda',
    month: '12',
    year: '22',
  });

  const { value: planData, setCurrent } = useContext(SubscribeContext);

  const flipCard = () => setFlipped(!flipped);

  const onSubmit = async () => {
    const parsedCardDataToEncryption = {
      card_holder_name: cardData.holderName,
      card_expiration_date: `${cardData.month}${cardData.year}`,
      card_number: cardData.number,
      card_cvv: cardData.cvv,
    };

    const client = await pagarme.client.connect({
      encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
    });

    const cardHash = await client.security.encrypt(parsedCardDataToEncryption);

    const parsedCardData = {
      number: cardData.number,
      holderName: cardData.holderName,
      expirationDate: `${cardData.month}${cardData.year}`,
      cardHash,
    };

    try {
      setLoading(true);
      await api('user-api').post(`api/v1/users/cards`, parsedCardData);
      await api('user-api').post('api/v1/users/subscriptions', {
        planId: String(planData.planId),
      });
      setCurrent(3);
    } finally {
      setLoading(false);
    }
  };

  const onChangeCard = event => {
    const { name, value } = event.target;
    const parsedData = { [name]: value };

    setCardData({ ...cardData, ...parsedData });
  };

  return (
    <Card className="shadow credit-card">
      <CardBody>
        <Row className="justify-content-center">
          <Col md="6" className="order-2 order-md-1">
            <Form role="form">
              <div className="d-flex justify-content-center">
                <PaymentCard
                  brand={cardData.brand}
                  number={cardData.number}
                  cvv={cardData.cvv}
                  holderName={cardData.holderName}
                  expiration={`${cardData.month}/${cardData.year}`}
                  flipped={flipped}
                />
              </div>
              <div className="mt-5 px-4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-card-number">
                    Número do Cartão
                  </label>
                  <Input
                    placeholder="4111 1111 1111 1111"
                    type="text"
                    id="input-card-number"
                    name="number"
                    onChange={onChangeCard}
                    maxLength="19"
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-holder-name">
                    Nome <span className="font-weight-normal">(Impresso no Cartão)</span>
                  </label>
                  <Input
                    id="input-holder-name"
                    name="holderName"
                    placeholder="YUNG BUDA"
                    type="text"
                    onChange={onChangeCard}
                  />
                </FormGroup>
                <div className="d-flex">
                  <FormGroup className="mr-5">
                    <label className="form-control-label" htmlFor="input-expiration">
                      Validade
                    </label>
                    <div className="d-flex">
                      <Input
                        className=" mr-3"
                        type="select"
                        name="month"
                        id="input-expiration"
                        onChange={onChangeCard}
                      >
                        <option value="" disabled defaultValue>
                          Mês
                        </option>
                        <option value="01">01 - Janeiro</option>
                        <option value="02">02 - Fevereiro</option>
                        <option value="03">03 - Março</option>
                        <option value="04">04 - Abril</option>
                        <option value="05">05 - Maio</option>
                        <option value="06">06 - Junho</option>
                        <option value="07">07 - Julho</option>
                        <option value="08">08 - Agosto</option>
                        <option value="09">09 - Setembro</option>
                        <option value="10">10 - Outubro</option>
                        <option value="11">11 - Novembro</option>
                        <option value="12">12 - Dezembro</option>
                      </Input>
                      <Input type="select" name="year" id="input-year" onChange={onChangeCard}>
                        <option value="" disabled defaultValue>
                          Ano
                        </option>
                        {[...Array(11).keys()].map(el => (
                          <option key={el} value={el + 20}>{`20${el + 20}`}</option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-cvv">
                      Cod. Segurança
                    </label>
                    <Input
                      placeholder="123"
                      type="text"
                      name="cvv"
                      id="input-cvv"
                      onChange={onChangeCard}
                      onFocus={flipCard}
                      onBlur={flipCard}
                      maxLength="3"
                    />
                  </FormGroup>
                </div>

                <div className="d-flex justify-content-center mt-4">
                  <ReCAPTCHA
                    sitekey="6LeFEdAUAAAAAEkRP9-0xaRIYxAAbP2GWf3-0obH"
                    ref={recaptchaRef}
                  />
                </div>
              </div>
              <hr className="mt-5" />
              <div className="text-right mr-3">
                <Button className="btn-icon btn-2" type="button" onClick={() => setCurrent(0)}>
                  <span className="btn-inner--text">Voltar</span>
                </Button>
                <Button
                  className="btn-icon btn-2 btn-lg"
                  disabled={loading}
                  color="primary"
                  onClick={onSubmit}
                >
                  {loading ? 'Processando...' : 'Confirmar'}
                </Button>
              </div>
            </Form>
          </Col>
          <Col
            className="bg-lighter mt--4 my-md--4 mr-md--3 px-md-6 mb-6 mb-md-0 order-1 order-md-2"
            md="6"
          >
            <PlanDetail />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SubscribeCreditCard;
