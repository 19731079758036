import React, { useCallback, useMemo } from 'react';
import { Container, Row } from 'reactstrap';
import CardWidget from '../../../../components/CardWidget/CardWidget';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import CardProfit from './CardProfit/CardProfit';
import { numberBrFormatter } from '../../../../utils/utils';
import getWarnAlerts from '../../../../utils/warns/warns';
import './DashboardHeader.css';

const DashboardHeader = ({
  setStorePeriod,
  storePeriod,
  storeData,
  webhooks,
  loadStore,
  loadChartData,
}) => {
  const onDate = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return;
      if (!startDate.isValid() || !endDate.isValid()) return;
      setStorePeriod({ startDate, endDate });
    },
    [setStorePeriod],
  );

  const cardData = useMemo(() => {
    return {
      revenue: {
        id: 12,
        title: 'Faturamento',
        iconColor: 'yellow',
        icon: 'fas fa-chart-bar',
        ...storeData.profit.revenue,
        value: storeData.profit.revenue,
        formatter: numberBrFormatter,
        animated: true,
        description: 'Valor total de pedidos aprovados',
      },
      cogs: {
        id: 13,
        title: 'Custo dos produtos',
        iconColor: 'orange',
        icon: 'fas fa-shopping-cart',
        ...storeData.profit.cogs,
        value: storeData.profit.cogs,
        formatter: numberBrFormatter,
        animated: true,
        description: 'Custo total dos produtos para os pedidos aprovados',
      },
      marketing: {
        id: 14,
        title: 'Marketing',
        iconColor: 'purple',
        icon: 'fas fa-users',
        ...storeData.profit.marketing,
        value: storeData.profit.marketing,
        formatter: numberBrFormatter,
        animated: true,
        description: 'Custo total gasto com marketing',
      },
      tax: {
        id: 14,
        title: 'Taxas de Transação',
        iconColor: 'blue',
        icon: 'fa fa-cart-arrow-down',
        ...storeData.profit.totalTransactionTax,
        value: storeData.profit.totalTransactionTax,
        formatter: numberBrFormatter,
        animated: true,
        description: 'Soma das taxas de Gateway, Checkout e Impostos',
      },
      profit: {
        ...storeData.profit.profit,
        value: storeData.profit.profit,
      },
    };
  }, [
    storeData.profit.cogs,
    storeData.profit.marketing,
    storeData.profit.profit,
    storeData.profit.totalTransactionTax,
    storeData.profit.revenue,
  ]);

  return (
    storeData && (
      <div className="header pt-5 pt-md-8">
        <Container fluid>
          {getWarnAlerts(storeData.warning).map(Component => {
            return <Component key={Component} store={storeData.store} webhooks={webhooks} />;
          })}
          <div className="p-3">
            <DatePicker
              onDate={onDate}
              startDate={storePeriod.startDate}
              endDate={storePeriod.endDate}
              showPreDefinedDates
            />
          </div>
          <div className="header-body mt-3">
            <Row>
              <CardWidget data={cardData.revenue} xl="2-4" lg="6" prepend="R$" />
              <CardWidget data={cardData.cogs} xl="2-4" lg="6" prepend="R$" />
              <CardWidget data={cardData.marketing} xl="2-4" lg="6" prepend="R$" />
              <CardWidget data={cardData.tax} xl="2-4" lg="6" prepend="R$" />
              <CardProfit
                data={cardData.profit}
                loadStore={loadStore}
                loadChartData={loadChartData}
              />
            </Row>
          </div>
        </Container>
      </div>
    )
  );
};

export default DashboardHeader;
