import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import UploadMessage from './UploadMessage/UploadMessage';
import './styles.scss';

const getBorderColor = (isDragActive, isDragReject) => {
  if (isDragReject) return 'danger';
  if (isDragActive) return 'success';

  return 'light';
};

const Upload = ({ onUpload }) => {
  const renderDragMessage = useCallback((isDragActive, isDragRejest) => {
    if (!isDragActive) {
      return <UploadMessage type="default">Selecione ou arraste o arquivo aqui</UploadMessage>;
    }

    if (isDragRejest) {
      return <UploadMessage type="danger">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }, []);

  return (
    <Dropzone accept=".csv, text/csv" onDropAccepted={files => onUpload(files)}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          {...getRootProps()}
          className={`drop-container py-5 ml-2 text-center border-${getBorderColor(
            isDragActive,
            isDragReject,
          )}`}
        >
          <input {...getInputProps()} data-testid="upload" />
          {renderDragMessage(isDragActive, isDragReject)}
        </div>
      )}
    </Dropzone>
  );
};

export default Upload;
