import React, { useState, useEffect, useCallback } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import OrdersTableHeader from './TableHeader/TableHeader';
import OrdersTableBody from './TableBody/TableBody';
import OrdersTableFooter from './TableFooter/TableFooter';
import Spinner from '../../../components/Spinner/Spinner';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { shopsApi, makeSearchRequest } from '../../../services/api';
import ufoImage from '../../../assets/img/theme/ufo.svg';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [domain, setDomain] = useState('');
  const [orderFilters, setOrderFilters] = useState({
    name: null,
    hasTrackingNumber: false,
    type: null,
    status: null,
  });
  const [orderSort, setOrderSort] = useState({
    createdAt: 'desc',
    name: '',
    revenue: '',
    cogs: '',
    profit: '',
  });
  const [orderPeriod, setOrderPeriod] = useState({
    startDate: moment().subtract(2, 'M'),
    endDate: moment(),
  });
  const { store, platform } = useParams();

  const loadOrdersData = useCallback(
    async load => {
      const limit = 20;
      setLoading(Boolean(load));

      const search = await makeSearchRequest({ api: shopsApi[platform] });

      try {
        const response = await search(
          `api/v1/users/${platform}/stores/${store}/orders?page=${page}&limit=${limit}&startDate=${orderPeriod.startDate.format(
            'YYYY-MM-DD',
          )}&endDate=${orderPeriod.endDate.format('YYYY-MM-DD')}&${queryString.stringify({
            filter: JSON.stringify(orderFilters),
          })}&${queryString.stringify({
            sort: JSON.stringify(orderSort),
          })}`,
        );

        if (response) {
          setOrders(response.data.orders);
          setDomain(response.data.domain);
          setPageCount(response.data.numberOfPages);
        }
      } finally {
        setLoading(false);
      }
    },

    [page, store, platform, orderPeriod, orderFilters, orderSort],
  );

  const handleFilter = async orderParams => {
    setPage(1);
    setOrderFilters({ ...orderFilters, ...orderParams });
  };

  const onChangePage = page => {
    setPage(page.selected + 1);
  };

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPage(1);
    setOrderPeriod({ startDate, endDate });
  }, []);

  useEffect(() => {
    loadOrdersData(true);
  }, [loadOrdersData]);

  return (
    <Container fluid className="pb-8 pt-0 pt-md-8">
      <Row className="mb-4">
        <Col>
          <DatePicker
            startDate={orderPeriod.startDate}
            endDate={orderPeriod.endDate}
            onDate={onDate}
            showPreDefinedDates
          />
        </Col>
      </Row>
      <Row>
        <div className="col">
          <Card className="shadow">
            <OrdersTableHeader handleFilter={handleFilter} />
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <OrdersTableBody
                orders={orders}
                platform={platform}
                domain={domain}
                setOrderSort={setOrderSort}
                orderSort={orderSort}
                loadOrdersData={loadOrdersData}
              />
            )}
            <OrdersTableFooter pages={pagesCount} onChangePage={onChangePage} />
          </Card>
        </div>
      </Row>
      {orders.length <= 0 && !loading && (
        <Row className="align-items-center flex-column mt-6">
          <img src={ufoImage} width="300px" alt="no data" />
          <h1 className="mt-4 text-muted text-center">Seus pedidos foram abduzidos</h1>
        </Row>
      )}
    </Container>
  );
};

export default Orders;
