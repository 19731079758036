import React from 'react';

import { ToastProvider } from './ToastContext';
import { ProductDetailsProvider } from './ProductDetails';

const AppProvider = ({ children }) => {
  return (
    <ToastProvider>
      <ProductDetailsProvider>{children}</ProductDetailsProvider>
    </ToastProvider>
  );
};

export default AppProvider;
