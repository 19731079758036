import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { api, shopsApi } from '../../services/api';
import { useToast } from '../../providers/ToastContext';

const CieloForm = ({ onSave = () => {}, className }) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ merchantId: '', merchantKey: '', id: null });
  const [cieloFee, setCieloFee] = useState({
    debitCard: 2.7,
    creditCard: 2.7,
    creditCard3x: 4.75,
    creditCard6x: 5.0,
    creditCard12x: 5.5,
  });

  const handleMerchantKey = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ merchantKey: value } });
  };

  const handleMerchantId = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ merchantId: value } });
  };

  const onSubmit = async () => {
    try {
      const requestType = credentials.id ? 'put' : 'post';

      setLoading(true);
      const { data } = await api(shopsApi[platform])[requestType](
        `api/v1/users/${platform}/stores/${store}/cielo-credentials/${credentials?.id || ''}`,
        credentials,
      );

      setCredentials({
        merchantId: data.cieloCredential.merchantId,
        merchantKey: data.cieloCredential.merchantKey,
        id: data.cieloCredential.id,
      });

      if (data) {
        const requestType = cieloFee.id ? 'put' : 'post';

        await api(shopsApi[platform])[requestType](
          `api/v1/users/${platform}/stores/${store}/cielo-credentials/${
            data.cieloCredential.id
          }/fees/${cieloFee?.id || ''}`,
          cieloFee,
        );
      }

      showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
      onSave('cielo');
    } finally {
      setLoading(false);
    }
  };

  const handleFee = (installment, event) => {
    const { value } = event.target;
    setCieloFee({ ...cieloFee, [installment]: value });
  };

  const loadCredentials = useCallback(async () => {
    setLoading(true);
    const { data } = await api(shopsApi[platform]).get(
      `api/v1/users/${platform}/stores/${store}/cielo-credentials`,
    );

    if (data?.cieloCredential != null) {
      setCredentials({
        merchantId: data.cieloCredential.merchantId,
        merchantKey: data.cieloCredential.merchantKey,
        id: data.cieloCredential.id,
      });
    }

    if (data?.cieloFee?.id) {
      setCieloFee(data.cieloFee);
    }

    setLoading(false);
  }, [platform, store]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Configurações</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="input-access-token">
                            Merchant Id
                          </Label>
                          <Input
                            className="form-control-alternative"
                            id="input-access-token"
                            type="text"
                            onChange={handleMerchantId}
                            defaultValue={credentials.merchantId}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="input-public-key">
                            Merchant Key
                          </Label>
                          <Input
                            className="form-control-alternative"
                            id="input-public-key"
                            defaultValue={credentials.merchantKey}
                            type="text"
                            onChange={handleMerchantKey}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="align-items-center">
                      <Col md="5" className="pr-md-8">
                        <h1>Defina suas parcelas</h1>
                        <p className="text-gray">
                          Defina a porcentagem de suas parcelas para que o calculo seja feito de
                          forma correto.
                        </p>
                      </Col>
                      <Col>
                        <Row className="mx-md-6">
                          <Col className="mr-md-6">
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="input-1x">
                                à vista
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-1x"
                                  type="text"
                                  onChange={e => handleFee('creditCard', e)}
                                  defaultValue={cieloFee?.creditCard}
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <span className="fa fa-percent" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="input-2x">
                                De 2x - 3x
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-2x"
                                  type="text"
                                  onChange={e => handleFee('creditCard3x', e)}
                                  defaultValue={cieloFee?.creditCard3x}
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <span className="fa fa-percent" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="input-4x">
                                De 4x - 6x
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-4x"
                                  type="text"
                                  onChange={e => handleFee('creditCard6x', e)}
                                  defaultValue={cieloFee?.creditCard6x}
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <span className="fa fa-percent" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label className="form-control-label" htmlFor="input-12x">
                                7x - 12x
                              </Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-12x"
                                  type="text"
                                  onChange={e => handleFee('creditCard12x', e)}
                                  defaultValue={cieloFee?.creditCard12x}
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <span className="fa fa-percent" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary" size="md" onClick={onSubmit}>
                  Salvar
                </Button>
              </CardFooter>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CieloForm;
