import React, { memo } from 'react';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import aliexpressIcon from '../../../../../assets/img/theme/aliexpress-icon.png';
import './styles.scss';

const RowActions = ({ rowHovered, order, handleEditOrder }) => {
  return (
    <>
      <td width="15%">
        <i
          className={`fa fa-truck text-lg text-${
            order.trackingNumber ? 'default' : 'light'
          } truck-button `}
          id="tracking-number"
        />

        {rowHovered === order.id && (
          <>
            <Button className="btn-link" onClick={() => handleEditOrder(order)}>
              <i className="fa fa-pen text-purple text-lg" />
            </Button>
            <UncontrolledPopover trigger="hover" placement="top" flip target="tracking-number">
              <PopoverBody>
                {order.trackingNumber ? (
                  <>
                    <p className="text-purple font-weight-bold mb-1">{order.trackingNumber}</p>

                    {order.supplierOrderId && (
                      <a
                        className="text-orange font-weight-bold"
                        href={`https://trade.aliexpress.com/order_detail.htm?orderId=${order.supplierOrderId}`}
                        target="blank"
                      >
                        Ir para aliexpress{' '}
                        <img src={aliexpressIcon} width="16px" alt="aliexpress profitfy" />
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    {order.products.length > 1 ? (
                      <span className="text-default font-weight-bold">
                        Há mais de 2 produtos no pedido, e
                        <br /> por isso não conseguimos os códigos de rastreio.
                      </span>
                    ) : (
                      <span className="text-default font-weight-bold">Sem código de rastreio</span>
                    )}
                  </>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )}
      </td>
    </>
  );
};

export default memo(RowActions);
