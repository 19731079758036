import React, { memo } from 'react';
import './Footer.css';

import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

const Footer = ({ transparent }) => {
  return (
    <>
      <footer className={transparent ? 'footer bg-transparent' : 'footer'}>
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div
                className={`copyright text-center text-xl-left text-muted ${transparent &&
                  'text-white'}`}
              >
                © 2020
                <a
                  className={`font-weight-bold ml-1 ${transparent && 'text-white'} `}
                  href="https://profitfy.me"
                >
                  Profitfy.me
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    href="https://docs.profitfy.me/outros/fale-conosco"
                    target="_blank"
                    className={transparent && 'text-white'}
                  >
                    Fale Conosco
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://docs.profitfy.me/"
                    target="_blank"
                    className={transparent && 'text-white'}
                  >
                    Sobre Nós
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://docs.profitfy.me/tutoriais/integracao-com-mercado-pago"
                    target="_blank"
                    className={transparent && 'text-white'}
                  >
                    Tutoriais
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="contato@profitfy.me"
                    target="_blank"
                    className={transparent && 'text-white'}
                  >
                    contato@profitfy.me
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default memo(Footer);
