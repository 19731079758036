import React, { useState, useContext, useCallback, memo } from 'react';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import Spinner from '../../../components/Spinner/Spinner';
import { login } from '../../../services/auth';
import { api } from '../../../services/api';
import { useToast } from '../../../providers/ToastContext';
import UserContext from '../../../providers/UserContext';

const qaUser =
  process.env.REACT_APP_STAGE === 'qa'
    ? { email: 'fake-shop@profitfy.me', password: '123321' }
    : { email: '', password: '' };

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { loadUser } = useContext(UserContext);
  const { showToast } = useToast();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Você precisa inserir um email válido')
      .required('Este campo é obrigatório'),
    password: yup.string().required('Este campo é obrigatório'),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const onSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        const response = await api('auth-api').post(`/auth/login`, data);
        const { token } = response.data;
        login(token);

        await loadUser();
        history.push('/stores');
      } catch (error) {
        const message =
          error?.response?.data?.message || 'Por Favor entrar em contato com suporte.';
        showToast({ message, type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [history, loadUser, showToast],
  );

  return (
    <>
      <Col lg="6" md="8" className="mt-sm--8 mt-md--2 mt-lg--6 mt-xl--6 pb-5 container">
        {loading ? (
          <Spinner loading color="white" />
        ) : (
          <>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5 card-body">
                <div className="text-center text-muted mb-4">
                  <p>Insira suas credenciais</p>
                </div>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className={`${errors.email && 'has-danger'} mb-3`}>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={`${errors.email && 'text-red'} ni ni-email-83`} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <StrapInput
                        className={errors.email && 'is-invalid'}
                        placeholder="Email"
                        name="email"
                        innerRef={register}
                        defaultValue={qaUser.email}
                      />
                    </InputGroup>
                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                  </FormGroup>

                  <FormGroup className={errors.password ? 'has-danger' : ''}>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i
                            className={`${errors.password && 'text-red'} ni ni-lock-circle-open`}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <StrapInput
                        className={errors.password && 'is-invalid'}
                        placeholder="Password"
                        type="password"
                        name="password"
                        innerRef={register}
                        defaultValue={qaUser.password}
                      />
                    </InputGroup>
                    {errors.password && (
                      <small className="text-danger">{errors.password.message}</small>
                    )}
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                  </div>
                  <div className="text-center">
                    <Button className="my-2 btn-icon btn-2" color="primary" type="submit">
                      <span className="btn-inner--icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn-inner--text">Entrar</span>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-white" to="/auth/forgot-password">
                  <span>Esqueceu sua senha?</span>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="text-white" to="/auth/register">
                  <span>Criar nova conta</span>
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </>
  );
};

export default memo(Login);
