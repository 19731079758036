const getStatusColor = status => {
  return (
    {
      paid: 'primary',
      refunded: 'danger',
    }[status] || 'darker'
  );
};

const parseStatusName = status => {
  return (
    {
      paid: 'Pago',
      refunded: 'Estornado',
    }[status] || status
  );
};

export { parseStatusName, getStatusColor };
