import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const GatewayWarn = () => (
  <Alert color="danger" className="d-flex align-items-center">
    <span>
      <i className="fa fa-exclamation-triangle" /> Conecte-se a um <strong>gateway</strong> para que
      o dashboard funcione corretamente. Para integrar{' '}
      <Link className="alert-link" to="config/integrations">
        Clique aqui
      </Link>
    </span>
  </Alert>
);

export default GatewayWarn;
