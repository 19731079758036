import React, { useState, memo } from 'react';
import { Card, CardBody, CardTitle, Row, Col, Tooltip } from 'reactstrap';
import AnimatedNumber from 'animated-number-react';

const CustomCardWidget = ({ data, xl, lg, md, id, title, iconColor, icon, description }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Col xl={xl} lg={lg} md={md} className="mt-2 card-widget">
      <Card className="card-stats mb-4 mb-xl-0 pt-2">
        <CardBody>
          <Row>
            <div className="col d-flex align-items-center">
              <CardTitle tag="h5" className="text-uppercase text-muted pb-0 card-height">
                {title}
              </CardTitle>
            </div>
            <Col className="col-auto align-middle">
              <div className={`icon icon-shape bg-${iconColor} text-white rounded-circle shadow`}>
                <i className={icon} />
              </div>
            </Col>
          </Row>
          <div className="pt-1 mt--2 d-flex align-items-center">
            <div>
              {data.map(el => (
                <div key={el.id}>
                  <span>{el.title}</span>
                  <span className="mb--2 text-sm"> {el.append}</span>
                  <span className={`cart-value mb-0 text-${el.iconColor}  card-value`}>
                    {el.animated ? (
                      <AnimatedNumber value={el.value} formatValue={el.formatter} duration={1000} />
                    ) : (
                      el.value
                    )}
                  </span>
                  <span className="mb--2 text-sm"> {el.prepend}</span>
                </div>
              ))}
            </div>
            <i
              className="mr--2 mb--3 fas fa-info-circle text-light text-right flex-grow-1"
              id={`Tooltip-${id}`}
            />
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`Tooltip-${id}`}
              toggle={toggle}
              color="secondary"
            >
              <span className="text-gray-dark">{description}</span>
            </Tooltip>
          </div>
        </CardBody>
        <div className={`bg-gradient-${iconColor} card-background mt--2`} />
      </Card>
    </Col>
  );
};

export default memo(CustomCardWidget);
