/* eslint-disable react/jsx-closing-tag-location */
import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';
import { numberBrFormatter } from '../../../../../../utils/utils';

const FacebookInsightsHeader = ({ facebookInsights }) => {
  return (
    <Row className="px-lg-4 text-center">
      <Col>
        <small className="font-weight-bold text-gray">CPM</small>
        <div className="mt-2">
          <span className="text-gray mr-1">R$ </span>
          <span className="h1 font-weight-bold">
            {numberBrFormatter(facebookInsights?.cpm || 0)}
          </span>
        </div>
      </Col>
      <Col>
        <small className="font-weight-bold text-gray">CTR</small>
        <div className="mt-2">
          <span className="text-gray mr-1">R$ </span>
          <span className="h1 font-weight-bold">{`${facebookInsights?.ctr.toFixed(1) || 0}%`}</span>
        </div>
      </Col>
      <Col>
        <small className="font-weight-bold text-gray">CPC</small>
        <div className="mt-2">
          <span className="text-gray mr-1">R$ </span>
          <span className="h1 font-weight-bold">
            {numberBrFormatter(facebookInsights?.cpc || 0)}
          </span>
        </div>
      </Col>
      <Col>
        <small className="font-weight-bold text-gray">CPA</small>
        <div className="mt-2">
          <span className="text-gray mr-1">R$ </span>
          <span className="h1 font-weight-bold">
            {numberBrFormatter(facebookInsights?.costPerConversion || 0)}
          </span>
        </div>
      </Col>
      <Col>
        <small className="font-weight-bold text-gray">ROI</small>
        <div className="mt-2">
          <span className="h1 font-weight-bold">{`${facebookInsights?.roas.toFixed(1) ||
            0}x`}</span>
        </div>
      </Col>
    </Row>
  );
};

export default memo(FacebookInsightsHeader);
