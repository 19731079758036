import React, { useState } from 'react';
import { Card, Col, CardBody, Tooltip } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { numberBrFormatter } from '../../../../../utils/utils';

const TopProductCard = ({ product, color }) => {
  const [tooltip, setTooltip] = useState(false);
  const { platform, store } = useParams();

  const toggle = id => {
    setTooltip({ open: !tooltip.open, id });
  };

  return (
    <Col lg={4} className="mt-3 mt-lg-0">
      <Link
        className="text-default"
        to={`/${platform}/${store}/dashboard/product-details/${product.slug}/product-details`}
      >
        <Card className="shadow shadow-lg--hover top-cards">
          <CardBody className="d-flex flex-column align-items-center p-3">
            <span className="avatar avatar-lg-2x rounded-circle mb-1">
              <img alt="user-icon" src={product.image} />
            </span>
            <div className="text-truncate mb-2" style={{ maxWidth: '16rem' }}>
              <span className="mb-0" id={`Tooltip-${product.id}`}>
                {product.title}
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltip.open && tooltip.id === product.id}
                target={`Tooltip-${product.id}`}
                toggle={() => toggle(product.id)}
                color="secondary"
              >
                <span className="text-gray-dark">{product.title}</span>
              </Tooltip>
            </div>
            <div>
              <span>R$ </span>
              <span className="display-3 mb-0">{numberBrFormatter(product.total_revenue)}</span>
            </div>
            <p className="text-muted text-sm">Faturamento</p>
            <div className="pb-2">
              <span className="font-weight-bold">{product.total_orders} </span>
              <span className="text-muted">pedidos</span>
            </div>
          </CardBody>
          <div className={`bg-gradient-${color} card-background mt--2`} />
        </Card>
      </Link>
    </Col>
  );
};

export default TopProductCard;
