import React, { memo } from 'react';
import {
  CardHeader,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';

const FacebookInsightsTableHeader = ({ handleSearch, handleSyncCampaigns }) => {
  return (
    <CardHeader className="bg-white border-0">
      <Row className="align-items-center">
        <Col lg={8}>
          <h2>Campanhas</h2>

          <Button
            color="primary"
            className="btn-icon btn-2 text-center mt-1"
            onClick={handleSyncCampaigns}
          >
            <span className="btn-inner--text">Sincronizar Campanhas</span>
            <span className="btn-inner--icon">
              <i className="fa fa-refresh" />
            </span>
          </Button>
        </Col>
        <Col>
          <Row>
            <Col>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Nome da campanha"
                  type="text"
                  name="Search"
                  onChange={handleSearch}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardHeader>
  );
};
export default memo(FacebookInsightsTableHeader);
