import React, { useContext, memo } from 'react';
import { Button, Table } from 'reactstrap';
import FacebookFormContext from '../../../../providers/FacebookFormContext';

const ProfilesTableBody = () => {
  const { handleFacebookLogout, profiles } = useContext(FacebookFormContext);

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">
            <span className="facebook-form-table-title">Nome </span>
          </th>
          <th scope="col" width="40px">
            <span className="facebook-form-table-title">Id da Conta</span>
          </th>
          <th scope="col" className="text-right">
            <span className="facebook-form-table-title"> Ações </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {profiles.map(profile => (
          <tr key={profile.id}>
            <td>
              <span className="facebook-form-table-title">{profile.name}</span>
            </td>
            <td>
              <span className="facebook-form-table-title">{profile.id}</span>
            </td>
            <td width="20%">
              <Button color="facebook" size="md" onClick={() => handleFacebookLogout(profile.id)}>
                <span className="btn-inner--icon pr-2">
                  <i className="fab fa-facebook-f" />
                </span>
                <span className="btn-inner--text">Desconectar</span>
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(ProfilesTableBody);
