import React from 'react';
import {
  CardHeader,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import Filter from './Filter/Filter';

const OrdersTableHeader = ({ handleFilter }) => {
  return (
    <>
      <CardHeader className="border-0">
        <Row className="d-flex justify-content-between p-2">
          <Col>
            <h3 className="mb-0">Pedidos</h3>
            <p className="text-muted text-sm mb-0">
              Veja seu lucro por pedidos (não inclui o marketing).
            </p>
          </Col>
          <Col sm="12" md="4" className="mt-2 mt-md-0 d-flex align-items-center">
            <Filter handleFilter={handleFilter} />
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Buscar Pedido"
                type="text"
                name="Search"
                onChange={e => handleFilter({ name: e.target.value || null })}
              />
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

export default OrdersTableHeader;
