import React, { useCallback, memo } from 'react';
import { Alert, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { api } from '../../../services/api';

const ShopifyWarnApp = ({ store, webhooks }) => {
  const { platform } = useParams();

  const handleClick = useCallback(async () => {
    const response = await api('urgot-shopify-api').post(
      `/api/v1/users/shopify/stores/?shop=${store?.shopifyDomain}`,
    );

    window.open(response.data.url, '_self');
  }, [store]);

  return (
    <>
      {platform === 'shopify' && _.isEmpty(webhooks) && (
        <Alert color="info" className="d-flex align-items-center">
          <span>
            <i className="fa fa-exclamation-triangle" /> Você precisa atualizar o nosso app na{' '}
            <strong> Shopify </strong>
            para que continue funcionando corretamente.
            <Button className="btn-link border-0 text-white mb-1" onClick={handleClick}>
              Clique aqui
            </Button>
          </span>
        </Alert>
      )}
    </>
  );
};

export default memo(ShopifyWarnApp);
