import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const FacebookWarn = () => (
  <Alert color="info" className="d-flex align-items-center">
    <span>
      <i className="fa fa-exclamation-triangle" /> Selecione uma conta do <strong>Facebook.</strong>{' '}
      Para sincronizar os custos de ADS. Para integrar{' '}
      <Link className="alert-link" to="integrations/facebook">
        Clique aqui
      </Link>
    </span>
  </Alert>
);

export default FacebookWarn;
