import React, { useState, useContext, memo, useCallback } from 'react';
import useForm from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import * as yup from 'yup';
import {
  Modal,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import CustomSwitch from '../../../../../components/CustomSwitch/CustomSwitch';
import FirstTimeContext from '../../../../../providers/FirstTimeContext';
import { api, shopsApi } from '../../../../../services/api';
import { useToast } from '../../../../../providers/ToastContext';

const smallDevice = window.matchMedia('(max-width: 727px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

const CustomSpendsModal = ({
  toggle,
  isOpen,
  customSpend,
  title,
  customSpends,
  setCustomSpends,
}) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const [date, setDate] = useState(moment(customSpend.startDate));
  const [focusedInput, setFocusedInput] = useState(false);
  const [checked, setCheck] = useState(customSpend.active);
  const value = useContext(FirstTimeContext);

  const schema = yup.object().shape({
    description: yup
      .string()
      .min(1, 'Precisa ter no mínimo 1 caracter')
      .max(60, 'Pode ter no máximo 60 caracters')
      .required('Este campo é requirido'),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    validationSchema: schema,
  });

  const handleCheck = useCallback(() => setCheck(!checked), [checked]);

  const onSubmit = useCallback(
    async data => {
      const parsedData = {
        ...data,
        amount: Number(data.amount.replace(/,/g, '.')),
        startDate: date.format('YYYY-MM-DD'),
        id: customSpend.id,
        active: checked,
      };

      const requestType = parsedData.id ? 'put' : 'post';
      const response = await api(shopsApi[platform])[requestType](
        `/api/v1/users/${platform}/stores/${store}/custom-spends/${parsedData?.id || ''}`,
        parsedData,
      );

      showToast({ message: 'Custo adicional salvo com sucesso!', type: 'success' });

      const filtered = customSpends.filter(spend => spend.id !== parsedData.id);
      const newCustomSpend = response.data.customSpend;
      setCustomSpends([...filtered, newCustomSpend]);
      toggle();
    },
    [
      checked,
      customSpend.id,
      customSpends,
      date,
      platform,
      setCustomSpends,
      showToast,
      store,
      toggle,
    ],
  );

  const onDateChange = useCallback(date => setDate(date), []);

  return (
    <Modal isOpen={isOpen} fade toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <Card className="bg-secondary shadow border-0 ">
        <CardBody>
          <Form role="form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Tipo</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <StrapInput
                      type="select"
                      name="type"
                      id="type"
                      defaultValue={customSpend.type}
                      innerRef={register}
                    >
                      <option value="OUT">Saída</option>
                      <option value="IN">Entrada</option>
                    </StrapInput>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <legend className="form-control-label">
                    <p className="mb-0 ">Moeda</p>
                  </legend>

                  <InputGroup className="input-group-alternative">
                    <StrapInput
                      type="select"
                      name="currency"
                      id="currency"
                      defaultValue={customSpend.currency}
                      innerRef={register}
                    >
                      <option value="BRL">Real</option>
                      <option value="USD">Dólar</option>
                    </StrapInput>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <legend className="form-control-label">
                    <p className="mb-0 ">Status</p>
                  </legend>
                  <CustomSwitch title="Status" onClick={handleCheck} id="active" status={checked} />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <legend className="text-lead">
                <p>Descrição</p>
              </legend>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-align-center" />
                  </InputGroupText>
                </InputGroupAddon>
                <StrapInput
                  className="form-control-alternative"
                  placeholder="Descrição"
                  defaultValue={customSpend.description}
                  type="text"
                  name="description"
                  innerRef={register}
                />
              </InputGroup>
              {errors.description && (
                <small className="text-danger">A descrição deve ter no maxímo 50 caracteres.</small>
              )}
            </FormGroup>

            <Row>
              <Col lg={4}>
                <FormGroup className={errors.lastName ? 'has-danger ' : ''}>
                  <legend className="text-lead">
                    <p className="mb-0">Valor</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>$</InputGroupText>
                    </InputGroupAddon>
                    <StrapInput
                      name="amount"
                      placeholder="Valor"
                      defaultValue={customSpend.amount}
                      id={customSpend.id}
                      innerRef={register}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Começa em:</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                      <SingleDatePicker
                        date={date}
                        onDateChange={onDateChange}
                        initialVisibleMonth={() => moment()}
                        focused={focusedInput.focused}
                        onFocusChange={setFocusedInput}
                        isOutsideRange={date => {
                          return date.isBefore(moment(value.storeCreatedAt), 'day');
                        }}
                        displayFormat="DD/MM/YYYY"
                        orientation={orientation}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Frequência</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <StrapInput
                      type="select"
                      name="period"
                      id="period"
                      defaultValue={customSpend.period}
                      innerRef={register}
                    >
                      <option value="NONE">Uma vez</option>
                      <option value="DAILY">Diária</option>
                      <option value="WEEKLY">Semanal</option>
                      <option value="MONTHLY">Mensal</option>
                      <option value="ANNUALLY">Anual</option>
                    </StrapInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <div className="text-right">
              <Button className="btn-icon btn-2" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text">Salvar</span>
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default memo(CustomSpendsModal);
