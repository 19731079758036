import React, { useEffect, useState, memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
} from 'reactstrap';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { api, shopsApi } from '../../../../../../services/api';
import { useToast } from '../../../../../../providers/ToastContext';

const VariantsKits = ({ toggle, isOpen, variant, currency, productSlug }) => {
  const [kits, setKits] = useState([]);
  const [cost, setCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const { platform, store } = useParams();
  const { showToast } = useToast();

  const loadKits = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await api(shopsApi[platform]).get(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/variants/${variant.id}/variants-kit`,
      );

      setKits(data.variantsKit);
    } finally {
      setLoadingData(false);
    }
  }, [platform, productSlug, store, variant.id]);

  const handleAddKit = async () => {
    try {
      setLoading(true);
      const parsedPrice = Number(cost.replace(/,/g, '.'));

      const { data } = await api(shopsApi[platform]).post(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/variants/${variant.id}/variants-kit`,
        {
          cost: parsedPrice,
          quantity,
        },
      );

      showToast({ message: 'Kit adicionado com sucesso!', type: 'success' });
      setKits([...kits, data.variantKit]);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteKit = async id => {
    try {
      setLoading(true);
      await api(shopsApi[platform]).delete(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/variants/${variant.id}/variants-kit/${id}`,
      );

      showToast({ message: 'Kit excluído com sucesso!', type: 'success' });
      setKits(kits.filter(kit => kit.id !== id));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadKits();
  }, [loadKits]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} fade>
      <ModalHeader toggle={toggle}>Aqui você pode informar quanto paga em seus Kits!</ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <InputGroup className="input-group-alternative">
            <Input
              name="quantity"
              placeholder="Qtd. Unidades"
              onChange={e => setQuantity(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="input-group-alternative ml-3">
            <InputGroupAddon addonType="prepend">
              {currency === 'BRL' ? 'R$' : 'U$'}
            </InputGroupAddon>
            <Input name="cost" placeholder="Custo" onChange={e => setCost(e.target.value)} />
          </InputGroup>
          <Button
            className="btn btn-inner--text btn-inner--icon ml-3"
            color="primary"
            disabled={loading}
            onClick={handleAddKit}
          >
            {loading ? '...' : <i className="fa fa-check" />}
          </Button>
        </div>
        <hr className="mt-4" />

        {loadingData ? (
          <Spinner loading={loadingData} />
        ) : (
          kits.map(kit => (
            <div key={kit.id}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="font-weight-bold">Quantidade: </span>
                  <span>{kit.quantity}</span>
                </div>
                <div>
                  <span className="font-weight-bold">Custo: </span>
                  <span>
                    {currency === 'BRL' ? 'R$' : 'U$'}
                    {kit.cost}
                  </span>
                </div>

                <Button
                  className="btn btn-inner--text btn-inner--icon ml-3"
                  disabled={loading}
                  onClick={() => handleDeleteKit(kit.id)}
                >
                  {loading ? '...' : <i className="fa fa-remove" />}
                </Button>
              </div>
              <hr className="my-3" />
            </div>
          ))
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(VariantsKits);
