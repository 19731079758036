import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';
import ProductInfo from './ProductInfo/ProductInfo';
import CardProfit from './CardProfit/CardProfit';
import { numberBrFormatter } from '../../../../utils/utils';
import CardDetails from './CardDetails/CardDetails';
import CardWidget from '../../../../components/CardWidget/CardWidget';

const ProductDetailsCardGroup = ({ productDetails }) => {
  const parsedCardWidget = {
    revenue: {
      id: 12,
      title: 'Faturamento',
      iconColor: 'yellow',
      icon: 'fas fa-chart-bar',
      value: productDetails.profit.revenue,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Valor total de pedidos aprovados',
      ...productDetails.profit.revenue,
    },
    cogs: {
      id: 13,
      title: 'Custo dos produtos',
      iconColor: 'orange',
      icon: 'fas fa-shopping-cart',
      value: productDetails.profit.cogs,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Custo total dos produtos para os pedidos aprovados',
      ...productDetails.profit.cogs,
    },
    marketing: {
      id: 14,
      title: 'Marketing',
      iconColor: 'purple',
      icon: 'fas fa-users',
      value: productDetails.profit.marketing,
      formatter: numberBrFormatter,
      animated: true,
      description: 'Custo total gasto com marketing',
      ...productDetails.profit.marketing,
    },
    profit: {
      id: 15,
      title: 'Lucro Liquído',
      iconColor: 'success',
      icon: 'fas fa-dollar-sign',
      ...productDetails.profit.profit,
      value: productDetails.profit.profit,
      formatter: numberBrFormatter,
      animated: true,
      description:
        'Lucro líquido = Faturamento - Custo Dos Produtos - Marketing - Taxas do Gateway - Valores Adicionais',
    },
  };

  return (
    <>
      <Row>
        <Col lg={3}>
          <ProductInfo product={productDetails.product} />
        </Col>
        <Col>
          <Row className="mt-lg-0 mt-4">
            <Col md={12} lg={4} className="pl-md-0">
              <CardProfit
                value={numberBrFormatter(productDetails.profit.cpa)}
                title="Custo por Aquisição (CPA)"
                prepend="R$"
              />
            </Col>
            <Col md={12} lg={4} className="pl-md-0 pt-2 pt-lg-0">
              <CardProfit
                title="Margem de Lucro"
                value={`${productDetails.profit.profitMargin.toFixed(0)}%`}
              />
            </Col>
            <Col md={12} lg={4} className="pl-md-0 pt-2 pt-lg-0">
              <CardProfit
                value={`${productDetails.profit.billetConversion.toFixed(0)}%`}
                title="% Conversão de Boleto"
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12} lg={4} className="pl-md-0 pt-2 pt-lg-0">
              <CardProfit title="Total de Pedidos" value={productDetails.profit.totalOrders} />
            </Col>
            <Col md={12} lg={4} className="pl-md-0 pt-2 pt-lg-0">
              <CardProfit
                value={productDetails.profit.totalOrdersApproved}
                title="Total de vendas aprovadas"
              />
            </Col>
            <Col md={12} lg={4} className="pl-md-0 pt-2 pt-lg-0">
              <CardProfit
                value={numberBrFormatter(productDetails.profit.waitOrders)}
                title="Pedidos Pendentes"
                prepend="R$"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} lg={3}>
          <CardDetails
            values={[
              {
                title: 'Boletos Gerados',
                value: productDetails.profit.totalBillets,
                color: 'orange',
              },
              {
                title: 'Boletos Pagos',
                value: productDetails.profit.convertedBillet,
                color: 'success',
              },
            ]}
          />
        </Col>
        <Col md={6} lg={3} className="pl-md-0 mt-md-0 mt-2">
          <CardDetails
            values={[
              {
                title: 'Cartões Aprovados',
                value: productDetails.profit.totalCardsApproved,
                color: 'success',
              },
              {
                title: 'Cartões Rejeitados',
                value: productDetails.profit.totalCardsRejected,
                color: 'danger',
              },
            ]}
          />
        </Col>
        <Col md={6} lg={3} className="pl-md-0 mt-md-0 mt-2">
          <CardDetails
            values={[
              {
                title: 'Pedidos devolvidos',
                value: productDetails.profit.totalOrdersRefunded,
                color: 'danger',
              },
              {
                title: 'Pedidos Estornado',
                value: productDetails.profit.totalOrdersChargedBack,
                color: 'danger',
              },
            ]}
          />
        </Col>
        <Col md={6} lg={3} className="pl-md-0 mt-md-0 mt-2">
          <CardDetails
            values={[
              {
                title: 'Taxa de Gateway',
                value: `R$ ${numberBrFormatter(productDetails.profit.gatewayFee)}`,
                color: 'danger',
              },
              {
                title: 'Taxa de Checkout',
                value: `R$ ${numberBrFormatter(productDetails.profit.checkoutFee)}`,
                color: 'danger',
              },
            ]}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <CardWidget data={parsedCardWidget.revenue} xl="3" lg="6" prepend="R$" />
        <CardWidget data={parsedCardWidget.cogs} xl="3" lg="6" prepend="R$" />
        <CardWidget data={parsedCardWidget.marketing} xl="3" lg="6" prepend="R$" />
        <CardWidget data={parsedCardWidget.profit} xl="3" lg="6" prepend="R$" />
      </Row>
    </>
  );
};

export default memo(ProductDetailsCardGroup);
