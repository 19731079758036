import React, { useEffect, useState, useCallback, useContext, useMemo, memo } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { PacmanLoader } from 'react-spinners';
import { Link, useRouteMatch } from 'react-router-dom';
import StoreCard from './Card/Card';
import StoreNewCard from './NewCard/NewCard';
import Footer from '../../components/Footer/Footer';
import { api } from '../../services/api';
import UserContext from '../../providers/UserContext';
import StoresNavbar from './Header/Header';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Profile from '../../components/Profile/Profile';

const Stores = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const { value: user } = useContext(UserContext);
  const match = useRouteMatch({
    path: '/stores/user-profile',
    strict: true,
    sensitive: true,
  });

  const loadStore = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api('user-api').get(`/api/v1/users/stores`);
      setStores(response?.data?.stores);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadStore();
  }, [loadStore]);

  const onOpenStore = useCallback(
    ({ storeSlug, storePlatform }) => {
      history.push(`/${storePlatform}/${storeSlug}/dashboard/shop-analytics`);
    },
    [history],
  );

  const renderSpinner = useMemo(
    () => (
      <div className="spinner-center">
        <PacmanLoader sizeUnit="px" size={30} color="#7dd56f" loading={loading} />
      </div>
    ),
    [loading],
  );

  return (
    <>
      {loading ? (
        renderSpinner
      ) : (
        <>
          <div className="main-content">
            <div className="header py-5 py-lg-4 vh-90">
              <StoresNavbar />
              {match ? (
                <PrivateRoute
                  path="/stores/user-profile"
                  component={props => <Profile {...props} fluid={false} />}
                />
              ) : (
                <Container className="mt-6">
                  <div className="text-center mb-7">
                    {user?.role === 'ADMIN' && (
                      <Row className="justify-content-center my-5">
                        <Link to="/admin/dashboard">
                          <Button
                            className="btn text-center btn-inner--text btn-lg"
                            color="primary"
                          >
                            Ir para admin
                          </Button>
                        </Link>
                      </Row>
                    )}
                    <Row className="justify-content-center">
                      <Col lg="5" md="6">
                        <p className="text-lead heading-title">Qual loja deseja analisar?</p>
                        <p className="text-lead">Veja seu lucro em tempo real.</p>
                      </Col>
                    </Row>
                    <Row className="py-5 py-lg-4">
                      {stores.map(store => (
                        <StoreCard
                          key={store.domain}
                          storeName={store.name}
                          storeImage={store.image}
                          storeDomain={store.domain}
                          storeSlug={store.slug}
                          storePlatform={store.platform}
                          onOpenStore={onOpenStore}
                        />
                      ))}
                      <StoreNewCard loadStore={loadStore} />
                    </Row>
                  </div>
                </Container>
              )}
            </div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default memo(Stores);
