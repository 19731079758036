import MercadoPagoWarn from '../../components/Warns/MercadoPago/MercadoPago';
import AppmaxWarn from '../../components/Warns/Appmax/Appmax';
import CieloWarn from '../../components/Warns/Cielo/Cielo';
import FacebookWarn from '../../components/Warns/Facebook/Facebook';
import GatewayWarn from '../../components/Warns/Gateway/Gateway';
import CloudFoxWarn from '../../components/Warns/Cloudfox/Cloudfox';
import ShopifyApp from '../../components/Warns/ShopifyApp/ShopifyApp';
import PagseguroWarn from '../../components/Warns/Pagseguro/Pagseguro';

const getWarnAlerts = warns => {
  if (!warns) return [];

  const componentsWithWarn = [];

  componentsWithWarn.push(ShopifyApp);

  if (warns.mercadoPagoCredential) {
    componentsWithWarn.push(MercadoPagoWarn);
  }

  if (warns.appMaxCredential) {
    componentsWithWarn.push(AppmaxWarn);
  }

  if (warns.cieloCredential) {
    componentsWithWarn.push(CieloWarn);
  }

  if (warns.cloudFoxCredential) {
    componentsWithWarn.push(CloudFoxWarn);
  }

  if (warns.pagSeguroCredential) {
    componentsWithWarn.push(PagseguroWarn);
  }

  if (warns.facebookCredential) {
    componentsWithWarn.push(FacebookWarn);
  }

  if (warns.noGatewayCredential) {
    componentsWithWarn.push(GatewayWarn);
  }

  return componentsWithWarn;
};

export default getWarnAlerts;
