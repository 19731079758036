import React, { useState, memo } from 'react';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
  ModalBody,
  ModalHeader,
  Label,
} from 'reactstrap';
import { api } from '../../../../services/api';

const Cartx = ({ setModal, loadStore }) => {
  const [storeName, setStoreName] = useState('');
  const [token, setToken] = useState();

  const onAddNewStore = async () => {
    await api('ornn-cartx-api').post(`/api/v1/users/cartx/stores`, { token, shop: storeName });
    await loadStore();
    setModal(false);
  };

  return (
    <>
      <ModalHeader className="teal h1">Digite o Nome da sua loja</ModalHeader>
      <ModalBody>
        <Label className="form-control-label" htmlFor="input-cartx-store">
          Nome da Loja
        </Label>
        <InputGroup className="input-group-alternative">
          <Input bsSize="lg" onChange={event => setStoreName(event.target.value)} />
          <InputGroupAddon addonType="append">
            <InputGroupText>.oncartx.io</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <div className="mt-4">
          <Label className="form-control-label" htmlFor="input-cartx-token">
            Token
          </Label>
          <InputGroup className="input-group-alternative">
            <Input
              className="input-group-alternative"
              bsSize="lg"
              name="input-cartx-token"
              onChange={event => setToken(event.target.value)}
            />
          </InputGroup>
        </div>
        <Button color="primary" className="btn-icon btn-2 mt-4" onClick={onAddNewStore}>
          <span className="btn-inner--text">Cadastrar</span>
          <span className="btn-inner--icon">
            <i className="ni ni-send" />
          </span>
        </Button>
      </ModalBody>
    </>
  );
};

export default memo(Cartx);
