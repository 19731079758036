import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import PieChart from '../../../components/Charts/PieChart';
import { api, shopsApi } from '../../../services/api';
import LineChart from './LineChart/LineChart';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import CardArea from './CardArea/CardArea';
import Spinner from '../../../components/Spinner/Spinner';
import NextFeatures from './NextFeatures/NextFeatures';
import LineChartContext from '../../../providers/LineChartContext';
import './ShopAnalytics.css';

const ShopAnalytics = () => {
  const [storeData, setStoreData] = useState(null);
  const [storePeriod, setStorePeriod] = useState({ startDate: moment(), endDate: moment() });
  const [loading, setLoading] = useState(false);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartPeriod, setLineChartPeriod] = useState(6);
  const [webhooks, setWebhooks] = useState([]);
  const { store, platform } = useParams();

  const value = useMemo(() => ({ lineChartData, lineChartPeriod }), [
    lineChartData,
    lineChartPeriod,
  ]);

  const loadStore = useCallback(
    async ({ spinner = true }) => {
      const defaultStartDate = storePeriod.startDate;
      const defaultEndDate = storePeriod.endDate;

      try {
        setLoading(spinner);
        const response = await api(shopsApi[platform]).get(
          `/api/v1/users/${platform}/stores/${store}/profit?startDate=${defaultStartDate.format(
            'YYYY-MM-DD',
          )}&endDate=${defaultEndDate.format('YYYY-MM-DD')}`,
        );

        setStoreData(response.data);
      } finally {
        setLoading(false);
      }
    },
    [platform, setStoreData, store, storePeriod],
  );

  const loadChartData = useCallback(async () => {
    const { data } = await api(shopsApi[platform]).get(
      `/api/v1/users/${platform}/stores/${store}/profit/chart/main?period=${lineChartPeriod}`,
    );

    setLineChartData(data.chart);
  }, [platform, store, lineChartPeriod]);

  const loadWebHooks = useCallback(async () => {
    const { data } = await api('urgot-shopify-api').get(
      `/api/v1/users/shopify/stores/${store}/webhooks`,
    );

    setWebhooks(data.webhooks);
  }, [store]);

  useEffect(() => {
    loadStore({ spinner: true });
  }, [loadStore]);

  useEffect(() => {
    loadChartData();
  }, [loadChartData]);

  useEffect(() => {
    if (platform !== 'cartx') loadWebHooks();
  }, [loadWebHooks, platform]);

  const renderContent = storeData && (
    <>
      <LineChartContext.Provider value={{ value, setLineChartPeriod }}>
        <DashboardHeader
          setStorePeriod={setStorePeriod}
          storePeriod={storePeriod}
          storeData={storeData}
          webhooks={webhooks}
          loadChartData={loadChartData}
          loadStore={loadStore}
        />
        <Container fluid className="mt-4">
          <Row>
            <Col xl="8">
              <LineChart />
            </Col>
            <Col xl="4">
              <NextFeatures />
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <CardArea data={storeData} />
            </Col>
            <Col xl="4">
              <PieChart data={storeData.profit} />
            </Col>
          </Row>
        </Container>
      </LineChartContext.Provider>
    </>
  );

  if (loading) {
    return <Spinner />;
  }

  return renderContent;
};

export default ShopAnalytics;
