import React, { memo } from 'react';
import { Card } from 'reactstrap';

const ProductDetailsCardProfit = ({ value, title, prepend }) => {
  return (
    <>
      <Card className="shadow">
        <div className="bg-gradient-primary card-line-top mb--1" />
        <div className="py-2 px-4">
          <small className="text-gray font-weight-bold">{title}</small>
          <div className="py-4">
            <span className="text-gray">{prepend} </span>
            <span className="h1 font-weight-normal">{value}</span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default memo(ProductDetailsCardProfit);
