import React, { useState, useCallback } from 'react';
import { Button } from 'reactstrap';
import ImportCSVModal from './Modal/Modal';

const ImportCSV = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <>
      <ImportCSVModal toggle={toggle} open={isOpen} />
      <Button className="text-right btn-inner--icon py-2" color="primary" onClick={toggle}>
        Editar via planilha
        <i className="fa fa-file-import ml-2" />
      </Button>
    </>
  );
};

export default ImportCSV;
