import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  CardHeader,
  // Input
} from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { parseLineChartData } from '../../../../utils/utils';
import LineChartContext from '../../../../providers/LineChartContext';
import './LineChart.scss';

const LineChart = () => {
  const {
    value: data,
    //  setLineChartPeriod
  } = useContext(LineChartContext);

  const {
    revenue,
    // marketing,
    profit,
    cogs,
  } = parseLineChartData(data.lineChartData);

  const chartConfig = {
    fill: true,
    lineTension: 0.1,
    borderCapStyle: 'square',
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderWidth: 1,
    pointRadius: 5,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1,
    spanGaps: true,
  };

  const chartData = {
    labels: [revenue && revenue[0], revenue && revenue[revenue?.length - 1]?.t],
    datasets: [
      {
        ...chartConfig,
        backgroundColor: 'rgba(225,214,0,0.4)',
        borderColor: '#ffd600',
        label: 'Faturamento',
        data: revenue,
      },
      {
        ...chartConfig,
        label: 'Custo dos Produtos',
        backgroundColor: 'rgba(251,99,64,0.4)',
        borderColor: '#fb6340',
        data: cogs,
      },
      // {
      //   ...chartConfig,
      //   label: 'Marketing',
      //   backgroundColor: 'rgba(137,101,224,0.4)',
      //   borderColor: '#8965e0',
      //   data: marketing,
      // },
      {
        ...chartConfig,
        label: 'Lucro Líquido',
        backgroundColor: 'rgba(40,207,135,0.4)',
        borderColor: '#28cf87',
        data: profit,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
          },
        },
      ],
    },
  };

  return (
    <Card className="bg-gradient-secundary shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Gráfico de performance</h3>
            <p className="text-muted text-sm mb-0">Veja como está sua evolução.</p>
          </div>
          {/* <div className="mr-md-3">
            <Input
              className="form-control-alternative"
              type="select"
              name="period"
              id="period"
              defaultValue={data.lineChartPeriod}
              onChange={e => setLineChartPeriod(e.target.value)}
            >
              <option value="6">7 dias</option>
              <option value="14">15 dias</option>
              <option value="29">30 dias</option>
            </Input>
          </div> */}
        </Row>
      </CardHeader>
      <CardBody className="p-0 p-md-4">
        <div className="wrapper-chart">
          <Line data={chartData} options={options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default LineChart;
