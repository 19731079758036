import React, { useState, useCallback } from 'react';
import { Row } from 'reactstrap';
import NewUserFluxLineItem from '../LineItem/LineItem';
import MercadoPagoForm from '../../../components/MercadoPagoForm/MercadoPagoForm';
import VideoTutorial from '../VideoTutorial/VideoTutorial';
import CieloForm from '../../../components/CieloForm/CieloForm';
import AppmaxForm from '../../../components/AppmaxForm/AppmaxForm';
import CloudfoxForm from '../../../components/CloudfoxForm/CloudfoxForm';
import PagseguroForm from '../../../components/PagseguroForm/PagseguroForm';
import usePersistedState from '../../../utils/usePersistedState';
import mercadoPago from '../../../assets/img/theme/mercadopago.png';
import cielo from '../../../assets/img/theme/cielo.png';
import appmax from '../../../assets/img/theme/appmax.png';
import cloudfox from '../../../assets/img/theme/cloudfox.png';
import pagseguro from '../../../assets/img/theme/pagseguro.png';
import './Payments.scss';

const NewUserFluxPayments = () => {
  const [component, setComponent] = useState(() => {});
  const [isConnected, setIsConnected] = usePersistedState('gateway', {});

  const handleSetGatewayIsConnected = useCallback(
    gateway => {
      setComponent(null);
      setIsConnected(state => ({ ...state, [gateway]: true }));
    },

    [setIsConnected],
  );

  const handleConnect = useCallback(
    event => {
      const { id } = event.target;

      const component = {
        mercadoPago: (
          <>
            <MercadoPagoForm fluid={false} onSave={handleSetGatewayIsConnected} className="mt-0" />
            <VideoTutorial src="https://profitfy-media.s3-us-west-2.amazonaws.com/videos-tuto/mercado-pago.webm" />
          </>
        ),
        cielo: (
          <>
            <CieloForm onSave={handleSetGatewayIsConnected} className="mt-0" />
          </>
        ),
        appmax: (
          <>
            <AppmaxForm onSave={handleSetGatewayIsConnected} className="mt-0" />
          </>
        ),
        cloudfox: (
          <>
            <CloudfoxForm onSave={handleSetGatewayIsConnected} className="mt-0" />
          </>
        ),
        pagseguro: (
          <>
            <PagseguroForm onSave={handleSetGatewayIsConnected} className="mt-0" />
          </>
        ),
      }[id];

      setComponent(component);
    },
    [handleSetGatewayIsConnected],
  );

  return (
    <>
      {component || (
        <div className="p-4">
          <h3 className="mb-0">Conecte-se a um Gateway de Pagamento</h3>
          <p className="text-muted text-sm mb-0">
            Isso é importante para que nós conseguirmos obter a informação do valor líquido recebido
            no seu gateway de pagamento, contando com taxas e parcelamentos, e assim realizar suas
            análises de lucro.
          </p>
          <hr />
          <Row className="align-items-center">
            <NewUserFluxLineItem
              title="Mercado Pago"
              logo={mercadoPago}
              handleConnect={handleConnect}
              id="mercadoPago"
              isConnected={isConnected?.mercadoPago}
            />
            <NewUserFluxLineItem
              title="Cielo"
              logo={cielo}
              handleConnect={handleConnect}
              id="cielo"
              isConnected={isConnected?.cielo}
            />
            <NewUserFluxLineItem
              title="Appmax"
              logo={appmax}
              handleConnect={handleConnect}
              id="appmax"
              isConnected={isConnected?.appmax}
            />
            <NewUserFluxLineItem
              title="CloudFox"
              logo={cloudfox}
              handleConnect={handleConnect}
              id="cloudfox"
              connected={isConnected.cloudfox}
            />
          </Row>
          <Row className="mt-4">
            <NewUserFluxLineItem
              title="Pagseguro"
              logo={pagseguro}
              handleConnect={handleConnect}
              id="pagseguro"
              connected={isConnected.pagseguro}
            />
          </Row>
        </div>
      )}
    </>
  );
};

export default NewUserFluxPayments;
