import React, { useCallback } from 'react';
import { Media, Card, Button } from 'reactstrap';

const ProductDetailsProductInfo = ({ product }) => {
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(product.id);
  }, [product.id]);

  return (
    <Card className="shadow">
      <div className="bg-gradient-primary card-line-top mb--1" />
      <div className="d-flex flex-column align-items-center py-4 px-3">
        <Media className="mt-2">
          <span className="avatar avatar-lg-3x rounded-circle">
            <img alt="product" src={product.image} />
          </span>
        </Media>
        <p className="pt-4 mb-2 font-weight-bold">{product.title}</p>
        <small>
          <b>ID:</b> {product.id}
          <Button className="btn-link text-default mb-1 px-2" onClick={handleCopyToClipboard}>
            <i className="far fa-copy " />
          </Button>
        </small>
      </div>
    </Card>
  );
};

export default ProductDetailsProductInfo;
