import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Intercom from 'react-intercom';
import { BrowserRouter } from 'react-router-dom';
import { api } from './services/api';
import { isAuthenticated } from './services/auth';
import { Routes } from './routes/routes';
import UserContext from './providers/UserContext';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import './App.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-phone-input-2/lib/style.css';
import './assets/scss/react-phone-overrides.scss';
import AppProvider from './providers';

const App = () => {
  const [user, setUser] = useState(null);

  const loadUser = useCallback(async () => {
    const { data } = await api('user-api').get('/api/v1/users/me');
    setUser(data.dashboardUser);
  }, []);

  const value = useMemo(() => user, [user]);

  useEffect(() => {
    if (isAuthenticated()) {
      loadUser();
    }
  }, [loadUser]);

  const userLevels = {
    Lite: 'Nível 1',
    Starter: 'Nível 2',
    Essentials: 'Nível 3',
    Premium: 'Nível 3',
    Pro: 'Nível 4',
    'Pro I': 'Nível 4',
    'Pro II': 'Nível 4',
  };

  const userItercom = {
    user_id: user?.id,
    lead_category: userLevels[user?.plan?.name],
    name: `${user?.firstName} ${user?.lastName}`,
    updated_at: user?.updatedAt,
    signed_up_at: user?.createdAt,
    email: user?.email,
    phone: user?.phone,
    company: {
      company_id: user?.id,
      company_name: user?.companyName,
      plan: user?.subscription?.planName,
      monthly_revenue: user?.monthlyRevenue,
      document: user?.cnpj || user?.cpf,
      payment_status: user?.subscription?.status,
      last_payment_status: user?.subscription?.lastStatus,
      canceled_at: user?.subscription?.canceledAt,
    },
  };

  const storageValue = localStorage.getItem('storeURL');
  if (storageValue) {
    const { store, platform } = JSON.parse(storageValue);
    userItercom.store = store;
    userItercom.platform = platform;
  }

  return (
    <UserContext.Provider value={{ value, loadUser }}>
      <AppProvider>
        <BrowserRouter>
          {user && <Intercom appID="w8h6uigl" {...userItercom} />}

          <ServiceWorkerWrapper />
          <Routes />
        </BrowserRouter>
      </AppProvider>
    </UserContext.Provider>
  );
};

export default App;
