import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment-timezone';
import Spinner from '../../../../components/Spinner/Spinner';
import TopProductCard from './Card/Card';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from './TableFooter/TableFooter';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import usePersistedState from '../../../../utils/usePersistedState';
import { shopsApi, makeSearchRequest } from '../../../../services/api';

const getProductColor = index =>
  ({
    1: 'gold',
    0: 'silver',
    2: 'bronze',
  }[index]);

const reOrderTopProductPosition = topProducts => {
  const [gold, silver, bronze] = topProducts;
  const orderTopProducts = [silver, gold, bronze];

  return orderTopProducts;
};

const TopProducts = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [period, setPeriod] = usePersistedState('topProductsDatePicker', {
    startDate: moment(),
    endDate: moment(),
  });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ field: 'revenue', sort: 'DESC', title: '' });
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const { platform, store } = useParams();
  const rowsPerPage = 10;

  const handleChangePage = page => {
    setPage(page.selected + 1);
  };

  const handleSearch = async event => {
    const { value } = event.target;
    setPage(1);
    setFilter({ ...filter, ...{ title: value } });
  };

  const handleDate = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return;
    if (!startDate.isValid() || !endDate.isValid()) return;
    setPeriod({ startDate, endDate });
  };

  const loadProducts = useCallback(async () => {
    setLoading(true);

    const search = await makeSearchRequest({
      api: shopsApi[platform],
    });

    const response = await search(
      `/api/v1/users/${platform}/stores/${store}/products/orders?${queryString.stringify({
        filter: JSON.stringify(filter),
      })}&startDate=${moment(period.startDate).format('YYYY-MM-DD')}&endDate=${moment(
        period.endDate,
      ).format('YYYY-MM-DD')}&page=${page}&limit=${rowsPerPage}`,
    );

    if (response) {
      const orderedTopProducts = reOrderTopProductPosition(response.topProducts);

      setProducts(response.products);
      setTopProducts(orderedTopProducts);
      setPagesCount(response.numberOfPages);
      setLoading(false);
    }
  }, [platform, store, filter, page, period]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  return (
    <div className="top-products">
      <Row>
        <Col className="mb-5">
          <DatePicker
            startDate={moment(period.startDate)}
            endDate={moment(period.endDate)}
            onDate={handleDate}
            showPreDefinedDates
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="font-weight-light">Produtos mais Vendidos</h1>
          <hr className="mb-5 mt-2" />
        </Col>
      </Row>

      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <Row>
          {topProducts
            .filter(product => product)
            .map((product, index) => (
              <TopProductCard key={product.id} product={product} color={getProductColor(index)} />
            ))}
        </Row>
      )}
      <Row className="mt-5">
        <Col>
          <Card className="shadow">
            <TableHeader handleSearch={handleSearch} />
            {loading ? <Spinner loading={loading} /> : <TableBody products={products} />}
            <TableFooter handleChangePage={handleChangePage} pages={pagesCount} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TopProducts;
