import React, { useState, memo, useEffect, useCallback } from 'react';
import { Table, Input, Button, Media } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import ModalDatePicker from './ModalDatePicker/ModalDatePicker';
import Variants from './Variants/Variants';
import { api, shopsApi } from '../../../../services/api';
import { ReactComponent as IconUS } from '../../../../assets/img/icons/common/us.svg';
import { ReactComponent as IconBR } from '../../../../assets/img/icons/common/brazil.svg';
import './TableBody.scss';

const customStyles = {
  indicatorsContainer: provided => ({
    ...provided,
    marginLeft: '-17px',
    marginRight: '8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    borderWidth: '0px',
    boxShadow: 'none',
    borderColor: 'none',
    marginTop: '3px',
  }),
};

const currencyOptions = [
  { value: 'USD', label: 'U$', icon: <IconUS /> },
  { value: 'BRL', label: 'R$', icon: <IconBR /> },
];

const ProductCostTableBody = ({ products, loadProductsData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [slug, setProductSlug] = useState(null);
  const [currency, setCurrency] = useState();
  const [modalDatePicker, setModalDatePicker] = useState(false);
  const { store, platform } = useParams();

  const parseCurrency = useCallback(() => {
    let productsByCurrency = {};

    for (const product of products) {
      const parsed = { [product.slug]: product.currency };
      productsByCurrency = { ...productsByCurrency, ...parsed };
    }

    setCurrency(productsByCurrency);
  }, [products]);

  useEffect(() => parseCurrency(), [parseCurrency]);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleEditProduct = useCallback(
    slug => {
      setProductSlug(slug);
      toggle();
    },
    [toggle],
  );

  const onSelectIOF = useCallback(
    async (event, { productSlug }) => {
      const { value } = event.target;
      await api(shopsApi[platform]).patch(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/buy-method`,
        {
          buyMethod: value,
        },
      );
    },
    [platform, store],
  );

  const onChangeCurrency = useCallback(
    async ({ currencyData, productSlug }) => {
      await api(shopsApi[platform]).patch(
        `/api/v1/users/${platform}/stores/${store}/products/${productSlug}/currency`,
        {
          currency: currencyData.value,
        },
      );

      setCurrency({ ...currency, ...{ [productSlug]: currencyData.value } });
    },
    [store, platform, currency],
  );

  const customSingleValue = useCallback(
    ({ data }) => (
      <div className="input-select">
        <div className="input-select__single-value">
          {data.icon && <span className="input-select__icon">{data.icon}</span>}
          <span>{data.label}</span>
        </div>
      </div>
    ),
    [],
  );

  const toggleModalDatePicker = useCallback(() => setModalDatePicker(!modalDatePicker), [
    modalDatePicker,
  ]);

  const getDefaultCurrency = useCallback(
    product => currencyOptions.find(({ value }) => value === product.currency),
    [],
  );

  return (
    <>
      <Variants
        products={products}
        productSlug={slug}
        currency={currency}
        loadProductsData={loadProductsData}
        setModalDatePicker={setModalDatePicker}
        open={isOpen}
        toggle={toggle}
      />
      <ModalDatePicker
        open={modalDatePicker}
        toggle={toggleModalDatePicker}
        loadProductsData={loadProductsData}
        productSlug={slug}
      />
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col">Nome</th>
            <th scope="col">Qtd. de Variantes</th>
            <th scope="col" className="text-center">
              Moeda
            </th>
            <th scope="col" className="text-center">
              Taxa de IOF
            </th>
            <th scope="col">Variantes</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td width="8%">
                <Media className="align-items-center">
                  <span className="avatar avatar-lg rounded-circle">
                    <img alt="product" src={product.image} />
                  </span>
                </Media>
              </td>
              <td className="text-truncate" style={{ maxWidth: '300px' }}>
                <span className="mb-0">{product.title}</span>
              </td>
              <td width="10%">
                <p className="mb-0 text-center">{product.Variants.length} </p>
              </td>
              <td width="140px">
                <Select
                  defaultValue={getDefaultCurrency(product)}
                  options={currencyOptions}
                  styles={customStyles}
                  components={{ SingleValue: customSingleValue }}
                  onChange={value =>
                    onChangeCurrency({ currencyData: value, productSlug: product.slug })
                  }
                />
              </td>
              <td width="22%">
                <div>
                  <Input
                    className="input-group-alternative"
                    type="select"
                    name="select"
                    id="selectIOF"
                    data-show-icon="true"
                    onChange={e => onSelectIOF(e, { productSlug: product.slug })}
                    defaultValue={product.buyMethod}
                  >
                    <option value="NONE">Nenhum</option>
                    <option value="CARD">Cartão de Crédito 6,38%</option>
                    <option value="TICKET">Boleto Bancário 0,38%</option>
                  </Input>
                </div>
              </td>
              <td className="pl-0 text-center" width="2%">
                <Button
                  onClick={() => handleEditProduct(product.slug)}
                  className="btn btn-inner--icon"
                >
                  <i className="fas fa-pen" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(ProductCostTableBody);
