import React from 'react';
import useForm from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { api, shopsApi } from '../../../../../services/api';
import { useToast } from '../../../../../providers/ToastContext';

const OrdersTableBodyModal = ({ handleEditOrder, isOpen, title, order, loadOrdersData }) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async data => {
    const parsedData = {
      revenue: Number(data.revenue.replace(/,/g, '.')),
      cogs: Number(data.cogs.replace(/,/g, '.')),
      gatewayFee: Number(data.gatewayFee.replace(/,/g, '.')),
      status: data.status,
      type: data.type,
      approved: order.createdAt,
    };

    await api(shopsApi[platform]).put(
      `/api/v1/users/${platform}/stores/${store}/orders/${order.id}`,
      parsedData,
    );

    showToast({ message: 'Valores alterados com sucesso!', type: 'success' });
    handleEditOrder();
    await loadOrdersData();
  };

  return (
    <Modal isOpen={isOpen} fade toggle={handleEditOrder}>
      <ModalHeader toggle={handleEditOrder}>{title}</ModalHeader>
      <Card className="bg-secondary shadow border-0 ">
        <CardBody>
          <Form role="form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Status</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <StrapInput
                      type="select"
                      name="status"
                      id="status"
                      placeholder={order.status}
                      innerRef={register}
                    >
                      <option value="approved">Aprovado</option>
                      <option value="cancelled">Cancelado</option>
                    </StrapInput>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Tipo</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <StrapInput
                      type="select"
                      name="type"
                      id="type"
                      placeholder={order.type}
                      innerRef={register}
                      defaultValue={order.type}
                    >
                      <option value="credit_card">Cartão De Crédito</option>
                      <option value="ticket">Boleto</option>
                      <option value="account_money">Depósito</option>
                    </StrapInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Faturamento</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="font-weight-bold">R$</InputGroupText>
                    </InputGroupAddon>
                    <StrapInput
                      className="form-control-alternative"
                      type="text"
                      name="revenue"
                      defaultValue={order.revenue}
                      innerRef={register}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Custo do Produto</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="font-weight-bold">R$</InputGroupText>
                    </InputGroupAddon>
                    <StrapInput
                      className="form-control-alternative"
                      type="text"
                      name="cogs"
                      defaultValue={order.cogs}
                      innerRef={register}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <legend className="text-lead">
                    <p className="mb-0">Tax. Gateway</p>
                  </legend>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="font-weight-bold">R$</InputGroupText>
                    </InputGroupAddon>
                    <StrapInput
                      className="form-control-alternative"
                      type="text"
                      name="gatewayFee"
                      defaultValue={order.gatewayFee}
                      innerRef={register}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button className="btn-icon btn-2" color="primary" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text">Editar</span>
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default OrdersTableBodyModal;
