import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useToast } from '../../providers/ToastContext';
import Spinner from '../Spinner/Spinner';
import { api, shopsApi } from '../../services/api';

const MercadoPagoForm = ({ fluid = true, onSave = () => {}, className }) => {
  const { store, platform } = useParams();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ publicKey: '', accessToken: '', id: null });

  const handlePublicKey = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ publicKey: value } });
  };

  const handleAccessToken = e => {
    const { value } = e.target;
    setCredentials({ ...credentials, ...{ accessToken: value } });
  };

  const onSubmit = async () => {
    const requestType = credentials.id ? 'put' : 'post';

    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform])[requestType](
        `api/v1/users/${platform}/stores/${store}/mercado-pago-credentials/${credentials?.id ||
          ''}`,
        credentials,
      );

      setCredentials({
        publicKey: data.mercadoPagoCredential.publicKey,
        accessToken: data.mercadoPagoCredential.token,
        id: data.mercadoPagoCredential.id,
      });

      showToast({ message: 'Credenciais salvas com sucesso!', type: 'success' });
      onSave('mercadoPago');
    } finally {
      setLoading(false);
    }
  };

  const loadCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api(shopsApi[platform]).get(
        `api/v1/users/${platform}/stores/${store}/mercado-pago-credentials`,
      );

      if (data.mercadoPagoCredential) {
        setCredentials({
          publicKey: data.mercadoPagoCredential.publicKey,
          accessToken: data.mercadoPagoCredential.token,
          id: data.mercadoPagoCredential.id,
        });
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }, [platform, store]);

  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <Container fluid={fluid} className={className || 'pb-8 pt-5 pt-md-8'}>
      <Row>
        <Col className="order-xl-1" xl="12">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Configurações</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" size="md" onClick={onSubmit}>
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <p className="text-muted mb-5">
                    <a href="https://www.mercadopago.com/mlb/account/credentials" target="blank">
                      <span className="font-weight-bold">Clique aqui </span>
                    </a>
                    para obter sua Public Key e Access Token
                    <span className="text-uppercase font-weight-bold text-dark">
                      {' '}
                      de produção
                    </span>{' '}
                    para se conectar ao Mercado Pago.
                  </p>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="input-public-key">
                            Public Key
                          </Label>
                          <Input
                            className="form-control-alternative"
                            id="input-public-key"
                            placeholder={credentials.publicKey}
                            type="text"
                            onChange={handlePublicKey}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="input-access-token">
                            Access Token
                          </Label>
                          <Input
                            className="form-control-alternative"
                            id="input-access-token"
                            type="text"
                            onChange={handleAccessToken}
                            placeholder={credentials.accessToken}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MercadoPagoForm;
