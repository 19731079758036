import React, { useState, useCallback } from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input as StrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { api } from '../../../services/api';
import { useToast } from '../../../providers/ToastContext';
import emailSent from '../../../assets/img/theme/email-sent.svg';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { showToast } = useToast();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const onSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        await api('auth-api').post(`/auth/forgot-password`, data);
        setSent(true);
      } catch (error) {
        const message = error?.response?.data?.message || 'Entre em contato com o suporte.';

        showToast({
          message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [showToast],
  );

  const renderSentMessage = (
    <CardBody className="px-lg-5 py-lg-5 card-body">
      <div className="text-muted mb-4">
        <h2 className="text-center">E-mail enviado com sucesso</h2>
        <p className="mb-4">
          Enviamos as instruções de como criar sua nova senha ao seu email! Aguardamos você
          novamente!
        </p>
        <img src={emailSent} alt="email-sent" />
      </div>
    </CardBody>
  );

  const renderLoginForm = (
    <>
      <Col lg="6" md="8" className="mt-sm--8 mt-md--2 mt-lg--6 mt-xl--6 pb-5 container">
        {loading ? (
          <Spinner loading={loading} color="white" />
        ) : (
          <>
            <Card className="bg-secondary shadow border-0">
              {sent ? (
                renderSentMessage
              ) : (
                <CardBody className="px-lg-5 py-lg-5 card-body">
                  <div className="text-left text-muted mb-4">
                    <p>Enviaremos um link de recuperação para: </p>
                  </div>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup className={`${errors.email && 'has-danger'} mb-3`}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <StrapInput
                          className={errors.email && 'is-invalid'}
                          placeholder="Email"
                          name="email"
                          innerRef={register}
                        />
                      </InputGroup>
                      {errors.email && (
                        <small className="text-danger">É necessário informar um e-mail</small>
                      )}
                    </FormGroup>

                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div className="text-right">
                      <Button className="my-2 btn-icon btn-2" color="primary" type="submit">
                        <span className="btn-inner--icon">
                          <i className="ni ni-send" />
                        </span>
                        <span className="btn-inner--text">Enviar</span>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              )}
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-white" to="/auth/register">
                  <span>Criar nova conta</span>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="text-white" to="/auth/login">
                  <span>Entrar</span>
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </>
  );

  return renderLoginForm;
};

export default ForgotPassword;
