import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const MercadoPagoWarn = () => (
  <Alert color="info" className="d-flex align-items-center">
    <span>
      <i className="fa fa-exclamation-triangle" />
      Suas credenciais do <strong>Mercado Pago </strong>
      estão incorretas. Para arrumar{' '}
      <Link className="alert-link" to="integrations/mercado-pago">
        Clique aqui
      </Link>
    </span>
  </Alert>
);

export default MercadoPagoWarn;
